.search-bar-container {
  background-color: white;
  padding-top: $header-height-desktop;
  &--is-member {
    background: white no-repeat top center;
    background-size: cover;
  }
  &.isRebook {
    .search-bar {
      padding: 0;
      @include breakpoint(md) {
        padding-top: 48px
      }
      .mbsc-row {
        display: flex;
      }
      input:disabled + input, .btnswitch.disabled{
        cursor: no-drop
      }
    }
  }
  .header-title-mobile {
    display: none;
  }
  .search-bar {
    &--changedate {
      .origin,.destination {
        input {
          background-color: rgba(245, 245, 245, 0.5);
          color: rgba(30, 30, 30, 0.5)
        }
        .origin-box {
          .btnswitch {
            background-color: rgba(245, 245, 245, 1);
          }
        }
      }
    }
    .origin {
      padding-right: 0;
    }
    .destination {
      padding-left: 0;
    }
    .origin,
    .destination {
      @include breakpoint(mdlg, min) {
        min-width: 145px;
      }
      @include breakpoint(lg, min) {
        min-width: 160px;
      }
    }
    .origin-box {
      position: relative;
      .btnswitch {
        @include spritex('switch');
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        transform: translate(50%, -10px);
        cursor: pointer;
        background-color: white;
        box-shadow: 0px 4px 20px 0px #00000040;
        border-radius: 15px;
        padding: 1.5px 7.5px;
        background-size: 27px 27px;
        width: 27px;
        height: 27px;
      }
    }
    .calendar-box {
      display: grid;
      // TODO da refattorizzzare per browser support
      column-gap: $space-unit * 2;
      grid-template-columns: repeat(2, 1fr);
      @include breakpoint(md, min) {
        column-gap: 4px;
      }
      .calendar-box-item {
        position: relative;
        align-self: center;
      }
    }

    .search-error {
      color: $white;
      text-align: center;
      width: 100%;
    }

    .search-button {
      width: 100%;
      padding: 0;
      @include breakpoint(sm) {
        margin-top: $space-unit * 3;
      }
    }
    input {
      font-size: 14px;
      font-weight: 700;
      &:focus {
        border-color: get($green) !important;
        box-shadow: inset 0 0 0px 2px get($green);
      }
    }
    .button--return {
      @extend input;
      display: flex;
      flex: 100%;
      position: relative;
      align-items: center;
      padding: 8px 16px;
      margin-bottom: 16px;
      .button-return-content {
        position: relative;
        padding-left: 20px;
        font-size: 16px;
        @include breakpoint(sm) {
          padding-left: 6px;
        }
      }
      &:before {
        content: '';
        @include sprite('add-alternate');
        width: 16px;
        height: 16px;
        border: red solid 1px;
        border-radius: 20px;
        background-color: transparent;
        margin-right: 4px;
      }
    }
  }
  .stepper {
    &__inner {
      pointer-events: none;
      position: absolute;
      inset: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-weight: 500;
      letter-spacing: -0.02em;
    }

    &__subtext {
      pointer-events: none;
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 11px;
    }
    
    .mbsc-comp.mbsc-control {
      text-align: center;
      color: transparent;
    }
  }

  .coupon-bar {
    bottom: 0;
    right: $space-unit;
    width: 100%;
    z-index: 2;
    .coupon {
      width: 200px;
      .mbsc-form-group-content {
        @include breakpoint(sm) {
          margin-top: 20px;
        }
        .mbsc-input {
          position: relative;
          display: block;
          .mbsc-err-msg {
            background: initial;
            height: auto;
            border-radius: initial;
            padding: 0;
            justify-content: flex-end;
          }
        }
      }
      &.mbsc-collapsible-open {
        .coupon-title {
          display: none;
        }
      }
      .coupon-title {
        color: get($red);
        cursor: pointer;
        padding-right: 0;
      }
    }
  }

  @include breakpoint(md, min) {
    .search-bar {
      position: relative;
      padding: 12px $space-unit * 3 0;
      margin-top: $space-unit * 2;
      .mbsc-row {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr;
      }
      .adults-col {
        padding-right: 2px;
      }
      .children-col {
        padding-left: 2px;
      }
    }
    .coupon-bar {
      display: flex;
      justify-content: space-between;
      .search-bar__membership {
        @include spritex('logo-membership-transparent');
        align-items: center;
        justify-content: center;
        margin-right: 12px;
        margin-bottom: 7px;
      }
    }
  }

  @include breakpoint(md) {
    position: fixed;
    background-color: $white;
    top: 0;
    z-index: 10;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    padding-top: 0;
    @include animate;
    &.visible {
      opacity: 1;
      visibility: visible;
    }
    .header-title-mobile {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: $space-unit * 8;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
      padding: 0 $space-unit * 3;
    }
    .mbsc-grid {
      display: flex;
      flex: 1;
    }

    .search-bar {
      padding-top: $space-unit * 6;
      position: initial;
      .mbsc-col {
        position: absolute;
        bottom: $space-unit * 3;
      }
      .search-button {
        margin-top: $space-unit * 2;
        color: $white;
        background-color: get($red);
      }
    }
    .coupon-bar {
      position: relative;
      right: initial;
      padding-top: initial;
      transform: initial;
      // display: flex;
      // align-items: center;
      .coupon {
        width: auto;
        margin-right: auto;
        .coupon-title {
          text-align: center;
        }
        .mbsc-form-group-content {
          margin-top: 0;
        }
      }
    }
  }
}
