.main-features {
  padding: ($space-unit * 3) 0;
  background-color: get($grey, fog);
  @include breakpoint(lg) {
    padding-bottom: 0;
  }
  @include breakpoint(md, min) {
    .carousel--features {
      width: 100%;
      .swiper-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  @include breakpoint(md) {
    padding-bottom: $space-unit * 3;
    @include swiper;
    .carousel--features {
      position: relative;
      width: 100%;
      padding: 0 8px;
      .swiper-slide {
        padding: 0;
      }
    }
  }
}
