.switch {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  p {
    margin-right: $space-unit * 2;
    font-weight: bold;
  }
  input {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    border: 0;
    overflow: hidden;
    &:checked + .slider {
      border-color: get($flex, bg);
      background: get($flex, bg);
      &:before {
        transform: translate(-100%, -50%);
        left: 100%;
        background-color: $white;
      }
    }
    &:focus + .slider {
      box-shadow: 0 0 0 2px black;
      // box-shadow: 0 0 1px 2px get($red);
    }
  }
  .slider {
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    width: $space-unit * 6;
    height: $space-unit * 3;
    background-color: $white;
    border: 1px solid get($grey, border);
    padding: 0 $space-unit*0.5;
    border-radius: 12.5px;
    transition: 0.3s;
    &:before {
      content: '';
      position: relative;
      display: block;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: $space-unit * 2;
      height: $space-unit * 2;
      border-radius: 50%;
      background-color: get($grey, switch);
      transition: 0.3s;
    }
  }
  &-static {
    @extend .switch;
    position: relative;
    align-items: center;
    p {
      font-weight: normal;
    }
    input {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
}
