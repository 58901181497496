.membership-exclusive {
  position: relative;
  margin-bottom: 80px;
  .membership-image {
    width: 100%;
  }
  .wrapper-memership {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    h3 {
      margin-bottom: $space-unit * 3;
    }
    .big-paragraph {
      margin-bottom: $space-unit * 4;
    }
    li {
      display: flex;
      align-items: center;
      margin-bottom: $space-unit * 3;
      &:before {
        content: '';
        @include spritex('star');
        margin-right: 10px;
      }
      &:last-child {
        margin-bottom: $space-unit * 5;
      }
    }
  }
  @include breakpoint(md) {
    .mbsc-row {
      flex-direction: column-reverse;
    }
    .wrapper-memership {
      align-items: center;
      text-align: center;
      h3 {
        font-size: 32px;
        line-height: 40px;
        margin-top: $space-unit * 2;
      }
      .big-paragraph {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
