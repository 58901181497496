.stepper {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin-bottom: $space-unit * 2;
  .caption {
    margin-bottom: $space-unit * 0.5;
    white-space: pre;
    align-items: baseline;
    justify-content: space-between;
    span {
      color: get($grey, 'caption');
      font-family: get($font, 'paragraph');
      font-size: 12px;
      line-height: 16px;
      font-weight: normal;
    }
  }
  .mbsc-stepper-cont {
    .mbsc-stepper {
      position: relative;
      display: block;
      border-radius: 10px;
      height: 48px;
      background-color: get($grey, background);
      input {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
      }
      .mbsc-stepper-plus {
        @include sprite('plus');
        right: 2px;
      }
      .mbsc-stepper-minus {
        @include sprite('minus');
        left: 2px;
      }
      .mbsc-stepper-minus,
      .mbsc-stepper-plus {
        position: absolute;
        display: block;
        width: 24px;
        height: 24px;
        top: 50%;
        transform: translateY(-50%);
        background-position: center;
        background-size: 60%;
        background-repeat: no-repeat;
        padding: 8px;
        box-sizing: content-box;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &.mbsc-disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
    }
  }
  &.rebook-disabled {
    .mbsc-stepper-control {
      &.mbsc-stepper-plus, .mbsc-stepper-minus {
        opacity: .3;
        cursor: not-allowed;
      }
    }
  }
}
