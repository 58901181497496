.page-newsletter-e-registrazione {
  padding-top: $header-height-desktop;
  @include breakpoint(md) {
    padding-top: 0;
  }
  @include breakpoint(sm) {
    .breadcrumb {
      justify-content: flex-start;
    }
  }
}
