header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  background-color: get($red);
  &::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -2;
    visibility: hidden;
    opacity: 0;
    top: 80px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease-in-out;
    @include breakpoint(sm) {
      content: none;
    }
  }
  &.backdrop::after {
    visibility: visible;
    opacity: 1;
  }
  .hamburger {
    display: none;
  }
  .header {
    background-color: get($red);
    max-width: unset;
  }
  .header-container {
    width: 100%;
    box-sizing: border-box;
    background-color: get($red);
    height: $header-height-desktop;
    padding: 0 ($space-unit * 4) 0 ($space-unit * 5);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: get($screen, xl);
    margin-left: auto;
    margin-right: auto;
  }

  .logo-header {
    @include sprite('logo-big-white');
    display: block;
    width: 140px;
    height: 48px;
    background-position: left;
    margin-top: -6px;
  }

  .profile-header {
    display: none;
  }

  .menu-container {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    .menu-item {
      height: 100%;
      position: relative;
    }
    li {
      &:last-of-type {
        margin-right: 20px;
      }
    }
    .menu-heading {
      font-family: get($font, paragraph);
      font-size: 16px;
      line-height: 16px;
      color: get($grey, darkest);
      padding: 0 1vw;
      font-weight: 500;
      white-space: pre;
      text-decoration-line: none;
      display: flex;
      align-items: center;
      height: 100%;
      letter-spacing: initial;
      text-transform: initial;
      cursor: pointer;
      @include breakpoint(md, min) {
        color: $white;
        &:hover {
          //soluzione senza js
          + .submenu {
            opacity: 1;
            visibility: visible;
            transform: translateY(calc(100% - #{$header-height-desktop * 0.5}));
          }
        }
      }
    }
    .submenu {
      position: absolute;
      bottom: 0;
      left: 0;
      width: auto;
      max-height: calc(90vh - 40px);
      overflow-y: auto;
      opacity: 0;
      visibility: hidden;
      // min-height: 300px;
      background-color: $white;
      z-index: -1;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding-top: $header-height-desktop * 0.5;
      transform: translateY(0);
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease-in-out 0.1s;
      @include breakpoint(md, min) {
        &:hover {
          opacity: 1;
          visibility: visible;
          transform: translateY(calc(100% - #{$header-height-desktop * 0.5}));
        }
      }
      .submenu-heading {
        display: none;
      }
      .submenu-content {
        padding: ($space-unit * 3) 0;
      }
    }
  }
  .menu-buttons {
    display: flex;
    align-items: center;
    .button--primary {
      height: ($space-unit * 5);
    }

    @include breakpoint(md, min) {
      .login-logout-button {
        display: none;
      }
    }
  }
  .language-selector {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;
    select {
      appearance: none;
      border: none;
      background-color: $white;
      padding-right: $space-unit * 2;
      color: get($red);
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
      font-family: get($font, secondary);
      font-weight: bold;
      @include breakpoint(sm) {
        font-size: 16px;
        line-height: 24px;
      }
      @include breakpoint(md, min) {
        background-color: transparent;
        color: white;
      }
      option {
        color: get($red);
      }
    }
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid get($red);
      margin-left: 6px;
      display: block;
      position: absolute;
      right: 4px;
      @include breakpoint(md, min) {
        border-top: 4px solid white;
      }
    }

    &.mobile {
      display: none;
      &::after {
        top: 10px;
      }
    }

    @include breakpoint(md) {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
  }

  .wrapper-login-logout {
    .profile-button {
      display: none;
    }
  }

  @include breakpoint(md, min) {
    z-index: 4;
  }

  @include breakpoint(xl) {
    .menu-container {
      .menu-heading {
        padding: 0 12px;
      }
    }
  }

  @include breakpoint(md) {
    z-index: 3;

    &.is-open {
      // z-index: 3;
      .hamburger {
        .hamburger__line {
          &:nth-child(1) {
            transform: rotate(-45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(45deg);
          }
        }
      }
      .menu-container {
        opacity: 1;
        visibility: visible;
      }
      .menu-buttons {
        visibility: visible;
        opacity: 1;
      }
      &.sub {
        .menu-buttons {
          visibility: hidden;
          opacity: 0;
        }
      }
    }

    &.checkout {
      position: absolute;
    }

    .profile-header {
      @include sprite('user-not-logged');
      &.logged {
        @include sprite('user-logged');
      }
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
    }

    .hamburger {
      position: absolute;
      right: $space-unit * 3;
      display: flex;
      flex-direction: column;
      height: $space-unit * 3;
      width: $space-unit * 3;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &__line {
        width: 18px;
        height: 2px;
        background-color: white;
        border-radius: 2px;
        @include animate;
        &:nth-child(1) {
          transform-origin: top right;
        }
        &:nth-child(2) {
          margin: 4px 0;
        }
        &:nth-child(3) {
          transform-origin: bottom right;
        }
      }
    }

    .mbsc-grid {
      padding: 0;
      max-width: 100%;
    }

    .header-container {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      height: $header-height-mobile;
      padding: 0 $space-unit * 3;
      .logo-header {
        width: 112px;
        height: 40px;
        margin: 0 auto;
        &.booking {
          @include sprite('logo-white');
          width: 32px;
          height: 32px;
        }
      }
    }

    .menu-container {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      z-index: -1;
      padding: ($space-unit * 11) ($space-unit * 3);
      opacity: 0;
      visibility: hidden;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow-y: auto;
      @include animate;
      li {
        &:last-of-type {
          margin-right: 0;
        }
      }
      .menu-heading {
        width: 100%;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        padding: 0;
        border-bottom: 1px solid get($grey, border);
        &::after {
          content: '';
          @include sprite('chevron-right');
          display: block;
          width: 24px;
          height: 24px;
        }
      }
      .submenu {
        position: fixed;
        height: 100%;
        width: 100%;
        max-height: initial;
        border: none;
        padding: $space-unit * 3;
        padding-top: $header-height-mobile + $space-unit * 3;
        box-shadow: none;
        transform: translateX(100%);
        z-index: 1;
        transition: all 0.5s ease-in-out;
        &.active {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
        .submenu-heading {
          margin-bottom: $space-unit * 2;
          color: get($grey, darkest);
          display: flex;
          align-items: center;
          &::before {
            content: '';
            @include sprite('chevron-right');
            display: block;
            width: 35px;
            height: 35px;
            margin-right: 10px;
            border: 1px solid get($grey, border);
            border-radius: 50%;
            background-size: 80%;
            transform: rotate(180deg);
            background-position: 60% center;
            box-sizing: border-box;
          }
        }
        .submenu-content {
          padding: 0;
          margin: 0 -#{$space-unit};
        }
      }
      .menu-item {
        display: flex;
        align-items: center;
        height: 60px;
        width: 100%;
      }
    }

    .menu-buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: ($space-unit * 3);
      visibility: hidden;
      opacity: 0;
      width: 100%;
      justify-content: space-between;
      @include animate;
      .language-selector {
        padding: 0;
      }
    }
  }
  // custom mq for handling header behavior
  @media screen and (min-width: get($screen, 'md')) and (max-width: 1280px) {
    .logo-header {
      width: 112px;
      height: 40px;
    }
    .header-container {
      padding: 0 ($space-unit * 3);
    }
    .wrapper-login-logout {
      .profile-button {
        @include spritex('profile-white');
        filter: drop-shadow(0px 10px 20px rgba(71, 14, 14, 0.15));
        width: $space-unit * 5;
        height: $space-unit * 5;
        background-color: get($red);
        border-radius: 50%;
        display: none;
      }
      .button {
        display: none;
      }
    }
    .menu-container {
      .menu-heading {
        font-size: 14px;
      }
      .language-selector {
        margin-right: $space-unit * 2;
      }
      li:last-of-type {
        margin-right: 12px;
      }
    }
  }

  @include breakpoint(sm) {
    .profile-button {
      display: block;
    }
  }

  @include breakpoint(md, min) {
    &.checkout + #maincontent {
      padding-top: $header-height-desktop;
    }
    &.checkout.search-step + #maincontent {
      padding-top: 0;
    }
  }
  &.checkout.search-step {
    background-color: get($red);
    z-index: 7;
  }
}
.header-skirt {
  min-height: 150px;
  background-color: get($red);
  position: absolute;
  width: 100%;
  z-index: 2;
}
.menu-designer {
  ul {
    li {
      > a {
        display: none;
      }
    }
  }
}
