.services-route {
  background: #f5f5f5;
  padding: ($space-unit * 3) 0;
  h3 {
    margin-bottom: $space-unit * 3;
  }
  p {
    margin-bottom: $space-unit * 3;
  }
  .wrapper-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    ul {
      li {
        display: flex;
        align-items: center;
      }
      i,
      img {
        margin-right: $space-unit * 2;
      }
      li:not(:last-child) {
        margin-bottom: $space-unit * 2;
      }
    }
  }
  .wrapper-images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-unit * 2;
    .square-img {
      padding-top: 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  @include breakpoint(md) {
    .wrapper-images {
      grid-template-columns: repeat(4, 70%);
      overflow: scroll;
      margin-right: -$space-unit * 3;
      margin-left: -$space-unit * 3;
      margin-top: $space-unit * 3;
    }
  }
  @include breakpoint(sm) {
    h3 {
      font-size: 32px;
      line-height: 40px;
    }
  }
}
