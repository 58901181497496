.box-links {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  @include breakpoint(md, min){
    padding-top: 2rem;
    padding-bottom: 2rem;

    .box-links-wrapper {
      max-width: 1024px;
    }
  }

  .experience-component_box_link_item {
    align-self: flex-start;
  }

  .experience-component_box_link_item {
    flex: 1;
  }


  a {
    text-decoration: none;
    color: $black;
  }

  .mbsc-row {
    flex-wrap: nowrap;
  }

  .box-link-item {
    text-align: center;
    @include breakpoint(md, min){
      width: 100%;
      margin: 0 auto;
    }
  }
  
  .box-link-item__image {
    img {
      width: 2rem;
      height: 2rem;
      display: block;
      margin: 0 auto;
      @include breakpoint(md, min){
        width: 4rem;
        height: 4rem;
      }
    }
  }
  .box-link-item__title {
    font-weight: 500;
    @include breakpoint(sm, max){
      font-size: 12px;
      line-height: 120%;
    }
  }
}