.features-route {
  padding: ($space-unit * 10) 0;
  @include breakpoint(sm) {
    padding: ($space-unit * 5) 0;
  }
  &.is-grey {
    .card--features2 {
      border-color: $white;
    }
  }
  h3 {
    margin-bottom: $space-unit * 5;
  }
  .card--features2 {
    height: auto;
    margin-bottom: 0;
    border: initial;
  }
  @include breakpoint(sm) {
    h3 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: $space-unit * 4;
    }
  }
}
