.search-results-section-container {
  &.grid-container {
    .sidebar-wrapper {
      padding-top: 112px;
      padding-bottom: 120px;
    }
  }
  .legs-table {
    width: 100%;
  }
  .selected {
    box-shadow: 0 0 0 2pt red;
  }
}

.search-results-section-container--post-sale {
  background-color: #f5f6f6;
  .rebook-back {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .commission-alert {
    margin-bottom: 16px;
  }
  .search-results-section-container {
    &.grid-container {
      .sidebar-wrapper {
        padding-top: 0;
        padding-bottom: 232px;
      }
      .relative-grid {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        box-sizing: border-box;
        @include breakpoint(md, min) {
          padding-right: 24px;
          padding-left: 24px;
          max-width: calc(1280px + 48px);
        }
        @include breakpoint(lg, min) {
          max-width: calc(1440px + 160px);
          padding-right: 80px;
          padding-left: 80px;
        }
      }
    }
  }
  .post-sale-empty-state {
    background-color: #ffffff;
    border-radius: 20px;
    text-align: center;
    height: 100%;
    padding-top: 48px;
    @include breakpoint(md, min) {
      padding-top: 71px;
      max-width: 66.6666%;
    }
    @include breakpoint(md) {
      padding-bottom: 45px;
    }
    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      margin-top: 27px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }
    .icon-circle-bus {
      background-size: 147px;
    }
  }
}
