.grid-container {
  &.booking-container {
    @import '../components/seats-selection';

    .sidebar-wrapper {
      padding-bottom: 80px;
    }
    .card--booking {
      &.nameChange {
        position: relative;
        .loading-screen {
          position: absolute;
          width: 100%;
          height: 100%;
        }
        h5 {
          margin-bottom: 16px;
        }
        .card--booking__description {
          margin-bottom: 32px;
        }
        .passenger {
          margin-bottom: 32px;
          small {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }
        }
        .card--booking__buttons {
          display: flex;
          justify-content: center;
          gap: 32px;
          button {
            width: 100%;
            max-width: 238px;
          }
        }
        hr {
          border: solid 1px #dfdfdf;
          margin-top: 14px;
          margin-bottom: 14px;
        }
      }
    }
  }
}

.passenger-gender-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $space-unit * 3;
  padding: 0 $space-unit;
  margin-bottom: $space-unit * 2;

  .radio-wrapper {
    margin-bottom: 0;
    width: initial;
  }

  // Hide the fact that the radio buttons get focused on invalid form submission
  input[type='radio']:focus {
    border-color: get($grey, checkbox) !important;
    box-shadow: inherit;
  }
}

.privacy-info-message {
  text-align: center;
  a {
    color: initial;
    font-weight: bold;
  }
}

.service-container {
  margin-bottom: 60px;
  h5 {
    margin-bottom: $space-unit * 4;
    display: flex;

    @include breakpoint(sm, max) {
      margin-bottom: 0;
    }
  }
  .card--booking__membership-notice {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    margin: 0 0 0 8px;

    &--mobile {
      margin: 10px 0 24px 0;
      display: inline-block;
    }
  }
  .passenger {
    h6 {
      border-bottom: 1px solid get($grey, light);
      padding-bottom: 8px;
      margin-bottom: 16px;
      margin-top: $space-unit * 4;
      small {
        font-size: 12px;
        font-family: get($font, paragraph);
        margin-left: 8px;
        font-weight: normal;
        color: get($grey);
      }
    }
    @include breakpoint(sm) {
      h6 {
        margin-top: $space-unit * 3;
      }
    }
  }
  .credit-message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $space-unit;
    text-align: center;
    font-size: 14px;
  }
}

.booking-heading {
  margin-bottom: $space-unit * 3;
}

.mbsc-collapsible-open {
  .mbsc-card-header {
    border-bottom: 1px solid #ccc;
  }
}

.mbsc-collapsible-open {
  border-color: get($red);
  .mbsc-ic-arrow-down5 {
    transform: rotate(0deg);
  }
}

.luggagepassenger {
  margin-top: 24px;
  .passenger-line {
    border-bottom: 2px solid get($red);
    margin-top: $space-unit;
  }
  .alignright {
    text-align: right;
  }
  .luggagehead {
    white-space: nowrap;
    cursor: pointer;
    > * {
      display: flex;
    }
    h6 {
      font-size: 14px;
      text-transform: uppercase;
      padding: 0 $space-unit 0 $space-unit * 2;
      + div {
        color: get($grey, list);
      }
      br {
        display: none;
      }
    }
  }
  .luggagerow {
    margin: 0 20px;
    &--is-member {
      div.luggage {
        flex-direction: row-reverse;
        justify-content: flex-start;

        @include breakpoint(sm) {
          justify-content: space-between;
        }
      }
    }
    .luggage {
      display: flex;
      align-items: center;
      margin: $space-unit 0;
      padding: $space-unit 0;
      border-bottom: 1px solid get($grey, 'border');
      p {
        font-size: $space-unit * 2;
        font-weight: 600;
        font-family: get($font, secondary);
      }
    }
  }

  .specialbag-switch {
    justify-content: flex-end;
    .switch {
      padding-right: 90px;
    }
    .label {
      display: none;
    }
  }
  .segment-row {
    display: flex;
    padding: 0;
    padding-left: 20px;
    @include breakpoint(sm) {
      margin-top: $space-unit * 2;
    }
    .price {
      font-weight: bold;
      margin-left: $space-unit;
      margin-right: $space-unit;
    }
    .segment {
      &.segment-name {
        flex: 50%;
        .price {
          display: none;
        }
      }
      &.segment-switch {
        display: flex;
        justify-content: flex-end;
        text-align: right;
        flex: 50%;
        p {
          font-family: get($font, secondary);
          font-size: 12px;
          line-height: 24px;
          font-weight: 600;
          color: #00bca1;
          text-transform: uppercase;
        }
      }
    }
  }
  .icon-luggage {
    @include sprite('icon-luggage');
    background-size: 100%;
    height: $space-unit * 3;
    width: $space-unit * 3;
    display: inline-block;
    margin-right: $space-unit;
  }
  .icon-special-luggage {
    @include sprite('icon-special-luggage');
    background-size: 100%;
    height: $space-unit * 3;
    width: $space-unit * 3;
    display: inline-block;
    margin-right: $space-unit;
  }
  @include breakpoint(sm) {
    .luggagehead {
      > * {
        flex-wrap: wrap;
      }
      h6 {
        padding-left: 0;
        width: 100%;
      }
    }
    .specialbag-switch {
      .switch {
        padding-right: 20px;
      }
    }
    .segment-row {
      .segment {
        &.segment-name {
          .price {
            display: none;
          }
        }
        &.segment-switch {
          .price {
            margin-right: $space-unit * 3;
          }
          p {
            white-space: nowrap;
          }
        }
      }
    }
  }

  @include breakpoint(sm, min) {
    .luggagerow {
      margin: $space-unit 0;
      padding: ($space-unit * 2) 0;
      border-bottom: 1px solid get($grey, 'border');
      &--is-member {
        margin-left: 16px;
        margin-right: 16px;
        div.luggage {
          padding-left: 0;
          flex-direction: row;
        }
      }
      &:last-child {
        border: 0;
      }
      .luggage {
        margin: 0;
        border: 0;
        padding: 0;
        padding-left: 50px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .segment-row {
        text-align: right;
        @include breakpoint(sm) {
          margin-top: $space-unit * 2;
        }
        &.back-segment-row {
          padding-right: 30px;
        }
        .segment-name {
          flex: none;
          display: flex;
          align-items: center;
          margin-top: 0;
        }
        .segment-switch {
          flex: none;
          margin-left: 10px;
          margin-top: 0;
        }
      }
    }
  }
  @include breakpoint(lg, min) {
    .specialbag-switch {
      .label {
        display: block;
      }
    }
  }
}

.specialluggage {
  margin-top: 20px;
  margin-bottom: 20px;
}

.credit-card {
  padding-top: 1em;
}

.mobile-countdown-timer {
  margin-left: $space-unit * 3;
  margin-right: $space-unit * 3;
  .countdown {
    margin-top: $space-unit * 2;
  }
}

.error-banner-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: $space-unit * 2;
  padding: $space-unit * 2;
  background-color: #feecec;
  border-radius: 8px;

  p {
    flex: 1;
    margin-left: $space-unit * 2;
    color: #f03d3e;
    font-size: 14px;
    line-height: 22px;
  }
}

.booking-expired-popover {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  &__title {
    font-family: get($font, secondary);
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    color: get($grey, darkest);
    margin-bottom: $space-unit * 2;
  }

  &__message {
    font-family: Roboto;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    margin-bottom: $space-unit * 4;
  }

  &__actions {
    align-self: stretch;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.booking-navigation {
  &.search {
    background-color: get($red);
    .bookingbar {
      margin: 0;
      .back-wrapper {
        display: none;
      }
    }
  }
  .bookingbar {
    margin: ($space-unit * 2) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-operator-it {
      @include sprite('icon-operator-ita');
    }
    .icon-operator-en {
      @include sprite('icon-operator-eng');
    }
    .icon-operator-b2b-it {
      @include sprite('icon-operator-b2b-ita');
    }
    .icon-operator-b2b-en {
      @include sprite('icon-operator-b2b-eng');
    }
    .steps {
      display: flex;
      .step {
        display: flex;
        text-decoration-line: none;
        align-items: center;
        margin-left: 4vw;
        &:nth-child(1) {
          margin-left: 0;
        }
        span {
          color: white;
          font-family: get($font, secondary);
          font-weight: 600;
          font-size: 16px;
          letter-spacing: initial;
          white-space: nowrap;
        }
        &.enabled {
          cursor: pointer;
        }
      }
      .booking-operator-wrapper {
        @include breakpoint(md, min) {
          margin-left: 2vw;
        }

        @include breakpoint(md) {
          display: none;
        }
      }
    }
  }
}

.credit-card {
  .creditcardfield {
    max-width: 600px;
    margin-top: 20px;
    .mbsc-input-wrap {
      margin-bottom: 8px;
    }
    svg {
      position: absolute;
      z-index: 100;
      top: 17px;
      left: 24px;
    }
    #cardNumber {
      padding-left: 50px;
    }
  }
}

@include breakpoint(md) {
  .booking-expired-popover {
    &__actions {
      flex-direction: column;

      button {
        width: 100%;
      }

      > button + button {
        margin-top: $space-unit * 2;
      }
    }
  }
  .booking-navigation {
    &.search {
      .bookingbar {
        display: none;
      }
    }
    .bookingbar {
      .steps {
        .step {
          margin-left: $space-unit * 5;
        }
      }
    }
  }
  .booking-heading {
    font-size: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .service-container {
    margin-bottom: 0;
    .privacy {
      .privacybox {
        flex-wrap: wrap;
        .privacycol {
          width: 100%;
          padding-right: 0;
          > span {
            width: 100%;
          }
        }
      }
    }
  }
  .booking-navigation {
    .bookingbar {
      .steps {
        .step {
          margin-left: $space-unit;
          small.number {
            margin-right: 0;
          }
          span {
            display: none;
          }
        }
      }
    }
  }
}
@include breakpoint(md, min) {
  .booking-navigation {
    .bookingbar {
      margin: ($space-unit * 3) 0;
      .steps {
        position: fixed;
        top: 4px;
        z-index: 4;
        left: 50%;
        transform: translateX(-40%);
        .booking-operator-wrapper {
          margin-left: 2vw;
          i {
            width: 15vw;
          }
        }
      }
    }
    &.search {
      .steps {
        z-index: 7;
      }
    }
  }
}
@include breakpoint(md, max) {
  .booking-navigation {
    .bookingbar .steps .booking-operator-wrapper {
      display: none;
    }
  }
}
@include breakpoint(lg, min) {
  .booking-navigation {
    .bookingbar .steps .booking-operator-wrapper {
      margin-left: 3vw;
      i {
        width: 19vw;
      }
    }
  }
}
