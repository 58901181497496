.experience-component_image_slider {
  margin: $space-unit * 10 0;
  @include breakpoint(sm) {
    margin: $space-unit * 5 0;
  }
}
.image-slider {
  position: relative;
  height: 32vw;
  //for editmode
  .grid-layout {
    .swiper-slide {
      height: 400px;
      img {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  //carosello
  .slider {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include swiper();
    .swiper-slide {
      overflow: hidden;
      img {
        position: absolute;
        display: block;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        min-height: 100%;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      @include sprite('chevron-right');
      background-size: 80%;
      background-position: 60% center;
      background-color: $white;
      width: 40px;
      height: 40px;
      border: 1px solid get($grey, border);
      border-radius: 50%;
      box-sizing: border-box;
    }
    .swiper-button-next {
      right: 12vw;
      transform: translate(-50%, 50%);
    }
    .swiper-button-prev {
      right: initial;
      left: 12vw;
      transform: translate(50%, -50%) rotate(180deg);
    }
  }
  @include breakpoint(md) {
    .swiper-button-next,
    .swiper-button-prev {
      display: block;
    }
    .slider {
      .swiper-button-next {
        right: 10vw;
      }
      .swiper-button-prev {
        left: 10vw;
      }
    }
  }
  @include breakpoint(sm, min) {
    .slider {
      .swiper-button-next,
      .swiper-button-prev {
        &:hover {
          @include sprite('chevron-right-white');
          background-size: 80%;
          background-position: 60% center;
          background-color: get($red);
          border-color: get($red);
        }
      }
    }
  }
  @include breakpoint(sm) {
    height: 45vw;
    max-height: 175px;
    .slider {
      padding: 0 $space-unit * 3;
      box-sizing: border-box;
      .swiper-button-next,
      .swiper-button-prev {
        width: 32px;
        height: 32px;
      }
      .swiper-button-next {
        right: 4px;
        transform: translateY(50%);
      }
      .swiper-button-prev {
        left: 4px;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}
