.experience-component_text {
  margin: $space-unit * 10 0;
  @include breakpoint(sm) {
    margin: $space-unit * 5 0;
  }
}
.text-component {
  .text-item-row {
    display: flex;
    margin-left: -$space-unit;
    margin-right: -$space-unit;
    justify-content: center;
    flex-wrap: wrap;
  }
  .text-item-col {
    flex: 1 0 33.333333%;
    position: relative;
    padding-right: $space-unit;
    padding-left: $space-unit;
    max-width: 50%;
  }
  @include breakpoint(sm) {
    .text-item-col {
      max-width: 100%;
      flex-basis: 100%;
      margin-bottom: $space-unit * 4;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    .text-item {
      h5 {
        margin-bottom: $space-unit * 2;
        font-size: 16px;
        line-height: 24px;
      }
      .feature-paragraph {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}

.link-item {
  margin-bottom: 22px;
  :last-child {
    margin-bottom: 0;
  }
}
