.stations-list-container {
  .booking-box {
    z-index: 1;
    .close--button {
      z-index: 2;
    }
  }
  h2,
  .h2 {
    text-align: center;
    font-size: 2.2rem;
    &.stations-title {
      padding-top: $space-unit * 3;
    }
  }
  .experience-header {
    overflow-x: hidden;
  }
  .wrapper-stations-list {
    margin-top: $space-unit * 10;
    margin-bottom: $space-unit * 10;

    h3 {
      font-size: $space-unit * 7;
      line-height: $space-unit * 8;
      margin: $space-unit * 2 $space-unit * 3;
      text-align: center;
    }
    .stations-text {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: $space-unit * 2;
      text-align: center;
    }
    hr {
      border-color: get($grey, border);
      border-style: solid;
      margin-top: $space-unit * 2;
    }
    .stations-carousel {
      position: relative;
      padding: 0 0 34px;
      @include breakpoint(sm, max) {
        margin-bottom: $space-unit * 6;
      }
    }
    .swiper-wrapper {
      margin: 0;
    }
    .swiper-slide {
      margin-bottom: 2 * $space-unit;
      cursor: pointer;
      .image-container {
        padding-top: 66.66%;
        position: relative;
        img {
          position: absolute;
          object-fit: cover;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      .route-name {
        width: 135px;
        text-align: left;

        @include breakpoint(sm, min) {
          width: auto;
          flex: 1;
          padding-right: 1rem;
        }

        @include breakpoint(lg, min) {
          max-width: 10rem;
        }
      }
      .price {
        color: $black
        span {
          color: get($red);
        }
      }
    }
    .swiper-button-prev {
      display: none;
      position: absolute;
      bottom: 0;
      top: initial;
      left: -34px;
      right: initial;
    }
    .swiper-button-next {
      display: none;
      position: absolute;
      top: initial;
      bottom: 0;
      right: 0;
      left: initial;
    }
    .card--stations-finder {
      position: relative;
      .scroll-anchor {
        opacity: 0;
        height: 0;
        top: -80px;
        position: absolute;
      }
      input {
        width: 100%;
        padding-right: 25%;
        &::placeholder {
          text-align: left;
        }
      }
      button {
        position: absolute;
        bottom: 20%;
        right: 0;
        width: 20%;
        min-width: unset;
        margin-right: 10px;
        height: 30px;
        padding: 0 0 0 10px;
        i {
          background-size: 18px 18px;
        }
      }
      .stations-finder-wrapper {
        position: relative;
      }
      ul {
        position: absolute;
        top: $space-unit * 7;
        background-color: get($grey, 'background');
        width: 100%;
        border-radius: 10px;
        z-index: 2;
        li {
          cursor: pointer;
          text-align: center;
          line-height: $space-unit * 5;
          font-size: $space-unit * 2;
          mark {
            background-color: transparent;
            font-weight: bold;
          }
        }
      }
      @include breakpoint(md) {
        button {
          width: 27%;
        }
        input {
          padding-right: 32%;
        }
      }
    }
    .stations-section {
      max-width: 1040px;
      h2 {
        font-size: $space-unit * 5;
        line-height: $space-unit * 6;
        text-align: center;
        margin: $space-unit * 5 0 $space-unit * 3;
      }

      .stations-item {
        text-align: center;
        font-size: 18px;
        line-height: 28px;
        a {
          color: $black;
          text-decoration: initial;
        }
      }
    }
    .alert--error {
      text-align: center;
    }
    .show-more-container {
      width: 100%;
      text-align: center;
      color: get($red);
      font-size: $space-unit * 2;
      font-weight: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      .show-more,
      .close-all {
        cursor: pointer;
        margin: 20px;
      }
      .close-all {
        display: block;
      }
    }
  }

  .map-container {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 200px;
    padding-bottom: 130px;

    h3 {
      color: $white;
      text-align: left;
      font-size: $space-unit * 7;
      line-height: $space-unit * 8;
    }
    img {
      display: block;
      width: 100%;
    }
  }

  .row-search-field {
    padding-bottom: $space-unit * 5;
  }

  &--table {
    .swiper-wrapper {
      .swiper-slide {
        color: $black;
        text-decoration-line: none;
        max-width: 100%;
        width: 100%;
        flex: 1;
        .price {
          font-weight: 400;
          color: $unavailable;
          position: static;
          width: 80px;
          text-align: left;
          span {
            color: inherit;
          }
        }
        .image-container {
          padding-top: unset;
          display: flex;
          align-items: center;
        }
      }
      .card--offers {
        flex-direction: row;
        border-color: transparent;
        border-radius: 0;
        border-bottom-color: get($grey, border);
      }

      .bottom-card {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 0;
      }

      .route-name,
      .price {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: -2%;
      }
    }
  }

  &--airports {
    .routes-row {
      justify-content: space-evenly;
    }
  }

  @include breakpoint(md, min) {
    // > md
    .map-container {
      position: relative;
      overflow: hidden;
      display: block;
      height: 165px;
      color: red;
      img {
        display: none;
      }
    }
    .wrapper-stations-list {
      .show-all .swiper-slide {
        display: block;
      }
    }

    &--table {
      h2 {
        margin-bottom: 5rem;
      }

      .swiper-wrapper {
        display: grid;
        &:not(.swiper-wrapper--airports) {
          grid-template-rows: repeat(4, 4.375rem);
          grid-template-columns: 1fr 1fr;
          column-gap: 10rem;
        }

        .swiper-slide {
          margin-bottom: 0;
        }
      }
    }
  }

  @include breakpoint(lg, min) {
    &--table {
      h2 {
        margin-bottom: 5rem;
      }

      .swiper-wrapper {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 11.125rem;
        .swiper-slide {
          max-width: 19rem;
        }
      }
    }
    &--airports {
      .routes-row {
        .swiper-wrapper {
          grid-template-columns: 1fr;
          column-gap: 11.125rem;
          .swiper-slide {
            max-width: 19rem;
          }
        }
      }
    }
  }

  @include breakpoint(lg) {
    .card--offers .bottom-card h5 {
      max-width: calc(100% - 100px);
      &.price {
        max-width: 180px;
      }
    }
  }

  @include breakpoint(md) {
    h2,
    .h2 {
      font-size: $space-unit * 4;
      line-height: $space-unit * 5;
    }
    .map-container {
      h3 {
        color: $white;
        text-align: left;
        font-size: $space-unit * 4;
        line-height: $space-unit * 5;
      }
    }
    .wrapper-stations-list {
      h3 {
        font-size: $space-unit * 4;
        line-height: $space-unit * 5;
      }
      .stations-text {
        font-size: 16px;
        line-height: 28px;
      }
      .stations-section {
        h2 {
          font-size: $space-unit * 3;
          line-height: $space-unit * 4;
          margin: $space-unit * 3 0 $space-unit * 3;
        }
        .stations-item {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: $space-unit;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        display: block;
      }
      .swiper-slide {
        margin-right: 0;
        //padding: 0;
      }
    }
  }

  @include breakpoint(sm) {
    .editorial-card-carousel .swiper-slide {
      max-width: 50%;
    }
    h2,
    .h2 {
      font-size: $space-unit * 4;
      line-height: $space-unit * 5;
    }
    .map-container {
      height: calc(100vw * 0.25);
      padding-top: 330px;
      padding-bottom: 5px;
    }
    .swiper-wrapper {
      flex-wrap: wrap;
    }

    .card--offers {
      .bottom-card {
        padding: initial;
        h5 {
          text-align: center;
          max-width: 100%;
          font-size: 14px;
          &.price {
            position: initial;
            font-size: 12px;
            max-width: 100%;
          }
        }
      }
    }
  }

  @include breakpoint(md, max) {
    &--table {
      h2 {
        margin-bottom: 2rem;
      }

      .wrapper-stations-list {
        margin-bottom: 40px;
        margin-top: 40px;
        .stations-carousel {
          padding-bottom: 0;
        }
      }

      .wrapper-stations-list .swiper-wrapper {
        flex-direction: column;
        .swiper-slide {
          max-width: 100%;
          height: 4.375rem;
          flex: unset;
          cursor: unset;
        }

        .bottom-card {
          padding: 1rem;
          padding-right: 0;

          .route-name,
          .price {
            font-size: 1rem;
            line-height: 2rem;
            letter-spacing: -2%;
          }
        }
      }

      .accordion-item__head p {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: -2%;
      }
    }
  }
}

.from-to-component {
  border-radius: 20px;
  padding: 16px;
  position: relative;
  top: -16px;
  background-color: $white;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid get($grey, 'border');
  .names-col {
    p {
      width: 65%;
    }
  }
  .route-item {
    cursor: auto;
  }
  .tab-element {
    display: none;
    &.active {
      display: block;
    }
  }
  .ft-header{
    display: flex;
    @include breakpoint(md) {
      margin: -1px -1px 0;
    }
    h5 {
      width: 50%;
      text-align: center;
      padding: 4px;
      margin-bottom: 16px;
      font-size: 14px;
      text-transform: uppercase;
      border: 1px solid get($grey, 'border');
      background-color: #fff;
      cursor: pointer;
      &:first-child {
        border-radius: 10px 0 0 10px;
        border-right: none;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
        border-left: none;
      }
      &.selected {
        background-color: get($red);
        color: $white;
      }
    }
  }
  .ft-body {
    .route-item {
      position: relative;
      &:last-child {
        margin-bottom: 16px;
      }
    }
    .from-to-col {
      margin-right: 12px;
      color: get($red);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h5 {
        font-size: 18px;
      }
    }
    // .prices-col {
    //   align-self: flex-start;
    //   h5 {
    //     font-size: 18px;
    //   }
    // }
    .discover-more {
      position: absolute;
      bottom: 0;
      right: -8px;
      border-radius: 10px 0 0;
      min-width: 35%;
      h5 {
        font-size: 16px;
        text-transform: lowercase;
      }
      &:after {
        content: '';
        @include sprite('chevron-right-white');
        width: $space-unit * 3;
        height: $space-unit * 3;
        position: relative;
        left: $space-unit;
      }
    }
  }
  @include breakpoint(md) {
    padding: 0;
    .ft-body {
      padding: 0 8px;
    }
    .card--trip .card__body {
      padding: 12px 12px 0;
    }
    .ft-body {
      .names-col {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-right: 6px;
        p {
          width: 100%;
        }
      }
      // .prices-col {
      //   width: 35%;
      //   h5 {
      //     font-size: 14px;
      //     span {
      //       font-size: 18px;
      //     }
      //   }
      // }
      .from-to-col {
        h5 {
          font-size: 16px;
        }
      }
      .route-item {
        display: flex;
        flex-direction: column;
      }
      .discover-more {
        position: unset;
        left: unset;
        align-self: flex-end;
        height: 35px;
        width: 55%;
        padding: 8px 4%;
      }
    }
  }
  @include breakpoint(md, min){
    .discover-more.loading {
      width: 190px;
    }
  }
}
.from-to-component-wrapper {
  min-width: 50%;
  @include breakpoint(md) {
    width: 100%;
  }
}


.new-stations-wrapper {
  &.mobile {
    display: none;
    @include breakpoint(md, max){
        display: block;
        &.routes-page {
          margin-top: 1.5rem;
          padding-left: 1rem;
          border-left: 1px solid get($grey, border);
        }
    }
    .accordion-item__head {
      align-items: flex-end;
      margin-bottom: 1rem;
    }
  }
  display: flex;
  justify-content: center;
  @include breakpoint(md, min){
    display: flex;
    flex-wrap: nowrap;
  }
  display: none;
  h2 {
    font-size: 1.5rem;
    text-align: left;
    line-height: 2rem;
  }
  p {
    text-decoration: underline;
    line-height: 2rem;
    font-size: 1.2rem;
  }
  a {
    color: black;
    line-height: 2rem;
    font-size: 1.2rem;
  }
  .stations-column {
    display: flex;
    flex-direction: column;
    width: 33%;
    padding-left: 1rem;
    border-left: 1px solid get($grey, border);
  }
  .stations-row {
    display: block;
  }
}
