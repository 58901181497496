.seat-selection {
  width: 100%;
  position: relative;
  .loading-screen {
    position: absolute;
    background-color: rgba($white, 0.6);
  }

  .wrapper__passengers {
    display: flex;
  }

  .route-item {
    position: relative;
    cursor: pointer;
    padding: $space-unit;
    margin-bottom: 0;
    width: 100%;
    margin-right: $space-unit;
    border: 1px solid get($grey, border);
    border-radius: 10px;
    @include animate();
    &:last-of-type {
      margin-right: 0;
    }
    &.selected {
      border: 1px solid get($flex, bg);
      box-shadow: 0 0 0px 1px get($flex, bg);
    }

    // p {
    //   margin: 0;
    //   margin-bottom: 4px;
    // }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #dfdfdf;
      padding-top: 8px;
      margin-top: 8px;

      // .microcopy {
      //   margin-bottom: 0;
      // }

      .caption {
        color: #ee203c;
      }
    }
  }

  .microcopy {
    font-size: 12px;
    line-height: 16px;
    color: #2c2c31;
  }

  .caption {
    font-family: get($font, secondary);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  .route {
    font-family: get($font, secondary);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    // margin-bottom: 4px;
    i {
      margin: 0 4px;
      flex-shrink: 0;
    }
    span {
      @include text-shorten;
    }
  }

  // .routes {
  //   margin-top: 0;
  // }

  .passengers {
    margin-top: $space-unit * 3;
    .passengers-select {
      display: none;
    }
    .passenger-name {
      font-size: 16px;
      line-height: 32px;
      color: get($grey, caption);
    }

    .seat-details {
      display: flex;
      .seat-price,
      .seat-number,
      .seat-unselected {
        font-family: get($font, secondary);
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: get($grey, caption);
      }
      .seat-price {
        margin-right: ($space-unit * 2);
      }
      .seat-number {
        span {
          margin-right: $space-unit;
        }
      }
      span {
        color: get($grey);
      }
    }
  }

  .routes,
  .passengers {
    > .caption {
      margin-bottom: $space-unit;
    }
  }

  .wrapper__passengers {
    flex-direction: column;
    .passenger-item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f5f5f5;
      padding: 8px 16px;
      margin-bottom: $space-unit;
      border-radius: 10px;
      @include animate();
      &:last-of-type {
        margin-bottom: 0;
      }
      &.selected {
        box-shadow: inset 0 0 0px 1px get($grey, dark);
      }
      .passengers--is-member {
        p {
          display: inline-block;
          &.membership-badge{
            display: none;
            &.passenger_1,
            &.passenger_2 {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  .seat.available:hover {
    cursor: pointer;
  }

  .carriage-image {
    width: auto !important;
  }

  .wrapper-seatmap {
    border: 1px solid get($grey, border);
    border-radius: 10px;
    margin-top: $space-unit * 3;
    transition: all 0.4s ease-in-out;
    &.seatmap-open {
      height: 370px;
    }
    &.seatmap-close {
      height: 0;
    }

    ul.deck-list {
      display: flex;
      flex-wrap: wrap;
      li {
        flex: 1 0 50%;
        white-space: nowrap;
        text-align: center;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid get($grey, border);
        button {
          cursor: pointer;
          color: get($grey, default);
        }
        &.selected {
          border-bottom: 2px solid get($red, default);
          color: get($red, default);
          button {
            color: get($red, default);
          }
        }
      }
    }
  }

  .seatmap {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    max-height: 300px;
    margin: $space-unit * 2;
    scroll-behavior: smooth;
    .seatmap-container {
      transform: rotate(90deg) translateY(-100%);
      transform-origin: top left;
      transition: all 0.3s ease-in-out;
      opacity: 1;
      visibility: visible;
      top: -16px;
      left: -8px;

      &.hide {
        opacity: 0;
        visibility: hidden;
      }
      .seat {
        opacity: 0;
        background-color: rgba($white, 0.6);
        &.blink {
          animation: blink 0.3s ease-in-out infinite alternate;
        }
      }
    }
    #blocked-popup {
      position: fixed;
      display: none;
      align-items: center;
      width: 160px;
      height: 70px;
      transition: all 0.1s ease-in-out;
      transform: rotate(270deg);
      .popup-arrow {
        border: solid 20px #00bca1;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }
      .popup-text {
        background-color: rgb(0, 188, 161);
        padding: 4px;
        font-weight: 700;
      }
    }
    @include breakpoint(sm) {
      #blocked-popup {
        transform: unset;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;
        background-color: rgb(0, 188, 161);
        .popup-arrow {
          display: none;
        }
        .popup-text {
          padding-top: 16px;
          position: relative;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          line-height: 22px;
          letter-spacing: 0em;
        }
      }
    }
  }

  .carousel--seats {
    @extend .carousel;
    width: 100%;

    @include swiper();

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  @keyframes blink {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @include breakpoint(sm) {
    .wrapper-seatmap {
      margin-top: $space-unit * 2;
      &.seatmap-open {
        height: calc(100vh - 344px);
        opacity: 1;
      }
      &.seatmap-close {
        height: auto;
        opacity: 0;
      }
    }
    .route {
      margin-bottom: 0;
      padding-right: 30%;
    }
    .route-item {
      height: 60px;
      padding: $space-unit $space-unit * 2;
      &__header {
        .microcopy:last-child {
          position: absolute;
          right: 0;
          bottom: 0;
          margin-bottom: 11px;
          margin-right: 16px;
        }
      }
      &__footer {
        justify-content: flex-start;
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        padding: 0;
        margin: 8px 16px 0 0;
        .microcopy {
          margin-right: $space-unit;
        }
        .caption {
          color: get($grey, caption);
          letter-spacing: 3px;
        }
      }
    }
    .seatmap {
      max-height: calc(100% - 76px);
      overflow-x: hidden;
      overflow-y: auto;
      .seatmap-container {
        transform: none;
        transform-origin: initial;
        margin: 0 auto;
        top: initial;
        left: initial;
        width: 110%;
        object {
          .Verticale {
            display: block;
          }
          .Orizzontale {
            display: none;
          }
        }
      }
    }
    .routes,
    .passengers {
      > .caption {
        display: none;
      }
    }
    .passengers {
      margin-top: 0;
      padding: 0 $space-unit * 3;
      &.passengers--is-member-wrapper {
        input{
          line-height: 60px;
        }
        @include breakpoint(sm, max) {
          .passengers--is-member {
            display: flex;
            flex-direction: column-reverse;
          }
        }
      }
      .wrapper__passengers {
        display: none;
      }
      .passengers-select {
        display: block;
      }
    }
    .carousel--seats {
      padding: 0 $space-unit * 3;
      .swiper-button-prev,
      .swiper-button-next {
        @include sprite('chevron-right-white');
        display: block;
        height: $space-unit * 8;
        width: $space-unit * 8;
        background-size: 24px;
        background-position: 20% center;
        background-color: get($red);
        border-radius: 50%;
        z-index: 2;
        &:focus {
          outline: none;
        }
        &.swiper-button-disabled {
          opacity: 0;
        }
      }
      .swiper-button-prev {
        right: initial;
        left: $space-unit;
        transform: translate(-50%, -50%) rotate(-180deg);
      }
      .swiper-button-next {
        right: $space-unit;
        transform: translate(50%, 50%);
      }
    }
  }
}

@include breakpoint(sm) {
  .seatselection-card {
    .card__body {
      padding: 0;
      .collapsible {
        overflow: visible;
      }
    }
  }
}
