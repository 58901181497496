#search-result, #app-post-sale, #app-post-sale-rebook {
  @import '../components/screenWrapper';
  @import '../components/search-screen';
  @import '../components/booking-screen';
  @import '../components/payment-screen';

  .grid-container {
    .sidebar-wrapper {
      padding-top: unset;
    }
  }
  .return {
    background-color: get($grey, fog);
  }

  .triptitle-wrapper {
    position: relative;
  }

  .triptitle {
    margin-top: $space-unit * 3;
    margin-bottom: $space-unit * 3;
    h3 {
      @include text-shorten;
      font-size: 32px;
      line-height: 40px;
    }
    &--button {
      display: none;
    }
  }
  .edit-mobile {
    display: none;
  }

  .wrapper-days {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space-unit * 3;
  }

  .separator-listing {
    display: none;
  }

  .selector {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
    flex-grow: 1;
    .selector-item {
      border: 1px solid get($grey, border);
      background-color: $white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 32%;
      text-align: center;
      cursor: pointer;
      filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.1));
      position: relative;
      z-index: 1;
      padding-top: 12px;
      padding-bottom: 20px;
      h6 {
        font-size: 16px;
        line-height: 32px;
        color: get($grey);
        font-weight: normal;
        text-transform: capitalize;
        font-family: get($font, secondary);
        &.day-name {
          &.short-day-name {
            display: none;
          }
        }
      }
      .paragraph {
        font-family: get($font, primary);
        font-size: 20px;
        line-height: 24px;
        color: $black;
      }

      &.selected {
        z-index: 0;
        filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.25));
        box-shadow: get($green) 0px -4px 0px 0px inset;
        h6 {
          color: get($green);
        }
      }
    }
  }

  .routes-container {
    margin-bottom: 120px;
    @import '../components/route-item';
    > h5 {
      padding-top: $space-unit * 2;
    }
  }

  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space-unit * 3;

    .membership-prices-row {
      display: flex;
      background-color: rgba($membership-color, 0.05);
      border-radius: 25px;
      padding: 8px;
      align-items: center;

      .microcopy {
        margin: 0 $space-unit * 2 0 $space-unit;
      }
    }
  }

  .prices-col {
    .price.red-price {
      color: get($red);;
    }
  }

  .price-info-row {
    display: flex;
    align-items: center;
    // max-width: 50%;
    i {
      margin-left: 10px;
    }
    .caption {
      font-family: get($font, paragraph);
      font-weight: normal;
      font-size: 14px;
    }
    .info-box__content {
      left: initial;
      right: 11px;
      top: initial;
      bottom: calc(100% + 13px);
      border-top-left-radius: 10px;
      border-bottom-right-radius: 0;
      z-index: 3;
      &::before,
      &::after {
        border-width: 6px 8px;
        border-color: white white transparent transparent;
        left: initial;
        right: 0;
        top: initial;
        bottom: 0;
        transform: translateY(100%);
      }
      &::after {
        right: -1px;
        border-width: 7px 9px;
        border-color: #dfdfdf #dfdfdf transparent transparent;
      }
    }
  }

  .services-row {
    border: 1px solid get($grey, border);
    box-sizing: border-box;
    border-radius: 42px;
    background-color: $white;
    padding: 12px $space-unit * 2;
    display: flex;
    align-items: center;
    i {
      margin-right: $space-unit * 2;
      // background-size: 100% !important;
      // width: 18px !important;
      // height: 18px !important;
      &:last-child {
        margin-right: 0;
      }
    }
    .service-wifi {
      @include spritex('service-wifi-lg');
    }
    .service-slack {
      @include spritex('service-slack');
    }
    .service-zap {
      @include spritex('service-zap');
    }
    .service-wc {
      @include spritex('service-wc-lg');
    }
    .service-usb {
      @include spritex('service-usb-lg');
    }
    .service-snack {
      @include spritex('service-snack-lg');
    }
  }

  .mbsc-calendar {
    .mbsc-fr-popup {
      .mbsc-fr-w {
        background-color: $white;
        border: 1px solid #ccc;
        border-radius: 10px;
        .mbsc-cal-c {
          padding: 0.5em;
        }
      }
    }
  }

  .mbsc-mobiscroll {
    .mbsc-input.mbsc-sel-filter-cont {
      input {
        padding: $space-unit $space-unit * 3;
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
        font-family: get($font, secondary);
        font-weight: bold;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .mbsc-sc-itm {
    font-family: get($font, secondary);
  }

  .mbsc-mobiscroll.mbsc-fr-no-overlay.mbsc-fr-bubble {
    .mbsc-fr-scroll,
    .mbsc-fr-persp {
      overflow: visible;
    }
    .mbsc-fr-w {
      border-radius: 10px;
      // box-shadow: none;
    }
  }

  @include breakpoint(md) {
    margin-top: 0;
    margin-bottom: 120px;
    .triptitle-wrapper {
      &.hasEdit {
        .triptitle {
          padding-right: ($space-unit * 11);
        }
      }
    }

    .services-row i {
      &.service-wifi {
        @include spritex('service-wifi');
      }
      &.service-wc {
        @include spritex('service-wc');
      }
      &.service-usb {
        @include spritex('service-usb');
      }
      &.service-snack {
        @include spritex('service-snack');
      }
    }

    .ic-arrow-switch {
      transform: rotate(90deg);
    }

    .edit-mobile {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      span {
        font-family: get($font, secondary);
        font-size: 14px;
        line-height: 1;
        font-weight: 600;
        text-transform: initial;
        margin-top: $space-unit*0.5;
      }
    }
    .mbsc-calendar {
      // for debug purposes
      // .mbsc-cal-slide {
      //   border: 1px solid red;
      // }
      .mbsc-fr-popup {
        left: 50% !important;
        transform: translateX(-50%);
        padding-top: 12px;
      }
    }
    .routes-container {
      margin-bottom: 60px;
      .route-item {
        &:focus {
          outline: none;
        }
      }
    }

    .return {
      background-color: initial;
    }

    .triptitle {
      margin: $space-unit * 4 0;
    }
    .info-row {
      position: relative;
      margin: $space-unit * 3 0;
    }
    .price-info-row {
      i {
        margin-left: $space-unit;
      }
      .caption {
        font-size: 12px;
        line-height: 14px;
      }
    }
    .wrapper-days {
      background: get($red);
      padding: $space-unit * 3 0;
      color: $white;
      margin-left: -$space-unit * 3;
      margin-right: -$space-unit * 3;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      padding-bottom: 54px;
      margin-bottom: 0;
      &--is-member {
        background: var(--searchheader-url) no-repeat top center;
      }      
      .arrow-left {
        margin-left: $space-unit * 3;
      }
      .arrow-right {
        margin-right: $space-unit * 3;
      }
      .selector {
        margin: 0 $space-unit * 2;
      }
    }
    .separator-listing {
      display: block;
      background-color: get($grey, fog);
      height: 30px;
      margin-left: -$space-unit * 3;
      margin-right: -$space-unit * 3;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      position: relative;
      top: -30px;
      margin-bottom: -60px;
    }
    .selector {
      .selector-item {
        border-color: get($red);
        background-color: transparent;
        &.selected {
          border: 1px solid $white;
        }
        h6 {
          &.day-name {
            &.long-day-name {
              display: none;
            }
            &.short-day-name {
              display: block;
              color: $white;
              font-size: 12px;
              line-height: 22px;
            }
          }
        }
        &.selected {
          box-shadow: initial;
        }
        .paragraph {
          color: white;
        }
      }
    }
  }
  @include breakpoint(sm) {
    .triptitle-wrapper {
      &.hasEdit {
        .triptitle {
          &--button {
            .titles {
              padding-right: ($space-unit * 11);
            }
          }
        }
      }
    }
    button.edit-mobile {
      display: none;
    }
    .triptitle {
      display: none;
      &--button {
        display: flex;
        align-items: center;
        margin: ($space-unit * 2) 0;
        text-transform: initial;
        width: 100%;
        .titles {
          flex: 1 1 auto;
          padding-left: $space-unit;
          h6 {
            color: get($grey, 'darkest');
            line-height: 1;
            text-align: left;
            &:first-child {
              margin-bottom: $space-unit;
            }
          }
        }
      }
    }
    .wrapper-days {
      padding-top: 10px;
      padding-bottom: 40px;
      .selector {
        margin: 0;
      }
    }
    .info-row {
      margin-top: 16px;
      margin-bottom: 12px;
    }
    .selector {
      .selector-item {
        padding: 4px 0;
        width: auto;
        flex-grow: 1;
        h6 {
          &.day-name {
            &.short-day-name {
              color: $white;
              font-size: 12px;
              line-height: 22px;
            }
          }
        }
        .paragraph {
          font-size: 14px;
          line-height: 22px;
        }
        &.selected {
          box-shadow: initial;
        }
      }
    }

    .mbsc-calendar {
      .mbsc-fr-popup {
        top: 0 !important;
        padding-top: 32px;
      }
    }
  }
  @include breakpoint(xs) {
    .price-info-row {
      max-width: 70%;
    }
    .wrapper-days {
      .arrow-left {
        margin-left: $space-unit * 2;
      }
      .arrow-right {
        margin-right: $space-unit * 2;
      }
    }
  }
  .loading-screen {
    position: fixed;
    height: 100vh;
    background-color: rgba($white, 0.6);
    z-index: 2;
  }
}
main[data-action='Search-Start'] {
  background: get($grey, fog);
}

//custom css for new bus-stop-component
#app-bus-stops,
.other-routes {
  margin: 16px 0;
  .bs-title {
    display: flex;
    background-color: get($red);
    align-items: center;
    justify-content: center;
    height: 80px;
    border-radius: 20px 20px 0 0;
    &.justify-left {
      justify-content: left;
    }
    &.justify-center {
      justify-content: center;
    }    
    span {
      color: $white;
      font-size: 24px;
      font-family: get($font, secondary);
      display: inline-block;
      padding-bottom: 16px;      
    }
  }
  .bs-travels-wrapper {
    border-radius: 20px;
    padding: 40px 24px 24px;
    position: relative;
    top: -16px;
    background-color: white;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    .card--trip {
      cursor: auto;
    }
  }
  .price {
    font-size: 24px;
  }
  .bs-showmore {
    display: block;
    margin: 20px auto 0;
    text-transform: none;
    display: flex;
    font-weight: 400;
    cursor: pointer;
    i {
      margin-left: 6px;
    }
  }
  .bs-book-now {
    position: relative;
    bottom: -16px;
    left: 16px;
    border-radius: 20px 0 0;
    &:after {
      content: '';
      @include sprite('chevron-right-white');
      width: $space-unit * 3;
      height: $space-unit * 3;
      position: relative;
      left: $space-unit;
    }
  }
  @include breakpoint(md) {
    .bs-title {
      height: 64px;
      span {
        font-size: 16px;
      }
    }
    .prices-col {
      align-self: flex-start;
    }
    .price {
      font-size: 18px;
    }
    .names-col {
     padding-right: $space-unit;
     p {
      font-size: 12px;
     }
    }
    .hours-col {
      margin-right: $space-unit;
    }
    .route-item {
      display: flex;
      flex-direction: column;
    }
    .bs-book-now {
      height: 35px;
      bottom: 0;
      align-self: flex-end;
    }
  }
}
