.banner-comunications {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: get($alerts, notification_bg);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  min-height: ($space-unit * 6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  visibility: hidden;
  opacity: 0;
  transform: translateY(100%);
  &,
  * {
    box-sizing: border-box;
  }
  @include animate(0.5);
  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
  i {
    flex-shrink: 0;
  }
  a,
  .microcopy {
    text-decoration: initial;
    color: get($alerts, notification);
    padding: ($space-unit * 2) 0;
    padding-left: $space-unit;
  }
  button {
    position: absolute;
    right: ($space-unit * 2);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  @include breakpoint(md) {
    // bottom: initial;
    // top: 0;
    justify-content: flex-start;
    padding-left: ($space-unit * 2);
    padding-right: ($space-unit * 7);
    transform: translateY(-100%);
    a,
    .microcopy {
      padding-left: ($space-unit * 2);
    }
  }
}
