.modal.tracking-consent {
  z-index: 102;
  .modal__body {
    padding: ($space-unit * 3) ($space-unit * 5) ($space-unit * 5) ($space-unit * 5);
  }
  .cookies-tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    &.hide {
      display: none;
    }
    &__heading {
      display: flex;
      align-items: center;
      align-self: flex-start;
      h6 {
        margin-left: ($space-unit * 1.5);
      }
    }
    &__language-heading {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .language-selector {
      position: relative;
      display: flex;
      align-items: center;
      select {
        appearance: none;
        border: none;
        background-color: $white;
        color: get($red);
        font-size: 12px;
        line-height: 16px;
        padding-right: $space-unit * 2;
        text-transform: uppercase;
        font-family: get($font, secondary);
        font-weight: bold;
      }
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid get($red);
        margin-left: 6px;
        display: block;
        position: absolute;
        right: 4px;
      }
    }
    .field-wrapper {
      padding-left: ($space-unit * 5);
      margin-top: ($space-unit * 2);
      .form-field:not(:last-child) {
        margin-bottom: ($space-unit * 2);
      }
    }
    .microcopy {
      letter-spacing: initial;
    }
    .handler-show-hide {
      color: get($red);
      cursor: pointer;
    }
    a {
      text-decoration: none;
    }
    label {
      display: flex;
      align-items: flex-start;
    }
    .radio-wrapper {
      position: relative;
      flex-shrink: 0;
    }
    .radio-label {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 6px;
      h6 {
        margin-bottom: $space-unit*0.5;
      }
    }
    .cookies-actions {
      display: flex;
      width: 100%;
      margin-top: ($space-unit * 5);

      > button {
        flex: 1
      }
      > button + button {
        margin-left: ($space-unit * 4);
      }
    }
    .cookielayer-close{
      color: #e1261d;
      cursor: pointer;
    }
  }
  @include breakpoint(sm) {
    &.open .modal__container {
      transform: translateY(0);
    }
    &::after {
      content: '';
    }
    .modal__container {
      transform: translateY(100%);
      top: initial;
      bottom: 0;
      height: auto;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .modal__body {
      padding: ($space-unit * 2) ($space-unit * 3);
    }
    .cookies-tab {
      &__heading {
        position: relative;

        button {
          position: absolute;
          left: -12px;
        }

        h6 {
          font-size: 16px;
          line-height: 24px;
          flex-grow: 1;
          text-align: center;
          margin-left: ($space-unit * 3);
        }
      }

      label {
        margin-left: 12px;
      }

      .field-wrapper {
        padding-left: 0;
      }
      .radio-label {
        margin-left: $space-unit;
      }
      .cookies-actions {
        flex-direction: column-reverse;
        margin-top: ($space-unit * 2);

        > button {
          width: 100%;
          height: 40px;
        }
        
        > button + button {
          margin-left: 0;
          margin-bottom: $space-unit;
        }
      }
    }
  }
}
