.route-template {
  .intro-route {
    padding: ($space-unit * 10) 0;
    text-align: center;

    .title {
      margin-bottom: 2rem;
    }
  }
  &.city-landing {
    .intro-route {
      padding-bottom: 0;
    }
  }
  @include breakpoint(sm) {
    .intro-route {
      padding: ($space-unit * 5) ($space-unit * 3) 22px;
    }
  }

  &.city-landing {
    @include breakpoint(sm, max) {
      .intro-route {
        padding-left: 0;
        padding-right: 0;
        .title {
          font-size: 1.5rem;
          line-height: 120%;
          font-weight: 400;
        }
      }
    }
  }
  .experience-faq {
    margin: $space-unit * 10 0;
  }
}
