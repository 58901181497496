.voucherspage {
  .landing {
    text-align: center;
    .hero-title {
      h1 {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .hero-title-image {
      padding-bottom: 0;
      padding-top: 31.25rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .voucher-box {
      display: flex;
      align-items: center;
      flex-direction: column;

      .heroimage {
        width: 40%;
        margin-top: -#{$space-unit * 3};
        margin-bottom: $space-unit * 2;
      }
      .description {
        margin-bottom: $space-unit * 3;
      }
    }
  }

  .listing {
    margin-bottom: 100px;
    .voucher-tile {
      position: relative;
      padding: $space-unit * 3;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      text-align: left;
      border-radius: 20px;
      margin-bottom: $space-unit * 3;
      .voucher-message {
        font-size: 12px;
        line-height: 2 * $space-unit;
      }
      .voucher-logo {
        position: absolute;
        background: $enjoy;
        border-radius: 0px 20px;
        width: 92px;
        height: 40px;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 38px;
        }
      }
      h2 {
        font-size: 28px;
        line-height: 40px;
        margin-bottom: $space-unit;
      }
      .voucher-info {
        display: flex;
        margin-bottom: $space-unit * 2;
        justify-content: space-between;
        align-items: flex-end;
        .desc {
          line-height: 18px;
        }
        .caption {
          font-size: 12px;
          line-height: 16px;
        }
        .price {
          font-family: get($font, secondary);
          font-size: 24px;
          font-weight: bold;
          del {
            color: get($grey);
            font-size: 14px;
            margin-right: $space-unit;
          }
        }
      }
      button {
        width: 100%;
      }
    }
    .note {
      margin: 24px 0;
      text-align: center;
    }
    .voucher-details {
      text-align: center;
      background-color: get($grey, 'background');
      border: 1px solid get($grey, 'border');
      box-sizing: border-box;
      border-radius: 10px;
      padding-bottom: 35px;
      margin-top: 12px;
      .title {
        margin: 25px 25px 6px;
      }
      .note {
        margin: 0;
      }
    }
  }

  .checkout {
    // import screen wrapper
    @import '../components/screenWrapper';
    margin: $header-height-desktop 0;
    .grid-container .sidebar-wrapper {
      padding-top: 72px;
    }
    .sidebar {
      display: flex;
      flex-direction: column;
      .cart-body {
        > h5 {
          margin-bottom: ($space-unit * 3);
        }
        .alert--error {
          margin-top: ($space-unit * 3);
          width: 100%;
        }
      }
    }
    .voucher-checkout-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 12px 8px;
      border-radius: 10px;
      border: 1px solid get($grey, border);
      p,
      span {
        font-family: get($font, secondary);
      }
      p {
        color: get($grey, dark);
        font-size: 12px;
        line-height: 16px;
      }
      span {
        color: get($grey, caption);
        font-size: 14px;
        line-height: 24px;
      }
    }
    .back-wrapper {
      margin: ($space-unit * 4) 0;
    }
    .voucher-checkout-block {
      margin-top: $space-unit * 4;
      &:first-child {
        margin-top: 0;
      }
      h3 {
        margin-bottom: ($space-unit * 3);
      }

      button {
        &.option {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin: $space-unit * 3 0;
          @include breakpoint(md, min) {
            &.voucher {
              position: absolute;
              right: 8px;
              top: 0;
              margin-top: ($space-unit * 2);
              width: auto;
            }
          }
        }
      }
      .membership-reminder {
        margin: ($space-unit * 5) 0;
        h3 {
          margin-bottom: ($space-unit * 1);
        }
        p {
          font-weight: bold;
          font-size: 18px;
        }
      }
      .caption {
        margin-bottom: 4px;
      }
    }
    .flags {
      margin-top: ($space-unit * 2);
      margin-bottom: 0;
    }

    &--vouchers,
    &--iobusopen,
    &--iobuslongticket,
    &--iobusoneround {
      .voucher-checkout-block button.option.invoice-option {
        display: none;
      }
    }
  }
  .thankyou {
    .bon-voyage .button--primary {
      min-width: 300px;
    }
    .message {
      color: get($grey, 'dark');
      font-weight: normal;
      font-size: 14px;
    }
    .voucher-thankyou-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: ($space-unit * 3);
      border-radius: 10px;
      border: 1px solid get($grey, border);
      margin-bottom: ($space-unit * 3);

      input#copy-coupon-text-handle {
        position: absolute;
        opacity: 0;
        top: -100000px;
        left: -100000px;
      }

      img {
        height: 46px;
        margin-right: ($space-unit * 3);
      }
      > div {
        display: flex;
        align-items: center;
        flex-grow: 1;
        white-space: nowrap;
        flex-wrap: wrap;
        h5 {
          margin-right: ($space-unit * 5);
        }
        .code-info {
          .caption {
            font-size: 12px;
            line-height: 16px;
            color: get($grey, thankyou);
          }
          h6 {
            color: get($red);
          }
        }
      }
    }
    .voucher-details {
      text-align: center;
      border: 1px solid get($grey, 'border');
      box-sizing: border-box;
      border-radius: 10px;
      padding: 29px 25px;
      margin-top: 12px;
      .title {
        margin: 25px 0 6px;
        &.align-left {
          text-align: left;
        }
      }
      .note {
        margin: 0;
        ul li {
          list-style-type: none;
        }
        &.align-left {
          text-align: left;
        }
      }
      li {
        list-style: outside;
        margin-left: 20px;
      }
    }
    .voucher-more .title {
      text-align: center;
      margin: 24px 0 46px;
      a {
        text-decoration: none;
      }
    }
    .button-actions {
      .copy-code {
        padding-left: $space-unit * 2;
      }
    }
    .membership-caption {
      color: get($grey, thankyou);
      margin-bottom: 5px;
    }
  }

  @include breakpoint(md) {
    .landing {
      .hero-title {
        padding-bottom: 40px;
        &.hero-title-image {
          padding-bottom: 250px;
          padding-top: 0;
        }
      }
    }
    .checkout {
      margin-top: 0;
      .back-wrapper {
        margin: ($space-unit * 2) 0;
      }
    }
    .thankyou .button-actions {
      margin-left: 4px;
      .copy-code,
      .receipt {
        padding: 0;
      }
      .button--primary {
        min-width: initial;
      }
    }
  }
  @include breakpoint(sm) {
    .landing {
      .heroimage {
        width: 60%;
      }
      .hero-title {
        h1 {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .voucher-tile {
        padding: $space-unit * 2;
        h2 {
          font-size: 24px;
          line-height: 32px;
        }
        .voucher-info {
          display: block;
          margin-bottom: $space-unit;
          .caption,
          h5 {
            display: inline-block;
          }
          .caption {
            width: 60%;
            margin-left: 0;
            margin-right: 0;
          }
          h5 {
            width: 40%;
            margin-top: $space-unit;
            text-align: right;
          }
        }
        .voucher-logo {
          width: 76px;
          height: 32px;
          img {
            height: 18px;
          }
        }
      }
      .note {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .checkout {
      .card--booking {
        padding-top: ($space-unit * 2);
        padding-bottom: 0;
      }
      .voucher-checkout-block {
        margin-top: ($space-unit * 2);
        h3 {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: $space-unit * 2;
        }
      }
      .voucher-checkout-item {
        margin-top: ($space-unit * 3);
        margin-bottom: 100px;
        padding: 16px;
        span {
          font-size: 16px;
        }
      }
      .wrapper-input.icon-email .caption {
        font-size: 12px;
      }
      .flags__content {
        text-align: left;
      }
    }
    .thankyou {
      .voucher-thankyou-item {
        margin-top: ($space-unit * 3);
        padding: 12px 8px;
        img {
          height: 35px;
          margin-right: ($space-unit * 2);
        }
        > div {
          h5 {
            margin-right: 16px;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .button-actions {
        width: 100%;
        margin: 0;
        > * {
          width: 100%;
          &:not(:last-child) {
            margin-bottom: ($space-unit * 2);
          }
        }
      }
    }
  }
  #membership-scroll-into-view {
    display: block;
    position: relative;
    top: -15rem;
  }
}
