// import delle utilities
@import 'utils';

// import delle partials
@import 'partials';

// import dei componenti
@import 'components';
@import '../src/widgets/button';
@import '../src/widgets/input';
@import '../src/widgets/stepper';
@import '../src/widgets/card';
@import '../src/widgets/toggle-switch';
@import '../src/widgets/modal';
@import '../src/widgets/select';
@import '../src/widgets/alert';
@import '../src/widgets/loading';

// import delle pagine
@import 'pages';


#header-for-app {
  display: none;
}
