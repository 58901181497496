.webtocase-container {
  .h4 {
    margin-top: $space-unit * 5;
    margin-bottom: $space-unit * 2;
  }
  .big-paragraph {
    margin-bottom: $space-unit * 2;
  }
  .sidebar {
    .breadcrumbs {
      display: flex;
      align-items: center;

      i {
        margin-right: 10px;
        flex-shrink: 0;
      }
    }

    .big-paragraph {
      padding-right: 20%;
    }
    .small-paragraph {
      padding-right: 20%;
      margin-bottom: $space-unit * 2;
    }

    h4,
    .h4 {
      margin-top: $space-unit * 5;
      margin-bottom: $space-unit * 2;
    }
  }

  .content {
    form {
      margin-top: $space-unit * 5;

      // TODO create a file for common form css (see also _myaccount)
      .custom-select {
        &::after {
          @include labelIcon('chevron-down', right);
        }

        select {
          display: block;
          border-radius: 10px;
          color: get($grey, dark);
          box-sizing: border-box;
          border-color: transparent;
          width: 100%;
          height: $space-unit * 6;
          padding: 0 $space-unit;
          font-size: 16px;
          line-height: 24px;
          background-color: get($grey, fog);
          font-family: get($font, paragraph);
          -webkit-appearance: initial;

          &:disabled {
            color: get($grey, light);
          }
        }
      }

      textarea {
        width: 100%;
        min-height: 200px;
        background-color: get($grey, fog);
        font-family: get($font, paragraph);
        border-radius: 10px;
        color: get($grey, dark);
        box-sizing: border-box;
        border-color: transparent;
        padding: $space-unit $space-unit * 2;
        font-weight: 500;
      }

      .form-field {
        display: block;
        margin-bottom: $space-unit * 2;

        .caption {
          display: block;
          margin-bottom: $space-unit;
        }
      }

      button[role='submit'] {
        margin-top: $space-unit * 5;
        width: 100%;

        i {
          margin-right: $space-unit * 2;
        }
      }

      .hidden {
        display: none;
      }
    }
  }

  .wrapper-buttons {
    display: flex;
    flex-direction: column;
    margin-top: $space-unit * 3;

    a {
      margin-bottom: $space-unit * 2;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .collapsible {
    margin-top: $space-unit * 2;
    border-top: 1px solid get($grey, border);
    padding-top: $space-unit * 3;
  }
  .booking-confirm {
    position: absolute;
    top: -5000px;
  }

  @include breakpoint(md, min) {
    padding-top: $header-height-desktop + $space-unit * 5;
    padding-bottom: 120px;
  }

  @include breakpoint(md) {
    padding-top: $space-unit * 5;
    padding-bottom: $space-unit * 10;
  }
}
