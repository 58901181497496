footer {
  position: relative;
  overflow: hidden;
  background-color: get($grey, background);
  font-weight: 400;
  &.hide {
    display: none;
  }

  .wrapper-about-us {
    color: $white;
    padding-top: $space-unit * 4;
    padding-bottom: $space-unit * 4;
    &::before {
      content: '';
      background-color: get($red);
      position: absolute;
      right: -60px;
      top: 0;
      width: 50vw;
      height: 100%;
    }
    .about-us {
      position: relative;
      z-index: 1;
    }
    .logo {
      @include sprite('logo-big-white');
      display: block;
      width: 200px;
      height: 70px;
      margin-bottom: $space-unit * 3;
    }
    .small-paragraph {
      width: 90%;
      margin-bottom: $space-unit * 3;
    }
    .social-links {
      display: flex;
      a {
        margin-right: $space-unit * 2;
        height: 45px;
        width: 45px;
        &.social-facebook {
          @include sprite('social-circle-facebook');
        }
        &.social-twitter {
          @include sprite('social-circle-twitter');
        }
        &.social-instagram {
          @include sprite('social-circle-instagram');
        }
        &.social-youtube {
          @include sprite('social-circle-youtube');
        }
        &.social-linkedin {
          @include sprite('social-circle-linkedin');
        }
      }
    }
  }
  .wrapper-footer-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-menu {
    padding-top: $space-unit * 6;
    padding-bottom: $space-unit * 5;
    > div {
      display: flex;
      flex-direction: column;
    }
    h6 {
      margin-bottom: $space-unit;
    }
    a {
      text-decoration: none;
      color: get($grey, dark);
      &.notactive {
        text-decoration: line-through;
        color: #aaa; // temporaneo
      }
    }
  }
  .footeritems {
    font-size: 12px;
    line-height: 16px;
  }
  .legal-area {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid get($grey, border);
    .microcopy {
      letter-spacing: initial;
      color: get($grey, dark);
      // line-height: 0;
      a {
        text-decoration: initial;
        color: inherit;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @include breakpoint(sm) {
    .wrapper-about-us {
      &::before {
        right: -#{$space-unit * 2};
        width: 100vw;
      }
    }
    .footer-menu {
      padding-top: ($space-unit * 4);
      padding-bottom: 0;
    }
    .footer-menu {
      > div {
        margin-bottom: $space-unit * 4;
      }
    }
    .legal-area {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
      .microcopy {
        margin-top: ($space-unit * 2);
        margin-bottom: ($space-unit * 2);
        line-height: 16px;
      }
      .language-selector-footer {
        margin-bottom: ($space-unit * 3);
      }
    }
  }
}

// utilizzato anche fuori dal footer
.language-selector-footer {
  position: relative;
  display: flex;
  align-items: center;
  > img {
    margin-right: $space-unit;
  }
  select {
    -webkit-appearance: none;
    border: none;
    background-color: transparent;
    padding-right: 12px;
    color: get($grey, dark);
    font-size: 14px;
    line-height: 22px;
    font-family: get($font, paragraph);
  }
  &::after {
    content: '';
    @include spritex('arrow-down-no-bg');
    display: block;
    position: absolute;
    right: 0;
  }
}
.cookie-button {
  letter-spacing: normal;
  color: get($grey, dark);
  margin-top: ($space-unit * 2);
  margin-bottom: ($space-unit * 2); 
  font-family: get($font, paragraph);
  text-transform: capitalize;
  font-weight: unset;
  font-size: unset;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
