.flags {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: $space-unit * 2;
  border-radius: 10px;
  background-color: $flags;
  margin-bottom: $space-unit * 2;
  overflow: hidden;
  border: 1px solid get($red);
  &::before {
    content: '';
    @include sprite('logo');
    display: block;
    height: $space-unit * 4;
    width: $space-unit * 4;
    flex-shrink: 0;
  }

  // .switch {
  //   margin: 4px 0;
  // }

  &__content {
    margin: 0 $space-unit * 2;
    font-family: get($font, secondary);
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    flex-grow: 1;
  }

  .flag {
    &:first-child {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(#000000, 0.1);
      margin-bottom: 12px;
    }
    .switch {
      width: 100%;
      text-align: right;
      align-items: center;
    }
    .microcopy {
      font-family: get($font, secondary);
      letter-spacing: initial;
      flex-grow: 1;
    }
  }

  + button[role='submit'] {
    margin-top: $space-unit * 2;
  }

  &--covid {
    @extend .flags;
    position: relative;
    margin-bottom: $space-unit * 3;
    align-items: flex-start;
    &.expanded {
      .show-more {
        &::after {
          transform: rotate(0deg);
        }
      }
      .covid-list {
        max-height: 400px;
        overflow-y: auto;
        &::before {
          height: 0;
        }
      }
    }
    .flags__content {
      position: relative;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .flag-title {
      margin: 0 $space-unit * 2;
      font-family: get($font, secondary);
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      flex-grow: 1;
    }
    .show-more {
      // position: absolute;
      // left: 50%;
      // transform: translateX(-50%);
      // z-index: 1;
      display: flex;
      bottom: $space-unit * 2;
      text-transform: initial;
      cursor: pointer;
      &::after {
        content: '';
        display: block;
        @include spritex('chevron-up');
        margin-left: $space-unit;
        transform: rotate(180deg);
      }
    }

    .covid-list {
      position: relative;
      overflow: hidden;
      font-family: get($font, paragraph);
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      margin-top: $space-unit;
      margin-bottom: $space-unit * 2;
      max-height: 48px;
      padding-left: 20px;
      @include animate;
      &::before {
        // content: '';
        position: absolute;
        display: block;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 30px;
        // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #{$flags} 100%);
        z-index: 1;
        @include animate;
      }

      & > li {
        margin-bottom: $space-unit * 2;
      }

      li {
        position: relative;
        &::before {
          // content: '';
          position: absolute;
          top: 7px;
          left: 0;
          width: 4px;
          height: 4px;
          // background: get($grey, 'textlight');
          // border-radius: 50%;
        }
      }
      ul,
      ol {
        padding-left: 12px;
      }
    }
  }

  &--registration,
  &--profile {
    @extend .flags;
    &::before {
      height: $space-unit * 5;
      width: $space-unit * 5;
      margin-right: $space-unit * 2;
    }
  }
  &--profile {
    margin-bottom: $space-unit * 3;
  }

  @include breakpoint(sm) {
    padding: $space-unit + 2px;
    &::before {
      height: $space-unit * 3;
      width: $space-unit * 3;
    }
    .flag {
      &:first-child {
        margin-bottom: ($space-unit * 2);
        padding-bottom: 0;
        border-bottom: initial;
      }
      .switch {
        align-items: flex-start;
        text-align: left;
      }
    }
  }
}
