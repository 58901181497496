.search-bus-contatiner {
  &.results {
    padding: ($space-unit * 10) 0;
    .search-bus-sidebar {
      .alert--info {
        background-color: transparent;
        &:first-of-type {
          margin-top: ($space-unit);
        }
      }
      .icon-error {
        margin: auto;
        margin-top: ($space-unit);
      }
    }
    .card--profile {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
      padding: ($space-unit * 2);
      margin-right: ($space-unit * 5);
      flex-direction: column;
    }
    .bus-heading {
      display: grid;
      grid-template-columns: auto 24px auto;
      // TODO da refattorizzzare per browser support
      grid-column-gap: 4px;
      align-items: center;
      h5 {
        @include text-shorten;
        &:first-child {
          text-align: right;
        }
      }
    }
    .info-bus {
      font-family: get($font, secondary);
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      margin: 0 auto;
      margin-top: $space-unit;
    }
    .next-stop-wrapper {
      margin-top: ($space-unit * 2);
      border-top: 1px solid get($grey, 'border');
      padding-top: ($space-unit * 2);
      display: flex;
      justify-content: space-between;

      .next-departure-time {
        width: 100%;
        text-align: center;

        span {
          font-weight: 600;
        }
      }
    }
    .next-stop-block {
      display: flex;
      align-items: center;
      .track-dot {
        width: ($space-unit * 2);
        height: 100%;
        position: relative;
        &::before {
          content: '';
          background-color: get($grey, 'border');
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &::after {
          content: '';
          background-color: get($red);
          width: ($space-unit * 2);
          height: ($space-unit * 2);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          border-radius: 50%;
        }
      }
      .microcopy {
        color: get($red);
      }
      .next-stop {
        margin-left: $space-unit;
      }
    }
    .next-stop-times {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.technical-stop {
        width:100%;
        text-align:center;
      }
    }
    .stops-details {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .caption {
        margin-left: $space-unit;
        &:not(:last-child) {
          opacity: 0.35;
          text-decoration: line-through;
        }
      }
    }

    .wrapper-search-bus {
      padding-bottom: 30%;
    }
  }
  .page-content {
    padding-bottom: $space-unit * 10;
  }
  @include breakpoint(md) {
    &.results {
      padding-top: 0;
      padding-bottom: ($space-unit * 5);
    }
  }
  @include breakpoint(sm) {
    &.results {
      .wrapper-search-bus {
        height: 80vh;
        order: -1;
        margin-left: -#{$space-unit * 2};
        margin-right: -#{$space-unit * 2};
        width: 100vw;
        margin-top: ($space-unit * 2);
      }
      .search-bus-sidebar {
        position: initial;
        .alert--info:first-of-type {
          margin-top: ($space-unit * 4);
        }
      }
      .card--profile {
        position: absolute;
        width: calc(100% - #{$space-unit * 6});
        margin-right: 0;
        top: 67px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
    }
    .page-content {
      margin-top: 0;
      padding-bottom: $space-unit * 5;
      .big-paragraph {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
