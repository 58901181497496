.call-to-action {
  position: relative;
  overflow: hidden;
  padding: $space-unit * 10 0;
  &.white-text {
    color: $white;
  }
  .background-image {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .wrapper-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    &.between {
      justify-content: space-between;
    }
  }
  @include breakpoint(sm) {
    padding: $space-unit * 5 0;
    .background-image {
      height: 100%;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }
    .wrapper-cta {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      h4 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: $space-unit * 2;
      }
    }
  }
}
