.hero-route {
  padding-top: $header-height-desktop;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: get($font, secondary);
  &[data-edit='true'] {
    background-color: grey;
  }
  @include breakpoint(md, min) {
    min-height: 600px;
  }
  .wrapper-text {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10%;
    color: $white;
    .has-shadow {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .black {
      color: $black;
    }
    p {
      margin-top: $space-unit;
      &.has-shadow {
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      &.black {
        color: $black;
      }
    }
  }
  @include breakpoint(md) {
    padding-top: 0;
    background-size: 120%;
    background-position: top center;
    overflow: initial;
    margin-bottom: 240px;
    padding-bottom: 20px;
    > .mbsc-grid {
      padding: 0;
    }
    .mbsc-row {
      margin: 0;
    }
    .wrapper-text {
      position: relative;
      padding-top: ($space-unit * 4);
      padding-bottom: ($space-unit * 6);
      //padding-bottom: ($space-unit * 10) + 30px;
      min-height: 182px;
      text-align: center;
      .h2 {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .wrapper-booking {
      padding: 0;
    }
    .booking-box {
      position: absolute;
      top: -30px;
      z-index: 3;
    }
  }
}
