.tooltip {
  text-transform: initial;
  text-align: left;
  color: inherit;
}
.info-box {
  position: relative;
  &__head {
    display: flex;
    align-items: center;
    cursor: pointer;
    .caption {
      font-family: get($font, paragraph);
      font-weight: normal;
      letter-spacing: initial;
    }
  }
  &__content {
    display: none;
    position: absolute;
    padding-top: $space-unit;
    top: calc(100% + 12px);
    left: 0;
    border-radius: 10px;
    border: 1px solid get($grey, border);
    background-color: $white;
    padding: $space-unit * 2;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 0;
    z-index: 1;
    width: 300px;
    max-width: 75vw;
    @include animate;
    &::before,
    &::after {
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-100%);
      border-style: solid;
      border-width: 10px 0 0 12px;
      border-color: transparent transparent transparent $white;
    }
    &::after {
      z-index: -1;
      left: -1px;
      border-width: 12px 0 0 $space-unit * 2;
      border-color: transparent transparent transparent get($grey, border);
    }
    p {
      color: get($grey, list);
    }
  }
  &[data-visible='true'] {
    .info-box__content {
      display: block;
    }
  }
}

.tel-message {
  position: absolute;
  top: 80px;
  left: $space-unit;
  z-index: 1;
  line-height: 16px;
  @include breakpoint(md) {
    top: initial;
    right: initial;
    bottom: -0.5rem;
    left: $space-unit;
    + .wrapper-input {
      padding-bottom: $space-unit;
    }
  }
}

[data-whatintent='mouse'] {
  .info-box {
    .info-box__content {
      display: block;
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      .info-box__content {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
