.template-login-registration {
  #account-container {
    padding: $space-unit * 2 $space-unit * 3 $space-unit * 4;
    background: $white;
    border: 1px solid get($grey, border);
    box-sizing: border-box;
    border-radius: ($space-unit * 4);
    margin: 40px 0;

    [data-component='checkPassword'] {
      .dot {
        display: none;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-left: $space-unit;
      }

      .check-password-container {
        display: flex;
        margin-top: 4px;
        justify-content: space-between;
        flex-wrap: wrap;
        .status {
          font-family: get($font, secondary);
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: get($grey, thankyou);
          margin-right: ($space-unit * 2);
          strong {
            color: initial;
            margin-left: $space-unit;
          }
        }

        .steps-container {
          display: flex;
          justify-content: flex-end;
          .steps {
            margin-left: ($space-unit * 2);
            display: flex;
            align-items: center;
            &:first-of-type {
              margin-left: 0;
            }
            span {
              font-size: 12px;
              line-height: 24px;
              margin-left: 4px;
            }
          }
        }

        .icon-v-ok-green {
          display: none;
        }

        .step-completed {
          .icon-v-ok {
            display: none;
          }
          .icon-v-ok-green {
            display: inline-block;
          }
        }
      }

      .field-error-text {
        display: none;
        font-size: 12px;
        line-height: 24px;
        margin-top: 4px;
      }

      input:focus {
        box-shadow: none;
      }

      &.field-error {
        .dot {
          display: inline-block;
          background-color: #ee203c;
        }

        .field-error-text {
          display: block;
        }
        input {
          border: 1px solid #ee203c;
        }
      }

      &.field-ok {
        .dot {
          display: inline-block;
          background-color: #2bb8a4;
        }

        input {
          border: 1px solid #2bb8a4;
        }

      }
    }

    &.registraton-confirmation-container {
      text-align: center;

      .up-finger {
        background-color: #f5f5f5;
        border-radius: 50%;
        margin-bottom: 30px;
      }

      .details {
        margin-bottom: 30px;

        span {
          color: #ee203c;
        }
      }

      .button {
        display: block;
      }
    }

    > h4,
    .h4 {
      text-align: center;
      margin-bottom: $space-unit * 2;
    }

    .caption + input {
      background: #f5f5f5;
      box-sizing: border-box;
      border-radius: 10px;

      &.error {
        border: 1px solid #f03d3e;
      }
    }

    #err-password,
    #err-conf-password {
      display: none;

      &.shown {
        display: block;
      }
    }

    form {
      // margin-bottom: $space-unit * 4;

      .form-field {
        margin-bottom: $space-unit * 2;

        .caption {
          display: block;
          margin-bottom: $space-unit;
          color: get($grey, 'dark');
        }

        .workingdays {
          display: flex;
          align-items: stretch; /* Default */
          //justify-content: space-between;
          width: 100%;
          li {
            margin-bottom: $space-unit;
            flex: 0 1 auto; /* Default */
            list-style-type: none;
            margin-right: 0;
            width: 15%;
            label {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }
          }
        }

        .error {
          color: red;
        }

        .file-field-container {
          display: flex;
          background-color: #f5f5f5;
          border-radius: 10px;

          button {
            background: initial;
            text-transform: initial;
            border-radius: 10px;
            border: 1px solid get($grey, 'dark');
            color: get($grey, 'dark');
            margin: 8px 0 8px 16px;
            font-size: 14px;
            line-height: 16px;
            padding: 0 8px;
          }
        }
      }

      button[role='submit'] {
        margin-top: $space-unit * 3;
        width: 100%;
      }
    }

    .extra-actions {
      margin-bottom: 40px;
    }

    legend {
      text-align: center;
    }

    input[type='file'] {
      padding: 8px 16px;
      display: flex;
      &::-webkit-file-upload-button {
        display: inline-block;
        box-sizing: border-box;
        background: initial;
        border-radius: 10px;
        border: 1px solid get($grey, 'dark');
        color: get($grey, 'dark');
        font-size: 14px;
        line-height: 30px;
        font-family: get($font, secondary);
        font-weight: 600;
        padding: 0 8px;
      }
    }
  }

  // cosa ci fa qui?
  .privacy input[type='checkbox'] {
    display: inline-block;
  }

  .login-footer {
    padding-bottom: $space-unit * 4;
    display: flex;
    align-items: center;
    flex-direction: column;
    a {
      text-decoration: initial;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  @include breakpoint(md, min) {
    #account-container {
      margin-top: $header-height-desktop + ($space-unit * 5);
    }
  }

  @include breakpoint(sm) {
    #account-container {
      margin: 0;
      border: none;
      padding-left: 0;
      padding-right: 0;
      > h4,
      .h4 {
        text-align: left;
        font-size: 24px;
        line-height: 32px;
      }

      form .form-field .caption {
        font-size: 14px;
        margin-bottom: ($space-unit*0.5);
      }
      [data-component='checkPassword'] {
        .check-password-container {
          .steps-container {
            margin-top: 2px;
            .steps {
              margin-left: $space-unit;
            }
          }
        }
      }
    }
  }

  @include breakpoint(sm, min) {
    background-color: get($grey, background);
  }
}

.template-membership-registration {
  [data-component='checkPassword'] {
    .dot {
      display: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: $space-unit;
    }

    .check-password-container {
      display: flex;
      margin-top: 4px;
      justify-content: space-between;
      flex-wrap: wrap;
      .status {
        font-family: get($font, secondary);
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: get($grey, thankyou);
        margin-right: ($space-unit * 2);
        strong {
          color: initial;
          margin-left: $space-unit;
        }
      }

      .steps-container {
        display: flex;
        justify-content: flex-end;
        .steps {
          margin-left: ($space-unit * 2);
          display: flex;
          align-items: center;
          &:first-of-type {
            margin-left: 0;
          }
          span {
            font-size: 12px;
            line-height: 24px;
            margin-left: 4px;
          }
        }
      }

      .icon-v-ok-green {
        display: none;
      }

      .step-completed {
        .icon-v-ok {
          display: none;
        }
        .icon-v-ok-green {
          display: inline-block;
        }
      }
    }

    .field-error-text {
      display: none;
      font-size: 12px;
      line-height: 24px;
      margin-top: 4px;
    }

    input:focus {
      box-shadow: none;
    }

    &.field-error {
      .dot {
        display: inline-block;
        background-color: #ee203c;
      }

      input {
        border: 1px solid #ee203c;
      }
    }

    &.field-ok {
      .dot {
        display: inline-block;
        background-color: #2bb8a4;
      }

      input {
        border: 1px solid #2bb8a4;
      }

    }
  }

  &.registraton-confirmation-container {
    text-align: center;

    .up-finger {
      background-color: #f5f5f5;
      border-radius: 50%;
      margin-bottom: 30px;
    }

    .details {
      margin-bottom: 30px;

      span {
        color: #ee203c;
      }
    }

    .button {
      display: block;
    }
  }

  > h4,
  .h4 {
    text-align: center;
    margin-bottom: $space-unit * 2;
  }

  .caption + input {
    background: #f5f5f5;
    box-sizing: border-box;
    border-radius: 10px;

    &.error {
      border: 1px solid #f03d3e;
    }
  }

  #err-password,
  #err-conf-password {
    display: none;

    &.shown {
      display: block;
    }
  }

  form {
    // margin-bottom: $space-unit * 4;

    .form-field {
      margin-bottom: $space-unit * 2;

      .caption {
        display: block;
        margin-bottom: $space-unit;
        color: get($grey, 'dark');
      }

      .workingdays {
        display: flex;
        align-items: stretch; /* Default */
        //justify-content: space-between;
        width: 100%;
        li {
          margin-bottom: $space-unit;
          flex: 0 1 auto; /* Default */
          list-style-type: none;
          margin-right: 0;
          width: 15%;
          label {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
        }
      }

      .error {
        color: red;
      }

      .file-field-container {
        display: flex;
        background-color: #f5f5f5;
        border-radius: 10px;

        button {
          background: initial;
          text-transform: initial;
          border-radius: 10px;
          border: 1px solid get($grey, 'dark');
          color: get($grey, 'dark');
          margin: 8px 0 8px 16px;
          font-size: 14px;
          line-height: 16px;
          padding: 0 8px;
        }
      }
    }

    button[role='submit'] {
      margin-top: $space-unit * 3;
      width: 100%;
    }
  }

  .extra-actions {
    margin-bottom: 40px;
  }

  legend {
    text-align: center;
  }

  input[type='file'] {
    padding: 8px 16px;
    display: flex;
    &::-webkit-file-upload-button {
      display: inline-block;
      box-sizing: border-box;
      background: initial;
      border-radius: 10px;
      border: 1px solid get($grey, 'dark');
      color: get($grey, 'dark');
      font-size: 14px;
      line-height: 30px;
      font-family: get($font, secondary);
      font-weight: 600;
      padding: 0 8px;
    }
  }
}
