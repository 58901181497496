.experience-component_three_col_features {
  margin-top: $space-unit * 10;
  margin-bottom: $space-unit * 8;
  @include breakpoint(sm) {
    margin-top: $space-unit * 5;
    margin-bottom: $space-unit * 4;
  }
}
.three-col-features {
  &.is-grey {
    .card--features2 {
      border-color: $white;
    }
  }
  h3 {
    margin-bottom: $space-unit * 5;
  }
  @include breakpoint(sm) {
    h3 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: $space-unit * 4;
    }
  }
}
