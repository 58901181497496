#booking-history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $space-unit * 3;
}

#booking-history-csv {

  & > button {
    cursor: pointer;
  }
}

#booking-history-dates {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;

  p + input {
    margin-left: $space-unit * 0.5;
  }

  p {
    font-size: 12px;
    font-weight: 600;
    font-family: get($font, secondary);
  }

  input[type='date'] {
    color: #ee203c;
    width: inherit;
    background-color: inherit;
    border-radius: 0px;
    padding: 0;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;

    &:focus {
      box-shadow: none;
    }
  }
}

#booking-history-children {
  overflow: auto;
  border-radius: 12px;
  background-color: get($grey, background); // This seems to be allowed?
  padding: $space-unit;
  margin-bottom: $space-unit * 3;

  p {
    font-size: 12px;
    line-height: 14px;
  }

  i {
    display: block;
    background-size: 24px;
    width: 24px;
    height: 24px;
  }

  .child {
    float: left;
    margin-right: $space-unit * 2;
    padding: $space-unit 0;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-left: $space-unit;
      font-weight: 500;
    }
  }
}

#booking-history-table {
  width: 100%;

  tr {
    border-bottom: 1px solid get($grey, border);
  }

  th {
    text-align: start;
    font-size: 12px;
    font-family: get($font, secondary);
    font-weight: 700;
  }

  td {
    text-align: start;
    font-family: get($font, secondary);
    font-weight: 400;
    padding: 0.5rem 0;
    cursor: pointer;
  }

  .history-element {
    &__primary {
      font-size: 12px;
    }

    &__secondary {
      font-size: 10px;
    }

    &__primary-right {
      text-align: end;
    }
  }
}

#booking-history-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: $space-unit * 5;

  button {
    margin: 0 $space-unit * 0.5;
    color: #1e1e1e;

    &.active {
      color: #ee203c;
    }
  }
}
