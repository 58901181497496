input {
  background-color: get($grey, background);
  border: 0;
  border-radius: 10px;
  font-weight: 500;
  color: get($grey, dark);
  box-sizing: border-box;
  width: 100%;
  text-overflow: ellipsis;
  &:disabled {
    opacity: 0.7;
    cursor: initial;
  }
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px $black;
  }
  &[type='text'],
  &[type='email'],
  &[type='tel'],
  &[type='password'],
  &[type='number'],
  &[type='date'],
  &[type='time'] {
    padding: $space-unit $space-unit * 2;
    width: 100%;
  }
  &::placeholder {
    color: get($grey, placeholder);
    font-weight: 100;
  }
  &::placeholder,
  & {
    font-family: get($font, paragraph);
    font-size: 16px;
    line-height: 32px;
  }
  &[type='checkbox'],
  &[type='radio'] {
    display: block;
    background: $white;
    border: 1px solid get($grey, checkbox);
    border-radius: 4px;
    width: 25px;
    height: 25px;
    -webkit-appearance: none;
    margin: 0;
    transition: background-color 0.2s ease-in-out;
    &:checked {
      border-color: get($red);
      @include sprite('checkmark');
      background-color: get($red);
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: center 55%;
    }
    &:disabled {
      &:not([type='checkbox']) {
        background: get($grey, light);
      }
      border-color: get($grey, light);
    }
    &:focus {
      border-color: get($red) !important;
      box-shadow: inset 0 0 0px 1px get($red);
    }
  }
  &[type='radio'] {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    &:checked {
      background: $white;
      border-color: get($grey, checkbox);
      & + .mbsc-input-fill {
        background: get($red);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }
    &:disabled {
      background: get($grey, light);
      border-color: get($grey, light);
      & + .mbsc-input-fill {
        display: none;
      }
    }
  }
  &.focused {
    box-shadow: inset 0 0 0 2px $black;
  }
  &.promofilled {
    box-shadow: inset 0 0 0 2px get($alerts, 'success');
    @include sprite('check-circle');
    background-color: get($grey, 'background');
    background-size: $space-unit * 3;
    background-position: calc(100% - #{$space-unit * 2}) center;
  }
  &::-ms-reveal {
    display: none;
  }
}

#discountcode {
  border: 0;
  box-shadow: initial;
  &.promofilled {
    box-shadow: inset 0 0 0 2px get($alerts, 'success') !important;
  }
  &.promoerror {
    box-shadow: inset 0 0 0 2px get($red) !important;
  }
  @include breakpoint(md, min) {
    border-color: get($grey);
    box-shadow: inset 0 0 0px 1px get($grey);
    background-color: white;
    &.promofilled {
      box-shadow: inset 0 0 0 1px get($alerts, 'success') !important;
    }
    &.promoerror {
      box-shadow: inset 0 0 0 1px get($red) !important;
    }
  }
}

.wrapper-input {
  position: relative;
  display: block;
  box-sizing: border-box;
  margin-bottom: $space-unit * 2;
  width: 100%;
  &.hidden {
    display: none;
  }
  label {
    position: relative;
    display: block;
  }
  &.icon-email {
    input {
      padding-left: $space-unit * 7;
    }
    label {
      &::before {
        @include labelIcon('mail-icon');
      }
    }
  }
  &.reverse,
  &.radio-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    p {
      margin: 0;
      margin-left: $space-unit;
    }
  }
  &.icon-phone {
    input {
      padding-left: $space-unit * 7;
    }
    label {
      &::before {
        @include labelIcon('phone');
      }
    }
  }
  &.error {
    input {
      padding-right: $space-unit * 7;
      box-shadow: inset 0 0 0 2px get($red);
    }
    label {
      &::after {
        @include labelIcon('field-error', right);
      }
    }
  }
  .clear-input {
    @include spritex('x');
    position: absolute;
    bottom: 0;
    transform: translateY(-50%);
    right: 50px;
    display: block;
    z-index: 1;
    cursor: pointer;
    @include breakpoint(sm) {
      right: 10px;
    }
  }
  .caption {
    margin-bottom: $space-unit * 0.5;
    white-space: pre;
  }
  &.clearable {
    input {
      padding-right: $space-unit * 6;
    }
  }
}

.mbsc-input {
  &.mbsc-err {
    input {
      padding-right: $space-unit * 7;
      box-shadow: inset 0 0 0 2px get($red);
    }
    &::after {
      @include labelIcon('field-error', right);
      top: -1px;
    }

    .mbsc-err-msg {
      padding-left: $space-unit * 7;
      display: flex;
      align-items: center;
      margin-top: $space-unit;
      text-align: left;
      height: 48px;
      @include sprite('field-error');
      background-size: 24px;
      background-color: get($red, light);
      background-position: $space-unit * 2 45%;
      border-radius: $space-unit;
      color: get($red);
    }
  }
  .mbsc-err-msg {
    display: none;
  }
}
