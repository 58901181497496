@use "sass:math";
@use "sass:list";

.our-partners {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0;
  text-align: center;
  h4 {
    margin-bottom: 40px;
  }
  .wrapper-partners {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .partner {
    position: relative;
    display: flex;
    justify-content: center;
    height: 80px;

    &::before {
      border-right: 1px solid get($grey, border);
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      right: 0;
    }
    &:last-child {
      &::before {
        content: unset;
      }
    }
  }
  @include breakpoint(md) {
    .wrapper-partners {
      grid-template: list.slash(repeat(2, 1fr), repeat(2, 1fr));
    }
    .partner {
      height: 15vw;
      min-height: 90px;
      img {
        max-width: 75%;
        padding: 7% 0;
      }
      &:nth-child(odd) {
        &::before {
          border-right: 1px solid get($grey, border);
        }
      }
      &:nth-child(-n + 2) {
        &::before {
          border-bottom: 1px solid get($grey, border);
        }
      }
      &:nth-child(even) {
        &::before {
          border-right: none;
        }
      }
    }
  }
}
