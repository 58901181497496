.newsletter-subscription {
  width: 80%;
  margin: $space-unit * 3 auto;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 0 15px 20px;
  min-height: 200px;
  .newsletter-wrapper {
    padding-right: 40px;
    padding-left: 20px;
    .newsletter-description {
      display: flex;
      align-items: center;
      .newsletter-text {
        padding-left: 15px;
      }
    }
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  .icon-email {
    &::before {
      @include labelIcon('mail-icon');
      left: $space-unit * 3;
    }
    input {
      padding-left: $space-unit * 7;
    }
  }
  .newsletter-icon {
    @include sprite('newsletter');
    display: block;
    width: 238px;
    height: 238px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .newsletter-icon-new {
    @include sprite('newsletter-new');
    display: block;
    width: 179px;
    height: 179px;
    min-width: 179px;
  }
  .newsletter-privacy-wrapper {
    margin-top: 13px;
    .newsletter-privacy-inner-wrapper {
      display: flex;
      .switch {
        margin: 0 10px;
      }
      p {
        font-size: 10px;
        line-height: 16px;
        a {
          color: $black;
        }
      }
    }
  }
  h4 {
    letter-spacing: 0;
    line-height: 40px;
  }
  @include breakpoint(lg) {
    .newsletter-icon {
      width: 15vw;
      height: 15vw;
    }
  }
  @include breakpoint(md) {
    width: 90%;
    padding: $space-unit * 2 0;
    .newsletter-wrapper {
      padding: 0 $space-unit * 2;
    }
    .newsletter-icon {
      width: 25vw;
      height: 25vw;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
    .newsletter-icon-new {
      width: 90px;
      height: 90px;
      min-width: 90px;
    }
    .newsletter-description {
      margin-bottom: $space-unit * 2;
    }
    .newsletter-text {
      text-align: left;
      h4 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .newsletter-form .button {
      margin-top: 0;
    }
    .newsletter-privacy-wrapper {
        margin-top: 13px;
      .newsletter-privacy-inner-wrapper .switch {
        margin-left: 0;
      }
      p {
        text-align: left;
      }
    }
  }
  @include breakpoint(sm) {
    .newsletter-icon {
      width: 170px;
      height: 170px;
    }
    h4 {
      margin-bottom: $space-unit * 3;
      font-size: 24px;
      line-height: 32px;
    }
  }
}
.newsletter-form {
  align-items: center;
  input[type='email'] {
    margin-bottom: 13px;
  }
  .button {
    width: 100%;
  }
  label {
    &[for='nl-email'] {
      margin-bottom: 0;
      input {
        box-sizing: border-box;
      }
    }
    &[for='nl-privacy'] {
      display: flex;
      align-items: center;
      margin-top: $space-unit * 2;
    }
  }
  input[type='checkbox'] {
    margin-right: $space-unit * 2;
  }

  @include breakpoint(md) {
    text-align: center;

    input[type='email'] {
      display: block;
      width: 100%;
    }
    .button {
      position: relative;
      display: block;
      right: initial;
      width: 100%;
      margin-top: $space-unit * 3;
    }
    label {
      &[for='nl-privacy'] {
        margin-top: $space-unit * 4;
        justify-content: center;
      }
    }
  }
}
