.experience-slider {
  position: relative;
  overflow: hidden;
  padding: $space-unit * 5 0;
  color: $white;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  > .mbsc-grid {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .experience-carousel {
    position: relative;
    width: 50%;
    padding: 60px ($space-unit * 3) 60px $space-unit;
    margin-left: 50%;
    box-sizing: border-box;
    overflow: hidden;
    @include swiper();
    .swiper-button-next,
    .swiper-button-prev {
      @include sprite('chevron-right-white');
      background-size: 80%;
      background-position: 60% center;
      background-color: get($red);
      width: 35px;
      height: 35px;
      border: 1px solid get($red);
      border-radius: 50%;
      box-sizing: border-box;
      bottom: 0;
      right: initial;
      &.swiper-button-disabled {
        opacity: 1;
        @include sprite('chevron-right');
        background-size: 80%;
        background-position: 60% center;
        background-color: $white;
        border-color: get($grey, border);
      }
    }
    .swiper-button-next {
      left: 55px;
      transform: initial;
    }
    .swiper-button-prev {
      left: $space-unit;
      transform: rotate(-180deg);
      top: initial;
    }
    a {
      color: inherit;
      text-decoration-line: none;
      height: 100%;
      display: block;
    }
  }
  @include breakpoint(sm) {
    > .mbsc-grid {
      position: relative;
      top: initial;
      transform: initial;
    }
    .experience-carousel {
      width: 100%;
      padding-left: $space-unit * 3;
      padding-top: $space-unit * 4;
      padding-bottom: 0;
      margin-left: 0;
    }
    .wrapper-text {
      h3 {
        font-size: 32px;
        line-height: 40px;
      }
      p {
        margin-bottom: $space-unit * 4;
      }
    }
  }
  @media only screen and (min-width: 1250px) {
    padding: $space-unit * 10 0;
  }
  @media only screen and (min-width: 1328px) {
    .experience-carousel {
      padding-right: calc((100% - 1280px) / 2);
    }
  }
  @media only screen and (min-width: 1440px) {
    .experience-carousel {
      padding-right: $space-unit * 10;
    }
  }
  @media only screen and (min-width: 1616px) {
    .experience-carousel {
      padding-right: calc((100% - 1440px) / 2);
    }
  }
}
