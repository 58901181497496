.partner-popup__content {
  background-color: white;
  width: 66%;
  margin: auto;
  width: 100%;
  padding-bottom: $space-unit * 2;
  img {
    max-width: 235px;
  }
}

.partner-popup__actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  @media only screen and (min-width: 768px) {
    flex-direction: row-reverse;
    align-items: center;
  }

  button {
    flex: 1;
    width: 100%;

    & + button {
      margin-left: 8px;
    }
  }
}
