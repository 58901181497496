.booking-box {
  position: relative;
  margin-bottom: $space-unit * 7;
  align-items: center;
  z-index: 3;
  @include breakpoint(md, min) {
    width: 100%;
    display: flex;
  }
  .booking-box__body {
    position: relative;
    z-index: 1;
    padding: 20px 20px 10px;
    background-color: $white;
    border-radius: 30px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    .button--return {
      @extend input;
      display: flex;
      flex: 100%;
      position: relative;
      align-items: center;
      .button-return-content {
        position: relative;
        padding-left: 20px;
        font-size: 16px;
        @include breakpoint(sm) {
          padding-left: 6px;
        }
      }
    }
  }

  .booking-box__popup {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background-color: $white;
    overflow: hidden;
    padding: $space-unit * 3 0 $space-unit * 3 $space-unit * 3;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    @include breakpoint(md, min) {
      top: calc(100% + 10px);
      max-width: 33%;
      right: auto;
    }
    &.visible {
      opacity: 1;
      visibility: visible;
      @include breakpoint(sm) {
        & + .booking-box__body {
          .booking-box__membership {
            display: none;
          }
        }
      }
    }
    &.calendar {
      width: calc(220% + 32px);
      @include breakpoint(md, min) {
        max-width: 67%;
      }
    }
    .wrapper-station-list {
      position: relative;
      width: calc(100% - 32px);
      height: 100%;
      display: none;
      overflow: auto;
      scrollbar-color: black get($grey, bgcard);
      scrollbar-width: thin;
      padding-right: 24px;
      scroll-behavior: smooth;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background: get($grey, bgcard);
        border-radius: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background: get($red);
        border-radius: 4px;
      }
      &.active {
        display: block;
      }
      .station-list-item {
        border-bottom: 1px solid get($grey, border);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        h6 {
          padding: 8px 0;
          color: get($grey, list);
          padding-right: 8px;
          @include text-shorten;
        }
        .heart {
          @include spritex('heart');
        }
        &.focused {
          background-color: get($grey, placeholder);
        }
        &.last-with-priority {
          border-bottom: 2px solid red;
        }
      }
      .alert--notification {
        display: block;
        position: absolute;
        width: 100%;
        a {
          color: inherit;
          cursor: pointer;
        }
      }
      h5 {
        background-color: rgba(225, 38, 29, 0.1);
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: get($red);
        padding: 6px;
        text-transform: uppercase;
        border-radius: 6px;
      }
    }

    .custom-range {
      display: block;
      position: relative;
      width: calc(100% - 40px);
      height: 100%;
      box-sizing: border-box;
      background-color: $white;
      .mbsc-fr-persp {
        width: 100% !important;
        height: 100% !important;
      }
      .mbsc-fr-popup {
        position: relative;
        top: 0 !important;
        left: 0 !important;
        height: 100%;
        overflow-y: auto;
        .mbsc-fr-w {
          width: 100% !important;
          box-shadow: none;
          border-radius: 0;
          // height: inherit;
          background-color: $white;
          .mbsc-fr-c {
            // height: inherit;
            .mbsc-cal-c {
              padding: 0;
              // height: inherit;
            }
          }
        }
      }
      .mbsc-cal-day-picker {
        .mbsc-cal-scroll {
          width: 50% !important;
        }
      }
    }
  }

  .booking-box__membership {
    @include breakpoint(md, min) {
      top: auto;
      bottom: -6px;
      @include spritex('logo-membership');
    }
    @include breakpoint(sm) {
      @include spritex('logo-membership-mobile');
    }
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -14px;
    padding: 0 24px;
  }

  .booking-box__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h6,
    span {
      display: none;
    }
  }

  .booking-box__form {
    position: relative;
    width: 100%;
    box-sizing: border-box;

    .stepper,
    .wrapper-input,
    .wrapper-rangeEnd {
      @include breakpoint(md, min) {
        min-width: 120px;
      }
      @include breakpoint(mdlg, min) {
        min-width: 145px;
      }
      @include breakpoint(lg, min) {
        min-width: 160px;
      }
      width: 100%;
      margin-bottom: $space-unit;
    }

    .wrapper-range {
      .mbsc-comp {
        display: flex;
        gap: 8px;
        @include breakpoint(md, min) {
          gap: 4px;
          > div {
            width: calc(50% - 8px);
          }
        }
      }
    }

    .stepper-wrapper {
      gap: 8px;
      justify-content: space-between;
      @include breakpoint(md, min) {
        gap: 4px;
      }
    }

    .stepper {
      &__inner {
        pointer-events: none;
        position: absolute;
        inset: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: Roboto;
        font-weight: 500;
        letter-spacing: -0.02em;
      }

      &__subtext {
        pointer-events: none;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 11px;
      }
      
      .mbsc-comp.mbsc-control {
        text-align: center;
        color: transparent;
      }
    }


    .wrapper-station-select {
      display: flex;
      position: relative;
      gap: 4px;
    }

    .button--primary {
      min-width: 145px;
      z-index: 1;
      text-wrap: nowrap;
    }

    .wrapper-rangeEnd {
      display: flex;
    }

    .coupon {
      width: 100%;
      align-items: center;
      gap: 20px;
      &.mbsc-collapsible-open {
        .mbsc-collapsible-content {
          opacity: 1;
          visibility: visible;
        }
      }
      .mbsc-collapsible-content {
        opacity: 0;
        visibility: hidden;
        overflow: visible;
      }
      .mbsc-collapsible-header {
        margin-bottom: $space-unit * 2;
        padding-right: 0;
        cursor: pointer;
      }
      .mbsc-input {
        display: block;
        position: relative;
      }
      .coupon-code {
        color: get($red);
        text-align: left;
        cursor: pointer;
        &:focus {
          text-decoration: underline;
        }
        &.success {
          color: get($green);
          display: flex;
          align-items: center;
          &::after {
            content: '';
            @include spritex('success');
            margin-left: 4px;
          }
        }
      }
      &.desktop {
        position: relative;
        width: fit-content;
        .discountcode-popup {
          display: none;
          background-color: white;
          position: absolute;
          min-width: 300px;
          padding: 20px;
          top: calc(100% + 10px);
          border-radius: 20px;
          transform: translateX(-30%);
          box-shadow: 0px -4px 20px 0px #00000040;
          &::after {
            content: '';
            border-color: transparent transparent white transparent;
            border-style: solid;
            border-width: 8px;
            position: absolute;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
          }
          #discountcode {
            text-align: center;
          }
          &.open {
            display: block;
          }
          .mbsc-input.mbsc-err::after {
            display: none;
          }
        }
      }
      .clear-input {
        @include spritex('x-grey');
        top: 24px;
      }
      .remove-coupon {
        color: get($red);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .search-error {
    color: #f03d3e;
    text-align: center;
    width: 100%;
    background-color: rgba(238, 32, 60, 0.1);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 3px;
    .icon-error {
      width: 16px;
      height: 16px;
    }
  }

  .recent-logo {
    @include spritex('recent');
  }
  .btnswitch {
    @include spritex('switch');
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    background-color: white;
    box-shadow: 0px 4px 20px 0px #00000040;
    border-radius: 15px;
    padding: 1.5px 7.5px;
    background-size: 27px 27px;
    width: 27px;
    height: 27px;
  }

  @include breakpoint(md) {
    margin: 0;
    z-index: initial;

    .loading-screen {
      border-radius: 30px;
      min-height: 470px;
    }
    .booking-box__title {
      h4 {
        display: none;
      }
    }
    .booking-box__popup {
      position: fixed;
      height: calc(100% - 192px);
      z-index: 10;
      padding: $space-unit * 3;
      padding-bottom: 0;
      top: initial;
      bottom: 0;
      border-radius: 0;
      padding-top: 0;
      &.visible {
        right: 0;
        + .booking-box__body {
          position: fixed;
          top: 0;
          left: 0;
          z-index: 5;
          width: 100vw;
          height: 100vh;
          padding: 0;
          background-color: $white;
          border-radius: 0;
          .booking-box__title {
            position: relative;
            height: $header-height-mobile;
            padding: 0 $space-unit * 3;
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
            h4 {
              display: none;
            }
            span.close {
              position: absolute;
              top: 50%;
              right: $space-unit * 3;
              display: block;
              cursor: pointer;
              transform: translateY(-50%);
            }
          }
          .booking-box__form {
            padding: $space-unit * 4 $space-unit * 3;
          }
        }
        &.calendar {
          + .booking-box__body {
            .booking-box__title {
              .heading-range {
                display: block;
              }
            }
            .booking-box__form {
              > :not(.booking-box__form-row) {
                display: none;
              }
            }
            .booking-box__form-row {
              > :not(.wrapper-range) {
                display: none;
              }
            }
          }
        }
        &:not(.calendar) {
          + .booking-box__body {
            .booking-box__title {
              .heading-stations {
                display: block;
              }
            }
            .booking-box__form {
              > :not(.booking-box__form-row) {
                display: none;
              }
            }
            .booking-box__form-row {
              > :not(.wrapper-station-select) {
                display: none;
              }
            }
          }
        }
      }
      &.calendar {
        width: 100%;
      }
      .wrapper-station-list {
        width: 100%;
      }
      .custom-range {
        width: 100%;
        .mbsc-cal-day-picker {
          .mbsc-cal-scroll {
            width: 100% !important;
          }
        }
        .mbsc-cal-table {
          border-spacing: 0 8px;
        }
      }

      .promo-valid {
        background-color: green;
        padding: 10px;
      }
    }
  }

  @include breakpoint(sm) {
    padding: 16px;
    .booking-box__body {
      padding-top: $space-unit * 2;
      .button--return {
        &::before {
          bottom: -3px;
        }
      }

      .booking-box__title {
        padding-bottom: 0;
      }
      .booking-box__form {
        .stepper,
        .wrapper-input,
        .wrapper-rangeEnd {
          margin-bottom: $space-unit;
        }
        .coupon {
          margin-top: 16px;
          .coupon-title-wrapper {
            display: flex;
            justify-content: center;
            gap: 20px;
            &.success {
              .coupon-code {
                color: get($green);
                display: flex;
                align-items: center;
                &::after {
                  content: '';
                  @include spritex('success');
                  margin-left: 4px;
                }
              }
            }
          }
          .mbsc-collapsible-header {
            margin-bottom: $space-unit;
          }
        }
        .coupon-code {
          text-align: center;
        }
        .button--primary {
          width: 100%;
        }
      }
    }
    .open.modal {
      background-color: rgba($color: #000000, $alpha: 0.4);
      .modal {
        &__container {
          bottom: 0;
          top: auto;
          height: fit-content;
          border-radius: 20px 20px 0 0;
        }
      }
    }
  }
  .hidden-coupon-code {
    width: 100%;
  }
}
.hidden-coupon-code {
  color: get($red);
  width: 100%;
  text-align: center;
  font-size: 1rem;
  margin: 4px 0 12px;
  text-transform: uppercase;
  font-weight: 800;
}
@include breakpoint(md, min) {
  .coupon-bar .hidden-coupon-code {
    margin-left: 12px;
  }
  .booking-box {
    .booking-box__form-row {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
    .booking-box__popup {
      height: 435px;
    }
  }
}
