.error-message {
  padding-left: $space-unit * 7;
  padding-right: $space-unit;
  display: flex;
  align-items: center;
  margin-top: $space-unit;
  text-align: left;
  min-height: 48px;
  @include sprite('field-error');
  background-size: 24px;
  background-color: get($red, light);
  background-position: $space-unit * 2 45%;
  border-radius: $space-unit;
  color: get($red);
}

.mbsc-collapsible-header {
  &:focus {
    text-decoration: underline;
  }
}

.mbsc-collapsible-header {
  cursor: pointer;
}

.mbsc-switch {
  margin: 0;
  padding: 2px 0;
  cursor: pointer;
  position: relative;
  display: block;
  input[type='checkbox'] {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
    margin: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:checked {
      + .mbsc-switch-track {
        border-color: get($flex, bg);
        background: get($flex, bg);
        .mbsc-switch-handle {
          background: $white;
        }
      }
    }
    &:focus {
      + .mbsc-switch-track {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
  }

  .mbsc-switch-track {
    position: absolute;
    top: 50%;
    display: block;
    right: 0;
    width: $space-unit * 6;
    height: $space-unit * 3;
    margin-top: 0;
    border-radius: 12.5px;
    background-color: $white;
    border: 1px solid get($grey, border);
    transform: translateY(-50%);
  }

  .mbsc-progress-track {
    position: relative;
    display: block;
    height: 100%;
    padding: 0 ($space-unit + $space-unit * 0.5);
  }

  .mbsc-slider-handle-cont {
    position: relative;
    display: block;
    top: 50%;
    transform: translate(-50%, -50%);
    width: $space-unit * 2;
    height: $space-unit * 2;
    @include animate;
  }

  .mbsc-switch-handle {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: get($grey, checkbox);
  }

  .mbsc-switch-txt-off,
  .mbsc-switch-txt-on {
    display: none;
  }
}

small {
  &.number {
    background-color: get($red, light);
    color: get($red);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: $space-unit;
    font-family: get($font, secondary);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    border: 1px solid white;
    &.inverse {
      background-color: get($red);
      color: $white;
    }
  }
}

.alert-error {
  padding: $space-unit 0 $space-unit $space-unit * 7;
  display: flex;
  align-items: center;
  margin-top: $space-unit;
  text-align: left;
  min-height: 48px;
  @include sprite('field-error');
  background-size: 24px;
  background-color: get($red, light);
  background-position: $space-unit * 2 45%;
  border-radius: $space-unit;
  color: get($red);
  margin-bottom: $space-unit * 4;
}
