.custom-select {
  position: relative;
  margin-bottom: $space-unit * 2;

  &.error {
    &::after {
      content: '';
      top: 44px;
      right: 40px;
      z-index: 3;
      position: absolute;
      display: block;
      height: $space-unit * 3;
      width: $space-unit * 3;
      @include sprite('field-error');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  // &::after {
  //   @include labelIcon('chevron-down', right);
  //   pointer-events: none;
  //   // z-index: 3;
  // }

  .icon-travels + select {
    padding-left: $space-unit * 6;
  }

  select {
    display: block;
    border-radius: 10px;
    color: get($grey, dark);
    box-sizing: border-box;
    border-color: transparent;
    width: 100%;
    height: $space-unit * 6;
    padding: 0 $space-unit;
    font-size: 16px;
    line-height: 24px;
    background-color: get($grey, fog);
    font-family: get($font, paragraph);
    -webkit-appearance: initial;

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 2px $black;
    }
  }

  * {
    box-sizing: border-box;
  }
  > .caption {
    margin-bottom: $space-unit;
    white-space: pre;
  }
  .select-label {
    position: relative;
    .select-arrow {
      content: '';
      @include sprite('chevron-down');
      position: absolute;
      right: $space-unit * 2;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      display: block;
      z-index: 3;
      @include animate();
    }
  }
  .select-input {
    position: relative;
    z-index: 0;
    &:focus,
    &.focused,
    &.error {
      z-index: 2;
    }
    &.focused {
      box-shadow: inset 0 0 0 2px $black;
      + .select-arrow {
        transform: translateY(-50%) rotate(-180deg);
      }
    }
    &.error {
      box-shadow: inset 0 0 0 2px red;
    }
  }
  .select-list {
    position: absolute;
    //to avoid seeing label radius
    top: calc(100% - 10px);
    padding-top: 10px;
    left: 0;
    width: 100%;
    max-height: 170px;
    margin-bottom: $space-unit * 3;
    overflow-y: auto;
    background-color: $white;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 10px 20px rgba(71, 14, 14, 0.15);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 4;
    transform: translateY(-40%);
    transition: all 0.2s ease-in-out;
    &[aria-expanded='true'] {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
    .select-item {
      padding: $space-unit $space-unit * 2;
      color: get($grey, darkest);
      height: $space-unit * 5;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active {
        background-color: get($grey, background);
      }
      &:focus,
      &:hover {
        background-color: get($grey, light);
        outline: none;
      }
    }
  }
  .icon-travels {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
  @include breakpoint(sm) {
    &.passengers-select {
      margin-top: $space-unit * 2;
      &::after {
        content: none;
      }
      .select-label {
        display: block;
        z-index: 2;
      }
      .select-input {
        color: get($grey, fog);
        z-index: initial;
      }
      .input-overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: $space-unit ($space-unit * 6) $space-unit ($space-unit * 2);
        align-items: center;
        white-space: nowrap;
        cursor: pointer;
      }
      .passenger-name {
        flex-shrink: 1;
        margin-right: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .seat-details {
        flex-shrink: 0;
      }
      .select-item {
        height: auto;
        justify-content: space-between;
      }
    }
  }
}
