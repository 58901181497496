.partner-registration {
  h5 {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .hidden {
    height: 0;
    opacity: 0;
  }
}

.add-pointofsale {
  cursor: pointer;
  text-align: center;
  color: #c2132b;
}

#dwfrm_partner .button-primary {
  margin: auto;
}
