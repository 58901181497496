.hero-title {
  padding-top: $header-height-desktop + 40px;
  padding-bottom: $space-unit * 5;
  background-color: get($grey, background);
  position: relative;
  overflow: hidden;
  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  &.white-text {
    color: $white;
  }
  &-photo {
    @extend .hero-title;
    padding-top: $header-height-desktop + $space-unit * 25;
    padding-bottom: $space-unit * 25;
    .caption,
    h3,
    .h3 {
      color: $white;
    }
    @include breakpoint(sm) {
      .caption {
        margin-bottom: $space-unit;
      }
    }
  }
  &-illustration {
    @extend .hero-title;
    padding-top: $header-height-desktop;
    padding-bottom: 0;
    background-color: get($grey, background);
    .wrapper-text {
      padding: $space-unit * 22 0 $space-unit * 24;
    }
    .illustration {
      position: relative;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
      }
    }
    @include breakpoint(md, min) {
      .wrapper-text {
        padding-right: 10%;
      }
      .illustration {
        height: 100%;
        img {
          position: absolute;
          top: 42%;
          transform: translate(-50%, -50%);
          left: 50%;
          width: 120%;
        }
      }
    }
    @include breakpoint(md) {
      padding: 0 !important;
      .wrapper-text {
        padding-top: $space-unit * 6;
        padding-bottom: 0;
        text-align: center;
        .caption {
          margin-bottom: $space-unit * 2;
        }
      }
      h3,
      .h3 {
        font-size: 24px;
        line-height: 32px;
      }
      .illustration {
        img {
          width: 130%;
          position: relative;
          left: 54%;
          transform: translateX(-50%);
          margin-top: -42px;
          margin-bottom: 28px;
        }
      }
    }
  }
  @include breakpoint(md) {
    padding: $space-unit * 4 0;
    h3,
    .h3 {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
