h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: get($font, primary);
  font-weight: 600;
  letter-spacing: -0.02em;
}

h1 {
  font-size: 72px;
  line-height: 80px;
}

h2,
.h2 {
  font-size: 56px;
  line-height: 64px;
}

h3,
.h3 {
  font-size: 40px;
  line-height: 48px;
}

h4,
h5,
h6,
.h4 {
  font-family: get($font, secondary);
}

h4,
.h4 {
  font-size: 32px;
  line-height: 40px;
}

h5 {
  font-size: 24px;
  line-height: 32px;
}

h6 {
  font-size: 16px;
  line-height: 24px;
}

caption,
.caption {
  font-family: get($font, secondary);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.feature-paragraph,
.big-paragraph {
  font-family: get($font, paragraph);
}

.feature-paragraph {
  font-weight: 300;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.01em;
}

//paragraph on figma
.big-paragraph {
  font-size: 18px;
  line-height: 28px;
}

p,
div,
.small-paragraph {
  font-family: get($font, paragraph);
  font-size: 14px;
  line-height: 22px;
}

strong {
  font-weight: bold;
}

i {
  font-style: italic;
}

.microcopy {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

button,
.button {
  font-family: get($font, secondary);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: center;
  color: get($red);
  text-decoration-line: none;
}

a.button {
  display: inline-flex;
  width: auto;
}

a {
  text-decoration-line: underline;
  color: get($red);
}

.link {
  font-size: 18px;
  line-height: 24px;
  font-family: get($font, paragraph);
}

.page-title {
  margin-top: 200px;
}

@include breakpoint(sm) {
  h1,
  h2,
  h3 {
    font-family: get($font, secondary);
  }
}
