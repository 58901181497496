.grid-container {
  position: relative;
  width: 100%;
  .absolute-grid {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1;
    pointer-events: none;
    .mbsc-grid,
    .mbsc-row,
    .sidebar-wrapper {
      height: 100%;
    }
  }
  .sidebar-wrapper {
    pointer-events: auto;
    padding-top: 72px;
    padding-bottom: 24px;
  }
  @include breakpoint(md) {
    .sidebar-wrapper {
      display: none;
    }
    .absolute-grid {
      z-index: 2;
      &.hide-sidebar {
        display: none;
      }
    }
  }
}
@import 'sidebar';
@import 'sidebar-footer';
