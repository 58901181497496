.thankyou-template {
  margin-top: $header-height-desktop;
  .bon-voyage {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: ($space-unit * 8) 0;
    .message {
      margin-top: $space-unit * 3;
      margin-bottom: $space-unit * 3;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: get($grey, thankyou);
      font-family: get($font, secondary);
      font-weight: 600;
    }
    h4 {
      margin-bottom: $space-unit * 3;
    }
    .quick-actions {
      display: flex;
      margin-bottom: $space-unit * 3;
      .action {
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: get($grey, dark);
        margin-right: $space-unit * 2;
        text-transform: initial;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .button--primary {
      min-width: 312px;
    }
    img {
      max-height: 143px;
      @include breakpoint(sm) {
        display: none;
      }
    }
  }

  .modify-booking {
    padding-top: 45px;
    padding-bottom: 45px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .modify-booking-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
    }
    .modify-booking-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
    }
    .modify-booking-cta {
      max-width: 312px;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  p.missing-ticket {
    margin-bottom: $space-unit;
  }

  .button-actions {
    width: calc(100% + 16px);
    margin-bottom: $space-unit;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    a {
      width: 100%;
    }
    &.mobile {
      display: none;
    }
  }

  .wrapper-image-fullscreen {
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    img {
      width: 80%;
      float: right;
    }
  }

  .trip-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: auto auto 32px;
    .card--ticket-anchor {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > h5 {
        position: absolute;
        padding: 0 $space-unit;
        top: 0;
        background: #fff;
        z-index: 1;
      }
      .card--ticket {
        width: 100%;
        padding-bottom: 0;
      }
    }
    .action {
      position: absolute;
      top: 90%;
      right: 50%;
      transform: translateX(50%);
      height: auto;
      z-index: 2;
      padding: 6px 12px;
      text-transform: none;
      gap: 6px;
      &.show {
        z-index: 3;
      }
      .calendar-msg {
        font-size: 14px;
        line-height: 22px;
        font-family: get($font, secondary);
        font-weight: 600;
        text-transform: initial;
        padding-left: 6px;
      }
    }
  }

  .trip-actions {
    display: flex;
    flex-direction: column;
    padding-top: $space-unit;
    a:not(:last-child) {
      margin-bottom: ($space-unit * 2);
    }
  }

  .back-home {
    text-align: center;
    margin-top: $space-unit;
    margin-bottom: $space-unit * 3;
  }

  .experience-component_editorial_card_wrapper {
    margin-top: 40px;
  }

  @include breakpoint(md, min) {
    .bon-voyage {
      max-width: 847px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 24px 103px;
      box-shadow: 0px 5px 20px 0px #0000001a;
      border-radius: 20px;
    }
  }

  @include breakpoint(md) {
    margin-top: 0;
    overflow: hidden;
    .bon-voyage {
      margin: $space-unit * 3 auto;
      margin-bottom: $space-unit;
      h3 {
        font-size: 32px;
        line-height: 40px;
      }
      h4 {
        margin-bottom: $space-unit;
      }
      .message {
        margin-bottom: $space-unit;
      }
    }
    .button-actions {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
      }
    }
    .experience-main.experience-region {
      max-width: 100%;
    }
    .wrapper-image-fullscreen {
      img {
        width: 100%;
      }
    }
    .trip-box {
      &:nth-child(1) {
        .card--ticket-anchor {
          padding-right: $space-unit;
        }
        .action {
          position: absolute;
          top: 90%;
          right: 50%;
          transform: translateX(50%);
        }
      }
      &:nth-child(2) {
        .card--ticket-anchor {
          padding-left: $space-unit;
        }
      }
    }
    .back-home {
      &.desktop {
        display: none;
      }
    }
  }
  @include breakpoint(sm) {
    .trip-actions {
      a:not(:last-child) {
        margin-bottom: ($space-unit * 3);
      }
      .mobile-link {
        position: relative;
        border: none;
        height: auto;
        padding-top: ($space-unit * 2);
        padding-bottom: ($space-unit * 2);
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 100vw;
          transform: translateX(-50%);
          height: 1px;
          background-color: get($grey, border);
        }
      }
    }
    .experience-component_editorial_card_wrapper {
      margin-top: 24px;
    }
    .wrapper-image-fullscreen {
      margin-top: -($space-unit * 5);
    }
    .bon-voyage {
      .message {
        width: 100%;
        margin-top: $space-unit;
      }
      .quick-actions {
        margin-bottom: 0;
      }
    }
    .button-actions {
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
      }
    }
    .trip-box {
      h5 {
        font-size: 16px;
        line-height: 24px;
      }
      &:nth-child(1) {
        .card--ticket-anchor {
          padding-right: 0;
        }
      }
      &:nth-child(2) {
        .card--ticket-anchor {
          padding-left: 0;
        }
      }
    }
  }
}

.voucherspage--carnets {
  .thankyou-template {
    @include breakpoint(md, min) {
      .bon-voyage {
        justify-content: flex-start;
      }

      .wrapper-image-fullscreen {
        margin-top: 4rem;
      }
    }
  }
}
