.legs-table {
  width: 100%;
}

.route-item {

  &.selected {
    box-shadow: 0 0 0 2px #00bca1;
    border: 1px solid #00bca1;
  }

  &.selected-EXTRA {
    box-shadow: 0 0 0 2px #14abff;
    border: 1px solid #14abff;
  }

  &.selected-PARTNER {
    box-shadow: 0 0 0 2px #EE203C;
    border: 1px solid #EE203C;
  }

  &.disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
  &.rebook-original {
    &.selected {
      box-shadow: 0 0 0 2px get($red, default);
      border: 1px solid get($red, default);
    }
  }

  @include breakpoint(sm) {
    &.selected {
      box-shadow: 0 0 0 1px #00bca1;
    }
    
    &.selected-EXTRA {
      box-shadow: 0 0 0 1px #14abff;
    }

    &.selected-PARTNER {
      box-shadow: 0 0 0 1px #EE203C;
    }
  }
}
