.loading-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  // background-color: $white;
  top: 0;
  left: 0;
  &,
  * {
    box-sizing: border-box;
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include sprite('logo');
    display: block;
    height: 5vw;
    width: 5vw;
    background-size: 100%;
    animation: pulse-logo 1.6s infinite;
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 9vw;
    width: 9vw;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    animation: pulse-logo-shadow 1.6s infinite;
  }
  @include breakpoint(md) {
    &::after {
      height: 8vw;
      width: 8vw;
    }
    &::before {
      height: 16vw;
      width: 16vw;
    }
  }
  @include breakpoint(sm) {
    position: relative;
    min-height: 50vh;
    // background-color: $white;
    &::after {
      height: 15vw;
      width: 15vw;
    }
    &::before {
      height: 30vw;
      width: 30vw;
    }
  }
}

@keyframes pulse-logo {
  0% {
    transform: translate(-50%, -50%) scale(0.75);
  }

  70% {
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.75);
  }
}

@keyframes pulse-logo-shadow {
  // 0% {
  //   transform: translate(-50%, -50%) scale(0);
  //   background-color: transparent;
  // }
  0% {
    transform: translate(-50%, -50%) scale(0);
    background-color: transparentize(get($red), 0.4);
  }

  70% {
    transform: translate(-50%, -50%) scale(1);
    background-color: transparent;
  }

  100% {
    transform: translate(-50%, -50%) scale(0);
    background-color: transparent;
  }
}
