@mixin swiper {
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    position: relative;
    transition-property: transform;
  }
}
.carousel {
  position: relative;
}
.swiper-button-next {
  @include spritex('arrow-carousel');
  bottom: 50%;
  transform: translate(100%, calc(100% + #{$space-unit})) rotate(-90deg);
  @include breakpoint(lg) {
    transform: translate(-#{$space-unit}, calc(100% + #{$space-unit})) rotate(-90deg);
  }
}
.swiper-button-prev {
  @include spritex('arrow-carousel');
  top: 50%;
  transform: translate(100%, calc(-100% - #{$space-unit})) rotate(90deg);
  @include breakpoint(lg) {
    transform: translate(-#{$space-unit}, calc(-100% - #{$space-unit})) rotate(90deg);
  }
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  right: 0;
  z-index: 1;
  cursor: pointer;
  @include animate;
  &.swiper-button-disabled {
    opacity: 0.3;
    cursor: auto;
  }
  &.swiper-button-hidden {
    opacity: 0;
    visibility: hidden;
  }
  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
  @include breakpoint(md) {
    display: none;
  }
}
.swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.swiper-pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: $space-unit;
  @include breakpoint(md, min) {
    display: none;
  }
}
.swiper-pagination-bullet {
  height: $space-unit;
  width: $space-unit;
  border-radius: 50%;
  background-color: get($grey, border);
  &.swiper-pagination-bullet-active {
    background-color: get($red);
  }
}
