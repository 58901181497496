/* Hide all the elements that should be
 * visually hidden but accessible
 */
[visually-hidden],
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
}

[data-whatintent='mouse'],
[data-whatintent='touch'] {
  *:focus {
    outline: none;
  }
}

.hidden {
  display: none;
}

.feather-icons {
  display: block;
  width: 24px;
  height: 24px;
  stroke: get($red);
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.pointer {
  cursor: pointer;
}

#maincontent {
  position: relative;
  flex-grow: 1;
  @include breakpoint(md) {
    margin-top: $header-height-mobile;
  }
}

.loader {
  margin: auto;
  display: block;
}

.fullwidth {
  width: 100%;
}

.mt {
  margin-top: $space-unit;
  &-0 {
    margin-top: 0 !important;
  }
  &-2 {
    margin-top: $space-unit * 2;
  }
  &-3 {
    margin-top: $space-unit * 3;
  }
  &-4 {
    margin-top: $space-unit * 4;
  }
  &-5 {
    margin-top: $space-unit * 5;
  }
}

.mb {
  margin-bottom: $space-unit;
  &-0 {
    margin-bottom: 0 !important;
  }
  &-2 {
    margin-bottom: $space-unit * 2;
  }
  &-3 {
    margin-bottom: $space-unit * 3;
  }
  &-4 {
    margin-bottom: $space-unit * 4;
  }
  &-5 {
    margin-bottom: $space-unit * 5;
  }
}

.pt {
  padding-top: $space-unit;
  &-2 {
    padding-top: $space-unit * 2;
  }
  &-3 {
    padding-top: $space-unit * 3;
  }
  &-4 {
    padding-top: $space-unit * 4;
  }
  &-5 {
    padding-top: $space-unit * 5;
  }
}

.pb {
  padding-bottom: $space-unit;
  &-2 {
    padding-bottom: $space-unit * 2;
  }
  &-3 {
    padding-bottom: $space-unit * 3;
  }
  &-4 {
    padding-bottom: $space-unit * 4;
  }
  &-5 {
    padding-bottom: $space-unit * 5;
  }
}

.right {
  text-align: right;
}

@keyframes loading-dot {
  from {
    opacity: 0.1;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(-5px);
  }
}

.align-center,
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

//go to link style
.go-to {
  display: flex;
  text-decoration: none;
  color: get($grey, darkest);
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  .button {
    padding: 0;
    height: auto;
    margin-left: $space-unit * 2;
    color: get($grey, darkest);
    letter-spacing: initial;
  }
}

.embeddedServiceHelpButton {
  z-index: 99 !important;
  .helpButton {
    .uiButton {
      background-color: #b0b1b1;
      font-family: 'Arial', sans-serif;
      &:focus {
        outline: 1px solid #b0b1b1;
      }
      &.helpButtonDisabled {
        display: none;
      }
    }
  }
}

//for editmode
.grid-layout {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // TODO da refattorizzzare per browser support
  gap: $space-unit * 2;
  .swiper-slide {
    position: relative;
    height: auto;
    width: 100%;
    overflow: hidden;
  }
}

.triptitle {
  display: flex;
  justify-content: center;
  align-items: center;
  .ic-arrow {
    margin: 0 $space-unit * 3;
    // margin-top: 6px;
    flex-shrink: 0;
  }
}

// class used by js to generate mqObj
.custom-mq {
  $mqObj: '';
  @each $name, $value in $screen {
    @if $mqObj != '' {
      $mqObj: $mqObj + '&';
    }
    $mqObj: $mqObj + $name + '=' + $value;
  }
  font-family: $mqObj;
}

.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
@each $name, $br in $screen {
  @include breakpoint($name, min) {
    .d-#{$name}-none {
      display: none !important;
    }
    .d-#{$name}-block {
      display: block !important;
    }
    .d-#{$name}-flex {
      display: flex !important;
    }
  }
}

.is-grey {
  background-color: get($grey, background);
  padding: $space-unit * 10 0;
  @include breakpoint(sm) {
    padding: $space-unit * 5 0;
  }
}

.back-wrapper {
  display: flex;
  align-items: center;
  span {
    font-family: get($font, secondary);
    color: get($grey, darkest);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
  }
}

.text-content {
  img {
    max-width: 100%;
  }
}

.error-box {
  display: grid;
  grid-template-columns: 10% 90%;
  background: get($red, light);
  border-radius: 8px;
  color: get($red);
  padding: 16px;
  margin-bottom: 20px;
}

body.modal-open {
  // è necessario?
  // height: 100vh;
  overflow-y: hidden;
}
body .modal-backdrop {
  visibility: hidden;
  opacity: 0;
  &.show {
    position: fixed;
    visibility: visible;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s ease-in-out;
    @include breakpoint(sm) {
      content: none;
    }
  }
}
