.experience-component_accordion {
  margin: $space-unit * 10 0;
  @include breakpoint(sm) {
    margin: $space-unit * 5 0;
  }
}
.accordion {
  .accordion-wrapper {
    margin-top: $space-unit * 5;
    margin-bottom: $space-unit * 5;
  }

  .accordion-item {
    &:not(.open) {
      .accordion-item__content {
        height: 0 !important;
      }
    }
    &.open {
      .accordion-item__head {
        i {
          transform: rotate(0);
        }
      }
    }
    .accordion-item__head {
      height: $space-unit * 8;
      border-bottom: 1px solid get($grey, border);
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      i {
        @include animate();
        transform: rotate(-180deg);
      }
    }
    .accordion-item__content {
      position: relative;
      overflow: hidden;
      @include animate();
      .text-content {
        width: 100%;
        display: block;
        padding: $space-unit * 3 0;
      }
    }
  }
  @include breakpoint(sm) {
    h3 {
      font-size: 24px;
      line-height: 32px;
    }
    .accordion-wrapper {
      margin-bottom: 0;
      &.city-accordion-component {
        margin-bottom: 40px;
      }
    }
    .accordion-item {
      .accordion-item__head {
        height: $space-unit * 7;
        &:focus {
          outline: none;
        }
        .big-paragraph {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .accordion-item__content {
        .text-content {
          > * {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}


.accordion.city-accordion {
  .accordion-item {
    .accordion-item__head {
      i {
        transform: rotate(0);
      }
    }

    &.open {
      .accordion-item__head {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }

  @include breakpoint(sm) {
    .accordion-item {
      .accordion-item__head {
        .big-paragraph {
          font-size: 1rem;
          line-height: 1.5rem;
          letter-spacing: -2%;
          font-weight: 600;
        }
      }

      .accordion-item__content {
        .text-content {
          > * {
            font-size: 14px;
            line-height: 28px;
            font-weight: 400;
          }
        }

        .image-content {
          padding-top: 1.5rem;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.accordion.stations-accordion {
  .accordion-item {
    .accordion-item__head {
      justify-content: center;
      border-bottom-color: transparent;
      i {
        transform: rotate(0);
        margin-left: 1rem;
      }
      p {
        color: $unavailable;
      }

      .view-less {
        display: none;
      }
    }

    &.open {
      .accordion-item__head {
        i {
          transform: rotate(-180deg);
        }

        .view-more {
          display: none;
        }

        .view-less {
          display: block;
        }
      }
    }
  }
}