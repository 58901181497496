.experience-component_editorial_card,
.experience-component_editorial_card_wrapper {
  margin: $space-unit * 10 0;
  @include breakpoint(sm) {
    margin: $space-unit * 5 0;
  }
}
.editorial-card-wrapper {
  h2 {
    margin-bottom: $space-unit * 5;
  }
}
.single-editorial-card {
  .editorial-card {
    flex-direction: row;
    &.reverse {
      flex-direction: row-reverse;
    }
    .wrapper-text .text-content {
      flex-grow: initial;
    }
    .image-container img {
      width: 100%;
    }
  }
  @include breakpoint(sm) {
    text-align: center;
  }
}
//override del carosello
.editorial-card-carousel {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  @include swiper();
  .swiper-wrapper {
    width: auto;
    margin-left: -$space-unit;
    margin-right: -$space-unit;
    // justify-content: center;
    flex-wrap: wrap;
  }
  .swiper-slide {
    flex: 1 0 25%;
    padding-right: $space-unit;
    padding-left: $space-unit;
    max-width: 50%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  @include breakpoint(sm) {
    text-align: center;
  }
}
.editorial-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: initial;
  color: inherit;
  height: 100%;
  .wrapper-image {
    overflow: hidden;
    width: 100%;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 100%;
    }
    img {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: auto;
    }
    &.center {
      img {
        max-width: 100%;
        width: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &.full {
      img {
        width: auto;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .wrapper-text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: $space-unit * 5;
    .text-content {
      flex-grow: 1;
    }
  }
}
@include breakpoint(sm) {
  .editorial-card-wrapper {
    h2 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: $space-unit * 3;
    }
    .carousel-grid {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .editorial-card-carousel {
    padding-left: $space-unit * 3;
    padding-top: 50px;
    .swiper-button-prev,
    .swiper-button-next {
      @include sprite('chevron-right');
      background-size: 80%;
      background-position: 60% center;
      background-color: $white;
      width: 34px;
      height: 34px;
      border: 1px solid get($grey, border);
      border-radius: 50%;
      box-sizing: border-box;
      display: block;
      &:focus {
        outline: none;
      }
      &.swiper-button-disabled {
        opacity: 0.7;
        filter: grayscale(1);
      }
    }
    .swiper-button-prev {
      right: initial;
      top: 0;
      left: -$space-unit;
      transform: translateX(100%) rotate(180deg);
    }
    .swiper-button-next {
      right: initial;
      bottom: initial;
      top: 0;
      transform: initial;
      left: 68px;
    }
    .swiper-wrapper {
      flex-wrap: initial;
      justify-content: flex-start;
    }
    .swiper-slide {
      max-width: 100%;
      flex-basis: initial;
    }
  }
  .editorial-card {
    .wrapper-text {
      margin-top: 0;
      align-items: center;
      h4 {
        margin-top: $space-unit * 3;
        font-size: 24px;
        line-height: 32px;
      }
      .text-content {
        margin-bottom: $space-unit * 3;
      }
      .go-to {
        padding-bottom: $space-unit * 5;
      }
    }
  }
}
