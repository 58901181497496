.mbsc-calendar {
  .mbsc-cal {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .mbsc-cal-hdr {
    height: calc(35px + 12px);
    flex-shrink: 0;
    .mbsc-cal-btn {
      &.mbsc-cal-prev,
      &.mbsc-cal-next {
        @include sprite('chevron-right');
        margin: 0;
        width: 35px;
        height: 35px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-size: 70%;
        background-position-x: 60%;
        border: 1px solid get($grey, border);
        border-radius: 50%;
      }
    }

    .mbsc-cal-prev {
      transform: rotate(180deg);
    }

    .mbsc-cal-month {
      font-weight: bold;
      font-size: 16px;
      line-height: 35px;
      font-family: get($font, secondary);
    }
  }
  .mbsc-cal-body {
    flex-grow: 1;
    .mbsc-cal-month-picker {
      border-top: 1px solid get($grey, border);
      border-bottom: 1px solid $white;
    }
    .mbsc-cal-days > div {
      border-top: 1px solid get($grey, border);
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 12px;
      line-height: 16px;
      font-family: get($font, paragraph);
      border-bottom: 1px solid get($grey, border);
      color: get($grey, darkest);
    }
    .mbsc-cal-day-picker {
      .mbsc-cal-scroll-c {
        padding-top: $space-unit;
      }
    }
    .mbsc-cal-table {
      border-spacing: 0 3px;
      .mbsc-cal-day {
        position: relative;
        height: 50px;
        .mbsc-cal-day-i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
        }
        .mbsc-cal-day-date {
          border: none;
          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          margin: 0;
          color: get($grey, darkest);
          font-family: get($font, secondary);
          height: auto;
        }
        .mbsc-cal-txt {
          background-color: transparent;
          text-align: center;
          font-size: 12px;
          line-height: 16px;
          color: get($grey, darkest);
          padding: 0;
          margin: 0;
          height: auto;
          &.mbsc-hover:before {
            background-color: transparent;
          }
        }
        &:not(.mbsc-cal-day-diff) {
          &.mbsc-selected {
            .mbsc-cal-day-date {
              color: inherit;
            }
            &::after {
              content: none;
            }
          }
          &.mbsc-cal-sel-start,
          &.mbsc-cal-sel-end {
            background-color: get($red);
            border-radius: 15px;
            color: $white;
            .mbsc-cal-txt {
              color: $white;
            }
          }

          &.mbsc-cal-sel-start,
          &.mbsc-cal-sel-end > div {
            &:before {
              content: '';
              position: absolute;
              @include sprite('chevron-right-white');
              width: $space-unit * 2;
              height: $space-unit * 2;
              top: 50%;
              transform: translateY(-50%);
              z-index: 1;
            }
          }

          &.mbsc-cal-sel-start {
            &:before {
              right: 1px;
            }
          }

          &.mbsc-cal-sel-end > div {
            &:before {
              transform: translateY(-50%) rotate(180deg);
              left: 1px;
            }
          }
        }
        &:not(.mbsc-disabled) {
          &:not(.calendar-item-price) {
            cursor: not-allowed;
            opacity: 0.2;
          }
        }
      }
    }
  }

  @include breakpoint(md) {
    .mbsc-cal-body {
      .mbsc-cal-day-picker {
        .mbsc-cal-scroll-c {
          padding-top: $space-unit;
        }
      }
      .mbsc-cal-table {
        .mbsc-cal-day {
          &.mbsc-cal-day-diff {
            visibility: hidden;
          }
        }
      }
    }
  }
  .calendar-no-price {
    bottom: 8px;
  }
}
