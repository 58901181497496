.card {
  border-radius: 10px;
  border: 1px solid get($grey, border);
  background-color: $white;
  overflow: hidden;
  // modifiers
  &--offers {
    @extend .card;
    display: flex;
    height: auto;
    flex-direction: column;
    img {
      width: 100%;
    }
    .bottom-card {
      position: relative;
      padding: $space-unit * 2;
      p {
        color: get($grey, text);
      }
      h5 {
        color: $black;
        max-width: 180px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .price {
        position: absolute;
        right: $space-unit * 2;
        bottom: $space-unit * 2;
        color: get($grey, textlight);
        span {
          color: get($red);
          margin-left: 6px;
        }
      }
    }
    > .image-container {
      padding-top: 66%;
      position: relative;
      img {
        display: block;
        flex-grow: 1;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &--trip {
    @extend .card;
    cursor: pointer;
    margin-bottom: $space-unit * 3;
    &:last-of-type {
      margin-bottom: 0;
    }
    .card__body {
      padding: $space-unit * 2;
      display: flex;
      .hours-col {
        margin-right: $space-unit * 3;
        .time-departure {
          color: get($grey, darkest);
        }
        .time-arrival {
          color: get($grey);
        }
      }
      .names-col {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        padding-right: $space-unit * 3;
        p {
          line-height: $space-unit * 4;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .prices-col {
        align-self: flex-end;
        text-align: right;
        .starting-from {
          font-size: 18px;
        }
        .originalprice {
          text-decoration: line-through;
        }
      }
    }
    @include breakpoint(sm) {
      margin-bottom: 16px;
      .card__body {
        padding-top: 12px;
        padding-bottom: 10px;
        .hours-col {
          margin-right: $space-unit * 2;
          padding-top: 2px;
          .time-departure {
            padding-bottom: $space-unit;
          }
          .time-departure,
          .time-arrival {
            font-size: 18px;
            line-height: 16px;
            text-align: center;
          }
        }
        .names-col {
          padding-right: $space-unit;
          p {
            font-size: 14px;
            line-height: 22px;
          }
        }
        .prices-col {
          min-width: 20%;
          .originalprice {
            font-family: get($font, secondary);
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
          }
          .starting-from {
            font-size: 10px;
          }
          .price {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
      .card__footer {
        .duration {
          font-weight: 400;
        }
        .discount {
          height: 35px;
          &::before {
            background-size: 55%;
          }
          span {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }
  }
  &--features {
    @extend .card;
    border-color: get($grey, fog);
    display: flex;
    padding: $space-unit;
    .card__body {
      padding: 0 $space-unit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h6 {
        margin-bottom: 4px;
        color: get($grey, darkest);
      }
      p {
        color: get($grey, list);
      }
    }
  }
  &--menu {
    display: flex;
    padding: 0 $space-unit;
    a {
      display: flex;
      align-items: center;
      text-decoration-line: none;
      color: initial;
      width: 100%;
    }
    img {
      height: ($space-unit * 8);
    }
    p {
      font-family: get($font, paragraph);
      font-size: 14px;
      line-height: 16px;
      color: get($grey, darkest);
      font-weight: 500;
      white-space: pre;
      padding-left: $space-unit;
      padding-right: ($space-unit * 2);
    }
    @include breakpoint(md) {
      padding: 0;
      p {
        padding-left: $space-unit * 2;
        font-size: 16px;
      }
    }
  }
  &--features {
    img {
      height: 108px;
    }
    @include breakpoint(lg) {
      margin-bottom: $space-unit * 3;
    }
    @include breakpoint(md) {
      margin-bottom: 0;
    }
  }
  &--booking {
    @extend .card;
    margin-bottom: $space-unit * 3;
    padding: $space-unit * 3;
    overflow: visible;
    .accordion {
      margin-top: $space-unit * 3;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .wrapper-input {
      margin-bottom: 0;
      @include breakpoint(sm) {
        margin-bottom: $space-unit * 2;
      }
    }
    @include breakpoint(sm) {
      padding: $space-unit * 3 $space-unit * 2;
      .card--services:not([data-collapsed='true']) .card__head::after {
        content: initial;
      }
      .icon-email {
        p {
          margin-top: $space-unit * 3;
        }
      }
      .icon-phone {
        p {
          margin-top: $space-unit * 3;
        }
      }
    }
    .active {
      &::after {
        content: '';
        @include sprite('chevron-right');
        background-size: 120%;
        position: absolute;
        right: $space-unit * 3;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
        width: 24px;
        height: 24px;
        display: block;
        cursor: pointer;
        @include animate;
      }
    }
    .not-active {
      &::after {
        content: '';
        @include sprite('chevron-right');
        background-size: 120%;
        position: absolute;
        right: $space-unit * 3;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        display: block;
        cursor: pointer;
        @include animate;
      }
    }
  }
  &--services,
  &--payment {
    @extend .card;
    margin-bottom: $space-unit * 3;
    &:not([data-collapsed]) {
      border-color: get($red);
      box-shadow: 0 0 0 1px get($red);
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .card__head {
      position: relative;
      display: flex;
      align-items: center;
      i {
        display: block;
        height: 124px;
        width: 124px;
        margin: $space-unit * 4;
      }
      .wrapper-text {
        padding-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        p {
          max-width: 90%;
        }
        @include breakpoint(sm) {
          max-width: 100%;
          width: 100%;
          padding-right: 60px;
          padding-left: 20px;
        }
      }
    }
    .card__body {
      padding: 0 $space-unit * 3;
      .collapsible {
        border-top: 1px solid get($grey, border);
        padding: $space-unit * 3 0;
      }
    }
    .switch {
      p {
        font-weight: normal;
      }
    }
  }
  &--booking,
  &--payment {
    border-radius: 20px;
  }
  &--payment {
    margin-bottom: $space-unit * 2;
    .cardselector {
      .radio-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
    .card__head {
      i {
        height: 96px;
        width: 96px;
        margin: $space-unit * 2;
      }
    }
    .card__body {
      .collapsible {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .cards {
          display: flex;
          // i:first-of-type {
          //   margin-right: $space-unit * 3;
          // }
          i:not(:last-of-type) {
            margin-right: $space-unit * 3;
          }
        }
        .wrapper-card-form {
          .creditcardfield {
            > .mbsc-row:nth-of-type(1) {
              > .mbsc-col-12 {
                margin-bottom: $space-unit;

                svg {
                  position: absolute;
                  display: block;
                  top: 50%;
                  transform: translateY(-50%);
                  left: $space-unit * 2;
                  width: 40px;
                  height: 24px;
                }
                #cardNumber {
                  padding-left: $space-unit * 8;
                }
              }
            }
          }
          .savecard {
            padding-top: 10px;
            display: flex;
            label {
              margin-right: 10px;
            }
          }
        }
      }
    }
    @include breakpoint(md, min) {
      .card__body {
        .collapsible {
          .wrapper-card-info {
            order: 1;
            .cards {
              margin-top: $space-unit * 2;
              i {
                background-size: 100%;
                height: 40px;
                &.icon-mastercard {
                  width: 48px;
                }
                &.icon-visa {
                  width: 60px;
                }
                &.icon-amex {
                  width: 60px;
                }
              }
            }
          }
        }
      }
    }
    @include breakpoint(md) {
      .card__body {
        .collapsible {
          .wrapper-card-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: $space-unit * 3;
            p {
              max-width: 50%;
            }
          }
        }
      }
    }
    @include breakpoint(md) {
      .card__body {
        .collapsible {
          .wrapper-card-info {
            flex-wrap: wrap;
            p {
              max-width: initial;
            }
            .cards {
              padding-top: $space-unit * 2;
            }
          }
        }
      }
    }
  }
  &--services {
    .card__head::after {
      content: '';
      @include sprite('chevron-right');
      background-size: 120%;
      position: absolute;
      right: $space-unit * 3;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      width: 24px;
      height: 24px;
      display: block;
      @include animate;
    }

    @include breakpoint(sm) {
      margin-bottom: $space-unit * 2;
      .card__head {
        i {
          height: 80px;
          width: 80px;
          margin: 8px;
        }
        .wrapper-text {
          h6 {
            margin-bottom: 4px;
            font-size: 14px;
          }
          * {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .card__body {
        width: 100vw;
        .collapsible {
          border: none;
          overflow-x: hidden;
          max-height: calc(100vh - #{$cart-bar} - #{$header-height-mobile});
        }
      }
      &:not([data-collapsed]) {
        position: fixed;
        z-index: 2;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        border: none;
        border-radius: initial;
        .card__head {
          padding: 0 $space-unit * 3;
          height: $header-height-mobile;
          box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
          .wrapper-text {
            h6 {
              margin-bottom: 0;
              font-size: 16px;
              line-height: 24px;
            }
          }
          &::after {
            @include spritex('x');
          }
          i,
          p,
          small {
            display: none;
          }

          @include breakpoint(sm) {
            &.hideclose {
              &::after {
                display: none !important;
              }
            }
          }
        }
      }
    }
  }

  &--ticket-anchor {
    text-decoration-line: initial;
    color: inherit;
  }

  &--ticket {
    @extend .card;
    border-radius: 20px;
    padding: $space-unit * 3;
    padding-top: 20px;
    margin-top: 16px;
    .departures-arrivals-names,
    .departures-arrivals-times {
      position: relative;
      display: flex;
      justify-content: space-between;
    }
    .departures-arrivals-names {
      .departure-name,
      .arrival-name {
        flex: 1 1 calc(50% - 44px);
        max-width: calc(50% - 44px);
        h5 {
          margin-bottom: $space-unit * 4;
        }
        > * {
          margin-bottom: $space-unit;
        }
        h5,
        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .arrival-name {
        text-align: right;
      }
      .ticket-img {
        flex: 0 0 88px;
        height: 88px;
        width: 88px;
        margin-top: -14px;
      }
    }
    .departures-arrivals-times {
      align-items: center;
      margin-bottom: 24px;
      h5,
      p {
        background-color: $white;
        position: relative;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        padding: 0 14px;
        text-transform: capitalize;
      }
      .departure-time {
        padding-right: $space-unit * 3;
      }
      .date {
        padding: 0 $space-unit * 3;
        text-transform: capitalize;
        font-size: 12px;
        line-height: 16px;
        font-family: get($font, 'secondary');
      }
      .arrival-time {
        padding-left: $space-unit * 3;
      }
      &::before {
        content: '';
        height: 1px;
        width: 100%;
        background-color: get($grey, border);
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
    @include breakpoint(md) {
      padding: $space-unit * 3 $space-unit * 2;
      .departures-arrivals-names {
        .departure-name,
        h5 {
          font-size: 16px;
          line-height: 24px;
        }
        p {
          font-size: 12px;
          line-height: 16px;
        }
      }
      .departures-arrivals-times {
        h5 {
          font-size: 16px;
          line-height: 24px;
        }
        .departure-time {
          padding-right: $space-unit;
        }
        .arrival-time {
          padding-left: $space-unit;
        }
        .caption {
          padding: 0 12px;
        }
      }
    }
    .delete-ticket {
      form {
        button {
          width: 100%;
        }
      }
    }
  }
  &--buttons {
    @extend .card;
    border-radius: 20px;
    padding: $space-unit * 3;
    padding-top: 20px;
    margin-top: 16px;

    @include breakpoint(sm) {
      h6 {
        font-size: 14px;
      }
      h1 {
        font-size: 24px;
      }
    }

    button {
      max-width: 400px;
      margin: 0 auto;
    }

    .icon-access-new,
    .icon-luggages-new {
      display: block;
      width: 48px;
      height: 48px;
      margin: 0 auto;
    }

    > div {
      display: flex;
      justify-content: center;

      div {
        margin: 34px;
        text-align: center;

        @include breakpoint(sm) {
          margin: 15px;
        }
      }
    }
  }
  &--profile {
    @extend .card;
    display: flex;
    border-radius: 20px;
    .profile-picture {
      height: 104px;
      width: 104px;
      flex-shrink: 0;
      margin: 2px;
    }
    .card__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      padding-right: $space-unit * 2;
      > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      h6 {
        margin-bottom: 4px;
      }
      .button--primary {
        height: 40px;
        width: 50%;
        overflow: unset;
        text-overflow: unset;
        padding: 8px 0;
        margin-top: 4px;
        box-shadow: unset;
      }
    }
  }
  &--features2 {
    @extend .card;
    height: calc(100% - #{$space-unit * 2});
    margin-bottom: $space-unit * 2;
    .card__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      > a {
        text-decoration: none;
        > img {
          display: block;
          margin: auto;
          margin-top: -$space-unit * 2;
        }
        h5 {
          color: $black;
        }
      }
      > img {
        display: block;
        margin-top: -$space-unit * 2;
        margin-left: -$space-unit * 2;
      }
      p {
        color: rgba($black, 0.65);
      }
    }
    @include breakpoint(sm) {
      height: calc(100% - #{$space-unit});
      margin-bottom: $space-unit;
      .card__body {
        align-items: center;
        > a {
          > img {
            width: 80px;
            height: 80px;
            margin: auto;
            margin-top: -10px;
            margin-bottom: 8px;
          }
        }
        > img {
          width: 80px;
          height: 80px;
          margin: auto;
          margin-top: -10px;
          margin-bottom: 8px;
        }
        h5 {
          font-size: 16px;
          line-height: 24px;
        }
        p {
          font-size: 12px;
          line-height: 16px;
          text-align: center;
        }
      }
    }
  }
  &--bus-finder {
    border-radius: 20px;
    background-color: $white;
    overflow: hidden;
    box-shadow: 0px 6px 10px 2px rgba(0, 0, 0, 0.08);
    padding: $space-unit * 2;
    color: get($grey, darkest);
    > h5 {
      padding-top: $space-unit * 0.5;
    }
    form {
      position: relative;
      border-top: 1px solid get($grey, border);
      padding-top: 10px;
      margin-top: 10px;
      input {
        width: 65%;
      }
      button {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 40%;
        min-width: 230px;
      }
    }
    @include breakpoint(sm) {
      form {
        input {
          width: 100%;
        }
        button {
          position: relative;
          margin-top: $space-unit * 2;
          min-width: initial;
          width: 100%;
        }
      }
    }
  }
  &--stations-finder {
    position: relative;

    input {
      width: 65%;
    }
    button {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40%;
      min-width: 230px;
    }

    @include breakpoint(md) {
      input {
        width: 100%;
      }
      button {
        position: relative;
        margin-top: $space-unit * 2;
        min-width: initial;
        width: 100%;
      }
    }
  }
  // card blocks
  &__body {
    padding: $space-unit * 3;
  }
  &__footer {
    position: relative;
    height: 28px;
    background-color: get($grey, bgcard);
    display: flex;
    align-items: center;
    padding: 0 16px;

    .duration {
      font-weight: 500;
    }
    .transfer-handle {
      color: get($red);
      padding: 6px;
      &:not(.transfer_0) {
        cursor: pointer;
      }
    }
    .discount {
      position: absolute;
      bottom: 0;
      right: -10px;
      height: 38px;

      padding-left: $space-unit * 2;
      padding-right: $space-unit * 3;
      width: auto;
      min-width: 100px;

      background-color: $discount;
      color: $white;
      display: flex;
      align-items: center;
      font-weight: 600;
      text-align: right;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      transform: skewX(20deg);

      i {
        display: block;
        width: 24px;
        height: 24px;
        margin-right: $space-unit * 0.5;
        transform: skewX(-20deg); // Balance the skew of the discount.
      }

      span {
        width: 100%;
        font-size: 22px;
        line-height: 22px;
        font-family: get($font, secondary);
        transform: skewX(-20deg);
      }

      &--unavailable {
        background-color: get($red, default);

        span {
          font-size: 18px;
          line-height: 22px;
        }
      }

      &.promo-EXTRA {
        background-color: #14abff;
      }

      &.promo-Low span {
        font-size: 18px;
      }

      &.promo-WOW {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          @include sprite('promo-wow');
          background-size: 60%;
          transform: skewX(-20deg);
        }
        span {
          display: none;
        }
      }
    }
    .price-delta {
      position: absolute;
      right: 10px;
      bottom: 2px;
      font-size: 16px;
      @include breakpoint(md) {
        font-size: 11px;
      }
      line-height: 22px;
      font-weight: 500;
    }
    .logo-member {
      background: var(--searchbox-url) no-repeat 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 38px;
      position: absolute;
      right: 0;
      bottom: -1px;
      border-radius: 0 0 10px 0;
      padding: 0 16px 0 22px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 12% 100%);
      span {
        @include spritex('logo-memberhip');
        width: 100%;
        height: 100%;
        background-size: 95%;
      }
    }
    .logo-member-promo {
      background: var(--searchbox-url) no-repeat 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 38px;
      position: absolute;
      right: 0;
      bottom: -1px;
      border-radius: 0 0 10px 0;
      padding: 0 16px 0 22px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 12% 100%);
      span {
        @include spritex('logo-membership-promo');
        width: 100%;
        height: 100%;
        background-size: 95%;
      }
    }
  }

  &__partner {
    display: flex;
    padding: $space-unit;
    align-items: stretch;
    box-sizing: border-box;

    &__operated {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      img {
        display: block;
        width: 80px;
        height: 20px;
      }

      p {
        margin-right: $space-unit;
      }
    }

    &__multiple {
      width: 100%;
      padding: 8px;
    }

    &__multiple_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 100%;

      @include breakpoint(md) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
      }
    }

    &__multiple__row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__multiple__extra {
      // Compensate the natural padding of the card
      .card__extra {
        padding: 0;
      }

      @include breakpoint(md) {
        .card__extra {
          padding-top: $space-unit;
        }
      }
    }

    &__multiple__operated {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        display: block;
        width: 80px;
        height: 20px;
      }

      p {
        margin-right: $space-unit;
      }

      @include breakpoint(md) {
        position: inherit;
        width: 100%;
      }
    }

    &__multiple__time {
      width: 48px;
      margin-right: $space-unit * 2;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;

      &.departure {
        color: black;
      }

      &.arrival {
        color: get($grey, default);
      }
    }

    &__multiple__station {
      flex: 1;
      font-size: 16px;
      line-height: 28px;
      font-weight: 500;

      &.origin {
        color: black;
      }

      &.destination {
        color: get($grey, default);
      }
    }
  }

  &__extra {
    display: flex;
    padding: $space-unit;
    align-items: stretch;
    box-sizing: border-box;

    > div + div {
      margin-left: $space-unit;
    }

    i {
      display: block;
      width: 24px;
      height: 24px;
    }

    &__title {
      display: flex;
      justify-content: space-between;
    }

    .comfort {
      flex: 37.5%;
      padding: $space-unit * 0.5;
      border-radius: 10px;
      border: 3px solid #dfdfdf;
      @include breakpoint(md) {
        flex: 42.5%;
      }

      .icon-seat {
        @include sprite('seat-gray-lg');
        width: 48px;
        height: 48px;
      }

      &--active {
        border: 3px solid #2bb8a4;

        .tariff-name {
          color: get($grey, darkest);
        }

        .tariff-price {
          color: #2bb8a4;
        }

        .icon-seat {
          @include sprite('seat-color-lg');
        }
      }

      &--not-available {
        opacity: 0.5;
      }

      &__not-available {
        color: get($grey, darkest);
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-align: right;
      }

      &__benefit-item {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 14px;
          line-height: 16px;
        }
      }

      @include breakpoint(md) {
        &__benefit-item {
          flex-direction: row;
          justify-content: flex-start;
          align-items: initial;
          p {
            font-size: 12px;
          }
          .icon-seat {
            width: 24px;
            height: 24px;
            @include sprite('seat-gray-sm');
          }
        }
        &--active {
          .icon-seat {
            @include sprite('seat-color-sm');
          }
        }
      }
    }
    .extra-comfort {
      min-width: 160px;
      flex: 62.5%;
      padding: $space-unit * 0.5;
      border-radius: 10px;
      border: 3px solid #dfdfdf;
      @include breakpoint(md) {
        flex: 57.5%;
      }
      .icon-seat-extensible {
        @include sprite('seat-extensible-lg');
        width: 64px;
        height: 64px;
      }
      .icon-access {
        @include sprite('access-lg');
        width: 64px;
        height: 64px;
      }
      .icon-space {
        @include sprite('space-lg');
        width: 64px;
        height: 64px;
      }

      &--active {
        border: 3px solid #14abff;

        .icon-seat-extensible {
          @include sprite('seat-extensible-lg');
        }
        .icon-access {
          @include sprite('access-lg');
        }
        .icon-space {
          @include sprite('space-lg');
        }

        .tariff-name {
          color: get($grey, darkest);
        }

        .tariff-price {
          color: #14abff;
        }
      }

      &--not-available {
        opacity: 0.5;
      }

      &__benefits {
        display: flex;
        justify-content: space-between;
        margin: 0 8px;
      }

      &__benefit-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        p {
          font-size: 14px;
          line-height: 22px;
        }
      }

      &__not-available {
        color: get($grey, darkest);
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        text-align: right;
      }

      @include breakpoint(md) {
        &__benefits {
          flex-direction: column;
        }
        &__benefit-item {
          flex-direction: row;
          justify-content: flex-start;
          p {
            font-size: 12px;
          }
          .icon-seat-extensible {
            @include sprite('seat-extensible-sm');
            width: 48px;
            height: 48px;
          }
          .icon-access {
            @include sprite('access-sm');
            width: 48px;
            height: 48px;
          }
          .icon-space {
            @include sprite('space-sm');
            width: 48px;
            height: 48px;
          }
        }

        &--active {
          .icon-seat-extensible {
            @include sprite('seat-extensible-sm');
          }
          .icon-access {
            @include sprite('access-sm');
          }
          .icon-space {
            @include sprite('space-sm');
          }
        }
      }
    }

    .tariff-name {
      font-family: 'Montserrat';
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: get($grey, default);
    }

    .tariff-price {
      font-family: 'Roboto';
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: get($grey, darkest);
    }
  }
  &__steps {
    overflow: hidden;

    @include animate;
    .wrapper-steps {
      padding: $space-unit * 3 0;
      margin: 0 $space-unit * 2;
      border-top: 1px solid get($grey, border);
      .trasfer-item {
        display: flex;
        align-items: center;
        .caption {
          width: 34px;
        }
        p {
          &:nth-of-type(1) {
            margin-right: 20px;
          }
          &:nth-of-type(2) {
            margin-left: 20px;
          }
        }
        &.transfer {
          justify-content: space-between;
          flex-wrap: wrap;
          p {
            &:nth-of-type(1) {
              margin-right: 10px;
            }
            &:nth-of-type(2) {
              margin-left: 10px;
            }
          }
          .details {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            .change-time {
              margin-right: $space-unit;
            }
          }
          .time-diff {
            display: flex;
            align-items: center;
            margin-right: 0;
            .clock-icon {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  &__highlight {
    margin-bottom: $space-unit * 3;
    @include breakpoint(sm) {
      padding: 0;
    }
    > div {
      margin-bottom: $space-unit;
      small {
        color: get($red);
        font-family: get($font, secondary);
      }
    }
    @include breakpoint(sm) {
      > p {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .mbsc-row:nth-of-type(1) {
      flex-wrap: nowrap;
    }
    .box-item {
      display: flex;
      width: 100%;
      background: rgba(0, 188, 161, 0.05);
      border: 1px solid #00bca1;
      border-radius: 12px;
      margin: 0 16px;

      @include breakpoint(sm) {
        margin: 0;
      }

      i.icon-ancillary-luggage-included,
      i.icon-ancillary-bag-included {
        display: block;
        width: 34px;
        height: 58px;
        margin: $space-unit * 2;

        @include breakpoint(sm) {
          margin: $space-unit;
        }
      }
      i.icon-services-success {
        position: relative;
        width: 20px;
        height: 20px;
        top: 8px;
        right: 8px;
      }
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;

        @include breakpoint(sm) {
          max-width: 60%;
        }
      }
      h6 {
        font-size: 14px;
        color: #00bca1;
        font-weight: 600;
      }
      br {
        display: none;

        @include breakpoint(sm) {
          display: block;
        }
      }
      p {
        font-size: 14px;
        color: #00bca1;
        font-weight: 600;
      }
      em {
        font-style: italic;
      }
    }
    .popup-text {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin: 26px 0;
    }
    &-actions {
      display: flex;
      justify-content: space-around;
      align-items: center;

      @include breakpoint(sm) {
        display: block;
      }

      .button--alternate {
        padding: 8px 6px;
        width: 100%;

        @include breakpoint(sm) {
          margin-top: 16px;
        }
      }

      button:nth-of-type(1) {
        margin-right: 16px;
      }
    }
  }
  &[data-collapsible] {
    .card__head {
      cursor: pointer;
    }
    &[data-collapsed] {
      .card__head {
        &::after {
          transform: translateY(-50%);
        }
      }
    }
    .card__body {
      transition: height 0.3s ease-out;
    }
  }
}
