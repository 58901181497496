.experience-component_editorial_video {
  margin: $space-unit * 15 0;
  @include breakpoint(sm) {
    margin: 0;
  }
}
.editorial-video {
  position: relative;
  overflow: hidden;
}
.wrapper-video {
  --plyr-color-main: #{get($red)};
  .plyr--video {
    &.plyr--playing {
      .plyr__control--overlaid {
        visibility: visible;
        transform: translate(-50%, -50%) scale(0);
      }
    }
    .plyr__control {
      &--overlaid,
      &--overlaid:hover {
        @include spritex('play');
      }
      &--overlaid {
        transition: all 0.3s ease-in-out;
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
        svg {
          display: none;
        }
      }
    }
  }
  .plyr__poster {
    background-size: cover;
  }
  @include breakpoint(sm) {
    &.mbsc-grid {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
