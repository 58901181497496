.other-routes {
  .bs-title {
    padding-left: 18px;
  }
  &__travels-wrapper {
    border-radius: 20px;
    position: relative;
    top: -16px;
    background-color: #fff;
    padding: 26px 24px 22px;
    border: 1px solid get($grey, 'border');

    &--is-closed {
      .other-routes__item {
        &:nth-child(n+25) {
          display: none;
        }
        @include breakpoint(md) {
          &:nth-child(n+7) {
            display: none;
          }
        }
      }
      .other-routes__show-more i {
        transform: rotate(180deg);
      }
    }
  }
  &__item {
    margin: 10px 0;

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 24px;
      background: get($grey, 'background');
      border-radius: 32px;
      color: #000;
      text-decoration: none;
    }
  }
  &__show-more {
    padding: 24px 16px 0;
    display: none;
    justify-content: center;
    align-items: center;    

    &.is-visible {
      display: flex;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div {
      cursor: pointer;

      i {
        transition: all 0.3s ease-out;
      }
    }

    span {
      display: inline-block;
      padding: 0 16px;
      color: #E1261D;
    }
    .show-more-label,.show-less-label {
      &.hide{
        display: none;
      }
    } 
  }
}