.tabs-component {
  .tab-item-content {
    display: none;
    &.open {
      display: flex;
    }
    align-items: flex-start;
  }

  .tab-item-image, .tab-item-caption {
    flex: 1;
    @include breakpoint(md, min) {
      width: 50%;
    }
  }

  .tab-item-image {
    img {
      max-width: 100%;
    }
  }

  .tab-btn {
    padding: 0;
    margin: 0;
    background: unset;
    border: 0;
    color: $black;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 700;
    text-transform: none;
    position: relative;
    &.selected {
      color: $unavailable;
    }
  }

  .tab-btn.selected::after {
    content: " ";
    position: absolute;
    width: 100%;
    bottom: -4px;
    left: 0;
    border-bottom: 3px solid $unavailable;
  }

  @include breakpoint(md, min){
    padding-bottom: 3rem;
    .tabs-header {
      ul {
        display: flex;
        align-items: center;
        padding-bottom: 2.5rem;
      }
      li {
        margin-right: 2.5rem;
        padding: 0;
      }
    }

    .tab-item-image {
      align-self: flex-start;
    }

    .tab-item-caption {
      padding: 0 0 0 7.75rem;
      max-width: 50%;

      p {
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 400;
      }
    }
  }

  @include breakpoint(md, max) {
    .tab-title {
      font-size: 1.5rem;
    }
  }

  @include breakpoint(sm, max) {
    .tab-item-content {
      flex-direction: column;
    }
  }
}