.myaccount {
  .alert--success {
    margin-bottom: 20px;
  }

  #err-password,
  #err-conf-password {
    display: none;

    &.shown {
      display: block;
    }
  }

  .newsletter-toggle {
    display: block;
    padding-right: 30%;
    letter-spacing: initial;
    margin-top: $space-unit * 2;
  }

  .accountmenu {
    margin: $space-unit * 3 0;
    display: flex;
    flex-direction: column;

    &__item {
      padding: 0 $space-unit * 2;
      height: $space-unit * 7;
      text-decoration: none;
      display: flex;
      align-items: center;
      border-radius: 45px;
      border: 1px solid transparent;

      &.selected {
        border-color: get($red);
      }

      i {
        margin-right: $space-unit * 2;
      }

      .caption {
        color: get($grey, darkest);
      }
    }
  }

  .accountmenu-mobile {
    display: none;
  }

  .form-field {
    margin-bottom: $space-unit * 2;

    .caption {
      margin-bottom: $space-unit;
      text-align: left;
    }

    .gender-container {
      display: flex;
      .radio-wrapper {
        margin-bottom: 0;
        width: auto;
      }
    }

    .radio-wrapper {
      margin-right: 16px;
    }

    .workingdays {
      display: flex;
      align-items: stretch; /* Default */
      //justify-content: space-between;
      width: 100%;
      li {
        margin-bottom: $space-unit;
        flex: 0 1 auto; /* Default */
        list-style-type: none;
        margin-right: 0;
        width: 15%;
        label {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
    }
  }

  .wrapper-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: $space-unit * 2;

    .microcopy {
      margin-left: $space-unit;
    }
  }

  .wrapper-account {
    flex-direction: column;
    justify-content: center;

    .tickets-list {
      margin-top: 30px;

      .cartitem {
        position: relative;
        overflow: hidden;
        border: 1px solid get($grey, border);
        border-radius: 20px;
        padding: $space-unit * 3;
        width: 100%;
        background-color: $white;

        .travel-done {
          background-color: get($red);
          position: absolute;
          right: 0;
          top: 0;
          padding: 3px 14px 3px 10px;
          color: $white;
          border-bottom-left-radius: 10px;
        }

        .directions {
          display: grid;
          grid-template-columns: 35% 30% 35%;
          text-align: center;
          font-size: 12px;
          line-height: 16px;

          h6 {
            margin-bottom: 8px;
          }

          .left {
            text-align: left;
          }

          .right {
            text-align: right;
          }

          .ico-tickets {
            @include spritex('ticket');
            margin: 0 auto;
            margin-top: -10px;
          }

          &.stations {
            font-family: get($font, secondary);
            font-weight: 600;
          }

          &.times {
            @include spritex('line-horizontal');
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-repeat: repeat-x;
            background-position: center;
            width: 100%;
            align-items: center;
            margin: $space-unit * 2 0;
            background-size: 100%;

            > * {
              display: inline-block;
              background-color: #fff;

              &:first-child {
                padding-right: 14px;
              }

              &:last-child {
                padding-left: 14px;
              }
            }

            .day-name {
              padding: 0 14px;
              font-family: get($font, secondary);
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              text-align: center;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .no-results {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -($space-unit * 6);
      img {
        width: 40vw;
        max-width: 100%;
        margin-right: -2%;
      }
      h3 {
        margin-top: ($space-unit * 2);
      }
    }
  }

  .creditcardlist {
    display: flex;
    flex-direction: column;
    margin-top: $space-unit * 3;
    .creditcard {
      display: flex;
      text-align: left;
      align-items: center;
      justify-content: space-between;
      border: 1px solid get($grey, border);
      border-radius: 20px;
      width: 100%;
      padding: 30px;
      @include sprite('creditcard');
      background-position: left;
      background-size: 120px;
      padding-left: 110px;
      margin-bottom: $space-unit * 3;
      &__info {
        .caption {
          margin-bottom: 4px;
        }
      }
      &__actions {
        .button {
          padding: 0;
          height: auto;
        }
      }
      @include breakpoint(sm) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: $space-unit * 2;
        .creditcard__actions {
          .button {
            padding-top: $space-unit;
          }
        }
      }
    }
  }

  .emptypayments {
    text-align: center;
    padding: $space-unit * 4 0;

    img {
      width: 180px;
      height: 180px;
    }

    h3 {
      margin: $space-unit * 5 auto;
      width: 80%;
    }

    .button--primary {
      display: inline-flex;
    }
  }

  .single-booking {
    background: get($grey, background);
    border-radius: 30px;
    padding: $space-unit * 2;
    padding-bottom: $space-unit * 3;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.single-booking__nextbooking {
      padding: $space-unit * 2 0 0;
      border: 1px solid #dfdfdf;

      &.pd-bottom {
        padding: $space-unit * 2 0;
      }

      .cart-body {
        margin: $space-unit ($space-unit * 2);
        &-departed {
          padding-top: 28px;
          position: relative;
          overflow: hidden;
          .travel-done {
            background-color: get($red);
            position: absolute;
            right: 0;
            top: 0;
            padding: 3px 14px 3px 10px;
            color: $white;
            border-bottom-left-radius: 10px;
          }
        }
      }
      .card--postsale-services {
        background-color: #fff;
        border: 0;
        padding: $space-unit * 3;
        margin-bottom: $space-unit * 2;

        &.last-item {
          border-radius: 0 0 30px 30px;
          margin-bottom: 0;
        }

        &-top {
          background-color: #f5f5f5;
          border-radius: 0 0 20px 20px;
          border: 0;
          width: 100%;
          left: 0;
          z-index: 0;
          top: 0;
          height: 16px;
        }
      }
    }
    button:not(.card--postsale-services-button) {
      width: 50%;
      margin: 24px auto;
    }
    &__action {
      padding: 0 $space-unit * 2;
      button.button-ticket {
        width: auto;
        margin-top: 0;

        @include breakpoint(sm) {
          padding: $space-unit;
        }
      }
    }
    .departures-arrivals-cities {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $space-unit * 3;
      padding: 0 16px;
      .departure-city,
      .arrival-city {
        font-size: 24px;
        line-height: 32px;
        width: 50%;
        text-align: right;
      }
      .arrival-city {
        text-align: left;
      }
      i {
        margin: 0 $space-unit * 2;
      }
      .ic-arrow-switch {
        background-size: 100%;
        width: 22px;
        height: 28px;
      }
      @include breakpoint(md) {
        flex-direction: column;
        margin-top: 12px;
        .departure-city,
        .arrival-city {
          width: unset;
        }
        .ic-arrow-switch {
          transform: rotate(90deg);
          width: 11px;
          height: 14px;
          margin: 4px 0;
        }
      }
    }
  }

  .travels-login {
    font-size: 14px;
    line-height: 22px;
    margin-top: 24px;
  }

  @include breakpoint(md, min) {
    padding-top: $header-height-desktop + $space-unit * 5;
    padding-bottom: $space-unit * 15;
  }

  @include breakpoint(md) {
    padding-top: $space-unit * 5;
    padding-bottom: $space-unit * 5;
    text-align: center;

    .newsletter-toggle {
      padding-right: 0;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }

    .emptypayments {
      img {
        width: 140px;
        height: 140px;
      }

      h3 {
        width: 100%;
        font-size: 24px;
        line-height: 32px;
        margin: $space-unit * 3 auto;
      }

      .button--primary {
        width: 100%;
      }
    }
  }

  @include breakpoint(sm) {
    .single-booking {
      margin-left: - $space-unit * 3;
      margin-right: - $space-unit * 3;
      border-radius: unset;
      padding: $space-unit * 2;
      .triptitle {
        margin-top: 4px;
        h5 {
          font-size: 16px;
          line-height: 24px;
        }
        .ic-arrow {
          width: $space-unit * 4;
          margin: 0 $space-unit;
        }
      }
      button:not(.card--postsale-services-button) {
        width: 100%;
        margin-top: $space-unit * 3;
        &.handle-booking {
          width: 90%
        }
      }
    }
    .wrapper-account {
      padding-top: $space-unit * 5;
      .no-results {
        img {
          width: 100vw;
          max-width: initial;
        }
        h3 {
          font-size: 32px;
          line-height: 40px;
        }
      }
      .tickets-list {
        .cartitem {
          padding: $space-unit * 2;
          .directions {
            &.times {
              h5 {
                font-size: 16px;
                line-height: 24px;
              }
            }
            small {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
    .accountmenu {
      display: none;
    }

    .accountmenu-mobile {
      position: relative;
      display: block;

      &::before {
        @include labelIcon('home');
      }

      &::after {
        @include labelIcon('chevron-down', right);
      }
    }

    #accountmenu-select {
      display: block;
      margin-top: $space-unit * 2;
      border: 1px solid get($red);
      border-radius: 10px;
      color: get($grey, dark);
      box-sizing: border-box;
      width: 100%;
      height: $space-unit * 6;
      padding: 0 $space-unit * 6;
      font-size: 16px;
      line-height: 24px;
      font-family: get($font, paragraph);
      background-color: $white;
      -webkit-appearance: initial;
    }
  }
}

.accountmenu {
  .active {
    border-color: get($red);
  }
}
