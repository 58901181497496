.experience-component_offers {
  margin-top: $space-unit * 10;
  margin-bottom: $space-unit * 10;
  // margin-bottom: -$space-unit * 2;
  @include breakpoint(sm) {
    margin-top: $space-unit * 10;
    margin-bottom: $space-unit * 10;
  }
}
.our-offers {
  position: relative;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrapper-offers {
    padding-top: $space-unit * 7;
    .swiper-slide {
      margin-bottom: 2 * $space-unit;
    }
    a {
      text-decoration-line: none;
    }
  }
  .card {
    margin-bottom: $space-unit * 2;
  }
  @include breakpoint(md, min) {
    .carousel {
      width: 100%;
      .swiper-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  @include breakpoint(md) {
    @include swiper;
    .carousel {
      position: relative;
      width: 100%;
      padding: 0 $space-unit;
      .swiper-slide {
        padding: 0;
        flex-basis: initial;
        margin-bottom: 0;
      }
    }
    .wrapper-offers {
      padding-top: $space-unit * 5;
    }
    .title {
      h3 {
        text-align: center;
        font-size: 32px;
        line-height: 40px;
      }
    }
    .card {
      margin-bottom: 0;
    }
  }
}
