.textcentered {
  // usato nella 404
  text-align: center;
  margin-bottom: 50px;
  img {
    width: 70%;
    margin: 100px 0;
  }
  .button {
    width: auto;
  }

  .page-title {
    color: get($red, 'dark');
    @include breakpoint('md') {
      margin-top: 50px;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .code {
    background-color: #fee;
    margin: 50px 0;
    border-radius: 4px;
    text-align: left;
    padding: 20px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
    overflow: auto;
    b {
      font-weight: bold;
    }
  }

  .not-found {
    margin-top:110px;
  }
}
