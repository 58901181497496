.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  @include animate();
  &__container {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 20px;
    max-height: 99vh;
    transform: scale(0.8);
    @include animate();
    @include breakpoint(sm) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      border-radius: initial;
      max-height: 100vh;
      .modal__head {
        padding: 0 $space-unit * 3;
        height: $header-height-mobile;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
        .wrapper-text {
          * {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 24px;
          }
        }
        i,
        p,
        small {
          display: none;
        }
      }
      .modal__body {
        padding-top: $space-unit * 3;
        .modal__line {
          display: none;
        }
        .wrapper-voucher-description {
          margin-bottom: $space-unit * 2;
          i {
            display: block;
            width: 130px;
            height: 130px;
            flex-shrink: 0;
          }
          .voucher-description {
            padding-right: 0;
          }
        }
        max-height: calc(100vh - #{$header-height-mobile});
        overflow-x: hidden;
      }
    }
  }
  &::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    @include breakpoint(sm) {
      content: none;
    }
  }
  &__head {
    position: relative;
    display: flex;
    align-items: center;
    span.close {
      position: absolute;
      top: 50%;
      right: $space-unit * 3;
      cursor: pointer;
      transform: translateY(-50%);
    }
    i {
      display: block;
      height: 112px;
      width: 112px;
      margin: $space-unit;
    }
    .wrapper-text {
      padding: $space-unit * 2;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      * {
        font-family: get($font, secondary);
      }
    }
  }
  &__body {
    padding: $space-unit * 3;
    padding-top: 0;
    overflow-y: auto;
    .modal__line {
      border-top: 1px solid get($grey, border);
      margin-bottom: $space-unit * 3;
    }
    .wrapper-voucher-description {
      display: flex;
      align-items: center;
      i {
        display: none;
      }
      .voucher-description {
        padding-right: 10%;
      }
      @include breakpoint(md) {
        margin-bottom: $space-unit * 5;
      }
    }
    .voucher-box {
      position: relative;

      .wrapper-input {
        margin-bottom: 0;
      }
      input {
        width: 70%;
        // sarebbe da gestire il comportamento mobile con max()
        background-position: 82.5% center;
      }
      button.absolute {
        position: absolute;
        top: 28px;
        right: 0;
        width: 40%;
        min-width: 156px;
      }
    }
  }
  &.open {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    .modal__container {
      transform: scale(1);
    }
  }
  &.bill {
    .modal__head {
      padding: $space-unit * 3 $space-unit * 4 0 $space-unit * 4;
      span.close {
        right: $space-unit * 4;
      }
      @include breakpoint(sm) {
        padding: 0 $space-unit * 3;
        h4 {
          font-size: 16px;
          line-height: 24px;
        }
        span.close {
          right: $space-unit * 3;
        }
      }
    }
    .modal__body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding: 0;
      padding-top: $space-unit * 3;
      > * {
        padding: 0 $space-unit * 4;
      }
      button {
        width: 100%;
        margin-top: $space-unit * 2;
      }
      .switch-form {
        margin-top: $space-unit;
        margin-bottom: $space-unit * 3;
        .mbsc-segmented {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .mbsc-segmented-item {
            position: relative;
            height: $space-unit * 6;
            > input {
              opacity: 0;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              &:checked {
                + .mbsc-segmented-content {
                  background-color: get($red);
                  color: $white;
                  border-color: get($red);
                }
              }
            }
            .mbsc-segmented-content {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              font-size: 14px;
              line-height: 24px;
              font-family: get($font, secondary);
              border: 1px solid get($grey, border);
              @include animate();
            }
            &:nth-child(1) {
              .mbsc-segmented-content {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              }
            }
            &:nth-child(2) {
              .mbsc-segmented-content {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }
            .mbsc-ripple {
              display: none;
            }
          }
        }
      }

      .wrapper-forms {
        position: relative;
        overflow-y: auto;
        padding-bottom: $space-unit * 4;

        .validation-errors {
          color: get($red);
          font-weight: bold;
        }

        .form {
          display: block;
          opacity: 0;
          visibility: hidden;
          height: 0;
          overflow: hidden;
          @include animate();
          transition-property: visibility, opacity;
          &.active {
            opacity: 1;
            visibility: visible;
            height: auto;
            overflow: visible;
          }
          .switch {
            align-items: center;
            justify-content: center;
            margin-top: $space-unit;
            margin-bottom: $space-unit * 4;
            p {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  &.refound-confirm {
    &.success {
      .modal__body {
        padding-top: $space-unit * 2;
        padding-bottom: $space-unit * 4;
        .success {
          display: flex;
        }
        > :not(.success) {
          display: none;
        }
      }
    }
    &.loading {
      .loading {
        opacity: 1;
        visibility: visible;
      }
    }
    &.error {
      .modal__body {
        padding-top: $space-unit * 2;
        padding-bottom: $space-unit * 4;
        .error {
          display: flex;
          flex-direction: column;
        }
        > :not(.error) {
          display: none;
        }
      }
    }
    .modal__container {
      overflow: hidden;
    }
    .modal__body {
      padding: $space-unit * 5;
      text-align: center;
      > :not(.confirm) {
        display: none;
      }
    }
    .confirm,
    .success {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .confirm {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-bottom: $space-unit * 5;
      }
      button {
        margin: 0 auto;
        margin-bottom: $space-unit * 2;
        min-width: 330px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .success {
      h5 {
        margin-bottom: $space-unit * 2;
      }
      p {
        margin-bottom: $space-unit * 3;
      }
      .thumbs-up {
        background-size: 100%;
        margin-bottom: 6px;
      }
    }
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: transparentize(get($red), 0.2);
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease-out;
      > div {
        width: $space-unit * 2;
        height: $space-unit * 2;
        margin: 0 $space-unit;
        background: $white;
        border-radius: 50%;
        animation: loading-dot 0.6s infinite alternate;
        &:nth-child(2) {
          animation-delay: 0.2s;
        }
        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
    @include breakpoint(lg, min) {
      .modal__body {
        padding-right: 10%;
        padding-left: 10%;
      }
    }
    @include breakpoint(sm) {
      &::after {
        content: '';
      }
      .modal__container {
        top: initial;
        height: auto;
        bottom: 0;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        transform: translateY(50%);
      }
      .modal__body {
        padding: $space-unit * 5 $space-unit * 3;
      }
      .confirm,
      .success {
        button {
          width: 100%;
        }
      }
      .success {
        .thumbs-up {
          width: 144px;
          height: 144px;
        }
      }
      &.open {
        .modal__container {
          transform: translateY(0);
        }
      }
    }
  }

  &.modal-newsletter {
    .modal__body {
      padding: $space-unit * 2 70px $space-unit * 4;

      .newsletter-form {
        input[type='checkbox'] {
          margin-right: 0;
        }
      }
    }
  }

  &.completing-order-modal {
    .completing-order-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title,
      .text,
      .icon-waiting {
        align-self: center;
      }
      .text {
        text-align: center;
        margin-bottom: 15px;
        line-height: $space-unit * 2;
      }
      .title {
        text-align: center;
        margin-bottom: 10px;
      }
    }
    @include breakpoint(sm) {
      &::after {
        content: '';
      }
      .modal__container {
        height: auto;
        transform: none;
        width: 100%;
        top: auto;
        bottom: 0;
        border-radius: 46px 46px 0 0;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      }
    }
  }
  &.booking-popup {
    &.active {
      pointer-events: auto;
      background-color: rgba(0, 0, 0, 0.6);
    }
    .mbsc-grid {
      @include breakpoint(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .popup-body {
      @include breakpoint(sm) {
        position: absolute;
        bottom: 65%;
        transform: translateY(100%);
        opacity: 1;
        transition: all 0.3s ease-in-out;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 16px 24px 78px 24px;
      }
    }
    .modal__container {
      border-radius: 20px;
    }
  }
}
