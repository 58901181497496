.sidebar-wrapper-sticky-footer {
  display: none;

  @include breakpoint(md) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    @include animate;
    &.active {
      pointer-events: auto;
      background-color: rgba(0, 0, 0, 0.6);
      .cart-bar {
        .left {
          .arrow-up {
            transform: rotate(180deg);
          }
        }
      }
      .cart-body {
        opacity: 1;
        transform: translateY(0);
        max-height: 70vh;
        overflow: auto;
      }
    }

    .sidebar-footer-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .cart-bar {
      position: relative;
      display: grid;
      height: $cart-bar;
      grid-template-columns: 1fr auto;
      border-radius: 46px 46px 0 0;
      background: $white;
      padding: 0 $space-unit * 3;
      align-items: center;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      z-index: 1;
      pointer-events: auto;

      .left {
        display: flex;
        align-items: center;
        padding-right: $space-unit;

        .arrow-up {
          margin-right: 10px;
          cursor: pointer;
          outline: none;
          flex-shrink: 0;
          @include animate;
        }
        .total-price-label {
          color: get($grey, list);
          letter-spacing: initial;
        }
      }

      .right {
        text-align: right;

        button {
          float: right;
        }
      }
      .total-price-row {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    .cart-body {
      position: absolute;
      bottom: 50%;
      transform: translateY(100%);
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      opacity: 0;
      background-color: $white;
      @include animate;
      @include sidebarBody;
      padding: $space-unit * 2 $space-unit * 3 ($space-unit * 4 + $cart-bar*0.5) $space-unit * 3;
    }
    [class^='alert'] {
      width: 100%;
    }
  }

  @include breakpoint(xs) {
    .cart-bar {
      grid-template-columns: 1.2fr 0.8fr;
      .right {
        .button {
          height: 56px;
          padding: 8px 12px;
          line-height: 21px;
        }
      }
    }
  }
}
