.station-stops {
  margin-bottom: 80px;
  h3 {
    margin-bottom: 40px;
    text-align: center;
  }
  .station-box {
    border-radius: 20px;
    border: 1px solid #dfdfdf;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    align-items: center;
    overflow: hidden;
    > ul {
      padding: 44px;
      li:first-child {
        .station-select {
          padding-top: 0;
        }
      }
    }
  }
  .station-select {
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    padding-left: 32px;
    border-bottom: 1px solid #dfdfdf;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      transform: translate(1px, 3px);
      @include spritex('map-pin');
    }
    button {
      color: black;
      text-transform: initial;
      text-align: left;
      width: 100%;
      h4 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
      }
      p {
        font-size: 18px;
        line-height: 28px;
        font-weight: normal;
      }
    }
  }
  .map-container {
    width: 100%;
    height: 100%;
    min-height: 400px;
  }
  @include breakpoint(sm) {
    margin-bottom: 0;
    &.mbsc-grid {
      padding-left: 0;
      padding-right: 0;
    }
    h3 {
      font-size: 24px;
      line-height: 32px;
      margin-top: 10px;
      margin-bottom: 24px;
    }
    .station-box {
      border-radius: initial;
      border: initial;
      grid-template-columns: 100%;
      > ul {
        order: 1;
        padding: 32px 24px;
      }
    }

    .station-select {
      h4 {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
