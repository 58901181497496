:root {
  --columns: 12;
  --gutter: 16px;
  --offset: 24px;
  --color: hsla(170, 98%, 37%, 0.12);
  --repeating-width: calc(100% / var(--columns));
  --column-width: calc((100% / var(--columns)) - var(--gutter));
  --background-width: calc(100% + var(--gutter));
  --background-columns: repeating-linear-gradient(to right, var(--color), var(--color) var(--column-width), transparent var(--column-width), transparent var(--repeating-width));
}

@include breakpoint(xl) {
  :root {
    --offset: 80px;
    --color: hsla(288, 80%, 72%, 0.12);
  }
  html::after {
    content: 'XL';
  }
}

@include breakpoint(lg) {
  :root {
    --color: hsla(118, 80%, 72%, 0.12);
  }
  html::after {
    content: 'LG';
  }
}

@include breakpoint(md) {
  :root {
    --color: hsla(0, 80%, 72%, 0.12);
  }
  html::after {
    content: 'MD';
  }
}

@include breakpoint(sm) {
  :root {
    --columns: 4;
  }
  html::after {
    content: 'SM';
  }
}

@include breakpoint(xs) {
  html::after {
    content: 'XS';
  }
}

html {
  position: relative;
  &.debug-grid {
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin-right: auto;
      margin-left: auto;
      width: calc(100% - (2 * var(--offset)));
      max-width: get($screen, xl);
      min-height: 100vh;
      content: '';
      background-image: var(--background-columns);
      background-size: var(--background-width) 100%;
      z-index: 1000;
      pointer-events: none;
      @include breakpoint(lg) {
        max-width: 1280px;
      }
    }
  }
  &:not(.debug-grid)::after {
    content: initial;
  }
  &::after {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: $black;
    width: $space-unit * 6;
    height: $space-unit * 6;
    border-radius: 50%;
    z-index: 100;
    bottom: $space-unit * 2;
    right: $space-unit * 2;
    font-size: 14px;
    line-height: 1;
    box-shadow: 0px 10px 20px rgba(71, 14, 14, 0.15);
    color: $white;
    font-family: get($font, 'paragraph');
  }
}
