.bus-rent {
  background-color: get($grey, background);
  &--hero {
    img {
      width: 100%;
    }
  }
  &--container {
    justify-content: center;
    background-color: $white;
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    border-radius: 20px;
    border: 1px solid get($grey, border);
  }
  &--heading {
    font-size: 24px;
  }
  &--passengers {
    position: relative;
    display: block;
    border-radius: 10px;
    height: 48px;
    background-color: #f5f5f5;
  }
  &--minus,
  &--plus {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
    padding: 12px;
    box-sizing: content-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  &--plus {
    @include sprite('plus');
    right: 2px;
  }
  &--minus {
    @include sprite('minus');
    left: 2px;
  }
  &--passengers-number {
    text-align: center;
  }
  &--top-caption,
  &--bottom-caption {
    text-align: center;
    justify-content: center;
  }

  &--popup-actions {
    display: flex;
    justify-content: space-evenly;
  }

  @include breakpoint(lg, min) {
    &--container {
      min-width: 880px;
    }
  }
}
