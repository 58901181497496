.city-landing {
  .text-center {
    text-align: center;
  }

  .tab-title {
    font-weight: 400;
  }

  @include breakpoint(md, max){
    .city-tab-component {
      display: none;
    }
  }

  @include breakpoint(md, min){
    .city-accordion {
      display: none;
    }
  }

}