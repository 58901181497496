.button {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  padding: $space-unit $space-unit * 4;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:disabled {
    cursor: auto;
  }
  &:active {
    border-style: solid;
  }
  &:focus {
    outline: none;
  }
  &.loading {
    > div {
      width: 10px;
      height: 10px;
      margin: 0 5px;
      background: $white;
      border-radius: 50%;
      animation: loading-dot 0.6s infinite alternate;
      margin-top: 4px;
      &:nth-child(2) {
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
  &--primary {
    @extend .button;
    background-color: get($red);
    color: $white;
    border: 1px solid get($red);
    box-shadow: 0px 10px 20px rgba(71, 14, 14, 0.15);
    &:hover:not(:disabled) {
      background-color: get($red, dark);
      border-color: get($red, dark);
      box-shadow: none;
    }
    &:disabled {
      box-shadow: none;
      background-color: get($grey, light);
      border-color: get($grey, light);
      color: get($grey, button);
    }
  }
  &--deny {
    @extend .button;
    background-color: get($grey, deny);
    color: $white;
  }
  &--alternate {
    @extend .button;
    background-color: $white;
    color: get($red);
    border: 1px solid get($grey, border);
    box-shadow: none;
    &:hover:not(:disabled) {
      border-color: get($red);
    }
    &:disabled {
      color: get($grey, button);
      border-color: get($grey, light);
    }
  }
  &--tertiary-w-arrow {
    @extend .button;
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: $white;
    color: black;
    &:disabled {
      color: get($grey, button);
    }
    &::before {
      content: '';
      @include spritex('arrow-right');
    }
  }
  &--return {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: initial;
    font-family: get($font, paragraph);
    font-size: 14px;
    line-height: 22px;
    text-transform: initial;
    letter-spacing: 0;

    &:focus {
      outline: none;
      text-decoration: underline;
    }
    .button-return-content {
      display: flex;
      align-items: center;
      &::before {
        content: '';
        display: block;
        height: 13px;
        width: 13px;
        @include sprite('add-transparent');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: $space-unit;
      }
    }
    span {
      font-size: 12px;
      line-height: 22px;
    }
  }
  &--tag {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    border: none;
    background-color: initial;
    color: get($grey, darkest);
    &:focus {
      outline: none;
      text-decoration: underline;
    }
    &::before {
      content: '';
      display: block;
      height: $space-unit * 3;
      width: $space-unit * 3;
      @include sprite('tag');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: $space-unit * 2;
    }
  }
  &--debug {
    position: fixed;
    bottom: 5%;
    right: 5%;
    @include spritex('grid');
    padding: 12px;
    box-sizing: content-box;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  }
  &--back {
    &:hover {
      @include sprite('chevron-right-white');
      background-size: 80%;
      background-position: 60% center;
      background-color: get($red);
      border-color: get($red);
    }
    &:disabled {
      cursor: auto;
      @include sprite('chevron-right-grey');
      background-size: 80%;
      background-position: 60% center;
      background-color: get($grey, light);
      border-color: get($grey, light);
    }
    @include sprite('chevron-right');
    background-size: 80%;
    background-position: 60% center;
    background-color: white;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border: 1px solid get($grey, border);
    border-radius: 50%;
    transform: rotate(180deg);
    box-sizing: border-box;
    display: inline-block;
    cursor: pointer;
    @include animate();
  }
}
