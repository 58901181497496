.experience-component_experiences {
  margin-top: $space-unit * 15;
  margin-bottom: $space-unit * 10;
  @include breakpoint(sm) {
    margin-top: $space-unit * 10;
  }
}
.our-experiences {
  position: relative;
  overflow: hidden;

  .wrapper-video {
    margin-top: $space-unit * 7;
    position: relative;
    img {
      width: 100%;
      display: block;
    }
    .play-button {
      position: absolute;
      width: 8%;
      left: 50%;
      top: 50%;
      @include spritex('play');
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
  .carousel {
    @include swiper;
    margin-top: $space-unit * 6;

    .swiper-slide {
      > a {
        color: inherit;
        text-decoration: none;
      }
    }

    .item {
      position: relative;
      overflow: hidden;
      background-color: $white;
      border-radius: 10px;
      border: 1px solid get($grey, border);
      display: grid;
      grid-template-columns: 35% auto;
      align-items: center;
      height: 16vw;
      max-height: 200px;
      min-height: 160px;

      &__image {
        overflow: hidden;
        position: relative;
        height: 100%;
        width: 100%;
        max-height: 350px;
        img {
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          height: 100%;
        }
      }

      &__body {
        padding: 0 $space-unit * 3;
        h5 {
          margin-bottom: $space-unit * 2;
        }
      }

      @include breakpoint(sm) {
        grid-template-columns: auto;
        height: auto;
        max-height: initial;
        &__image {
          img {
            position: relative;
            width: 100%;
            height: auto;
          }
        }
        &__body {
          padding: $space-unit * 3;
        }
      }
    }
  }

  @include breakpoint(md) {
    .wrapper-video {
      margin-top: $space-unit * 4;
    }
    h3 {
      font-size: 32px;
      line-height: 40px;
    }
    .carousel {
      margin-top: $space-unit * 4;
    }
  }
}
