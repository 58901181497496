.breadcrumb-wrapper {
  padding: $space-unit * 2 0;
  margin-top: 100px;
}
.breadcrumb {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    justify-content: center;
  }
  button {
    flex-shrink: 0;
  }
  .caption {
    font-size: 12px;
    line-height: 16px;
    color: get($grey, caption);
    &.mobile {
      display: none;
    }
  }
}
.breadcrumb-list {
  display: flex;
  align-items: center;
  .breadcrumb-item {
    p,
    a {
      font-family: get($font, secondary);
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: get($grey, caption);
    }
    a {
      text-decoration: none;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
@include breakpoint(sm) {
  .breadcrumb {
    .caption {
      display: none;
      &.mobile {
        display: block;
      }
    }
  }
  .breadcrumb-list {
    display: none;
  }
}
