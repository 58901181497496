.categorypage {
  &__editorial-area {
    margin: 1.5rem 0;
  }

  &--carnets, &--iobusopen, &--iobuslongticket, &--iobusoneround {
    .listing {
      .voucher-tile {
        .voucher-tile-title {
          padding-right: 70px;
          min-height: 80px;
        }
        h2, .voucher-message {
          font-family: get($font, secondary);
        }
        .voucher-logo {
          background-color: get($red, default);
        }
      }
    }
  }
}