.alert {
  padding: 12px 0;
  padding-left: $space-unit * 7;
  padding-right: $space-unit * 2;
  display: flex;
  align-items: center;
  margin-top: $space-unit;
  text-align: left;
  min-height: 48px;
  font-size: 12px;
  line-height: 16px;
  &--error,
  &--info,
  &--success,
  &--notification {
    @extend .alert;
    border-radius: $space-unit;
  }
  &--info {
    @include sprite('info-circle');
    background-size: 24px;
    background-color: get($grey, background);
    background-position: $space-unit * 2 45%;
    // color: get($alerts, success);
  }
  &--error {
    @include sprite('field-error');
    background-size: 24px;
    background-color: get($red, light);
    background-position: $space-unit * 2 45%;
    color: get($red);
  }
  &--success {
    @include sprite('success');
    background-size: 24px;
    background-color: get($alerts, success_bg);
    background-position: $space-unit * 2 45%;
    color: get($alerts, success);
  }
  &--notification {
    @include sprite('bell');
    background-size: 24px;
    background-color: get($alerts, notification_bg);
    background-position: $space-unit * 2 45%;
    color: get($alerts, notification);
  }
}

#__react-alert__ {
  &,
  * {
    box-sizing: border-box;
  }
  .alert {
    margin-top: 0;
    margin-left: $space-unit * 4;
    margin-bottom: $space-unit * 4;
    min-width: 280px;
  }
}
