.scroll-to-search {
  position: absolute;
  bottom: $space-unit * 3;
  right: 0;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  @include animate;
  &.show {
    opacity: 1;
    visibility: visible;
  }
  &::before {
    content: '';
    display: block;
    @include sprite('search-bus');
    height: 75px;
    width: 75px;
    background-size: 132px;
    background-position: -34px -36px;
    filter: drop-shadow(0px 10px 20px rgba(71, 14, 14, 0.2));
  }
  &.show-box {
    @include breakpoint(md, min) {
      bottom: calc(100vh - 130px);
      &::before {
        @include sprite('close-red');
      }
    }
    @include breakpoint(sm) {
      &::before {
        @include sprite('search-bus-close');
      }
    }
  }
}
.scroll-to-search-wrapper {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 4;
  .inner-wrapper {
    position: relative;
    width: 100%;
  }
  @include breakpoint(md) {
    z-index: 2;
  }
  &--hidden {
    display: none;
  }
}
