.grid-container {
  &.payment-container {
    .sidebar-wrapper {
      padding-top: $space-unit * 2;
      padding-bottom: $space-unit * 10;
    }
    .heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: $space-unit * 3;
    }

    .booking-recap {
      margin-top: $space-unit * 2;
      border: 1px solid #dfdfdf;
      border-radius: $space-unit * 2;
      display: flex;
      flex-direction: column;
      padding: 0 $space-unit * 2;

      .arrow-down,
      .arrow-up {
        margin-right: $space-unit;
        width: ($space-unit * 3);
        height: ($space-unit * 3);
        background-size: 100%;
      }

      .recap-detail {
        display: flex;
        flex-direction: column;
        background: get($grey, fog);
        border-radius: 10px;
        margin-bottom: $space-unit;
        padding: $space-unit * 2;

        &__passengers {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
        }

        &__price {
          margin-left: auto;
        }

        &__services {
          display: flex;
          flex-direction: column;
          padding-top: $space-unit;
          margin-top: $space-unit;
          border-top: 1px solid get($grey, light);

          &--update {
            border-top: 0;
            padding-top: 0;
            margin-top: 0;
          }

          &__overview {
            display: flex;
            align-items: center;
            font-weight: bold;

            i {
              margin: 0 $space-unit;
            }
          }

          &__item {
            display: flex;
            padding-top: $space-unit;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      .recap-route {
        display: flex;
        align-items: center;
        padding: $space-unit * 2 0;
        &:not(:first-child) {
          border-top: 1px solid #dfdfdf;
        }
        &__info {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          overflow: hidden;

          &__price {
            font-family: get($font, secondary);
            font-size: 16px;
            line-height: 24px;
            font-weight: bold;
            margin-left: ($space-unit * 2);
          }

          &__direction {
            font-size: 12px;
            line-height: 16px;
            font-weight: bold;
          }

          &__stations {
            display: flex;
            align-items: center;
            overflow: hidden;
            width: 100%;

            span {
              margin: 0 $space-unit;
              flex-shrink: 0;
            }

            p {
              font-size: 14px;
              line-height: 22px;
              @include text-shorten;
            }
          }
        }
      }

      .cart-totals {
        .total-price-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: $space-unit * 2 0;
          border-top: 1px solid #dfdfdf;

          .total-price-label,
          .total-price-value {
            color: get($grey, list);
          }
          &:not(.voucher-row) {
            display: none;
          }
        }
        .voucher-row {
          position: relative;

          .total-price-value {
            color: #ee203c;
          }

          .total-price-label {
            font-family: get($font, secondary);
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }

          .voucher-remove {
            cursor: pointer;
            position: absolute;
            top: -16px;
            right: -16px;
            padding: 8px;
            box-sizing: content-box;
          }
        }
      }

      // &__total {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: center;

      //   padding-top: $space-unit * 2;

      //   &:not(:last-child) {
      //     border-bottom:  1px solid #DFDFDF;
      //     padding-bottom: $space-unit * 2;
      //   }
      // }

      // &__total-label {
      //   font-size: 12px;
      //   line-height: 16px;
      //   font-weight: bold;
      // }

      // &__total-value {
      //   font-size: 24px;
      //   line-height: 32px;
      //   font-weight: bold;
      // }
    }

    button {
      &.option {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin: $space-unit * 3 0;
        @include breakpoint(md, min) {
          &.voucher {
            position: absolute;
            right: 8px;
            top: 0;
            margin-top: ($space-unit * 2);
            width: auto;
          }
        }
      }
    }
    .payments {
      .card {
        padding: 20px;
      }
      .creditcardfield {
        input {
          margin-bottom: 20px;
        }
      }
    }
  }
}
