.booking-search-container {
  @include breakpoint(md) {
    &.no-padding {
      padding-left: $space-unit;
      padding-right: $space-unit;
    }
    .recap-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
    .booking-search-sidebar {
      .breadcrumb {
        justify-content: flex-start;
      }
      .ticket-code.h4 {
        margin-top: 0;
      }
    }
  }
  .alert--success {
    margin-top: 0;
    padding-right: 20%;
  }

  .refound-table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;

    p,
    h6 {
      &:first-of-type {
        margin-bottom: $space-unit;
      }
    }

    .separator {
      grid-column: 1/3;
      margin: $space-unit * 3 0;
      height: 1px;
      width: 100%;
      background-color: get($grey, border);
    }
  }

  .info-box {
    padding-left: $space-unit;
    padding-right: $space-unit;
    h6 {
      display: flex;
      align-items: center;
      margin-bottom: $space-unit;

      i {
        margin-right: $space-unit * 2;
      }
    }
    ul {
      list-style: initial;
      margin-left: $space-unit * 2;
    }
    .highlight {
      font-weight: bold;
      margin: $space-unit * 2 0 $space-unit;
    }
  }

  .sidebar {
    .big-paragraph {
      padding-right: 20%;
    }

    h4,
    .h4 {
      margin-top: $space-unit * 4;
      margin-bottom: $space-unit * 2;
    }
  }

  .content {
    form {
      .form-field {
        display: block;
        margin-bottom: $space-unit * 2;

        .caption {
          display: block;
          margin-bottom: $space-unit;
        }
      }

      .ticket-label {
        input {
          text-transform: uppercase;
        }
      }

      button[role='submit'] {
        margin-top: $space-unit * 4;
        width: 100%;

        i {
          margin-right: $space-unit * 2;
        }

        + .alert--error {
          margin-top: $space-unit * 3;
        }
      }
    }
  }

  .extra-actions {
    margin-bottom: 40px;
  }

  legend {
    text-align: center;
  }

  .privacy {
    text-align: center;
  }

  .privacy {
    padding: $space-unit * 4 0;
  }

  .ticket-code {
    font-family: get($font, secondary);
    font-weight: 600;
  }

  .quick-actions {
    display: flex;
    margin-top: $space-unit * 3;

    @include breakpoint(sm) {
      justify-content: center;
    }

    .action {
      position: relative;
      display: block;
      margin-right: $space-unit * 2;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .wrapper-tickets {
    position: relative;
    .total-refound {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: $space-unit * 3 0;
    }

    > h4,
    .h4 {
      margin-top: $space-unit * 5;
      margin-bottom: $space-unit * 3;
      text-align: center;
    }

    button {
      &.remove {
        position: absolute;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        padding: 0;
        z-index: 1;
        right: 0;
        transform: translate(20px, -20px);

        &::before {
          @include labelIcon('trash');
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .wrapper-buttons {
    display: flex;
    flex-direction: column;
    margin-top: $space-unit * 3;

    > * {
      margin-bottom: $space-unit * 2;

      &:last-child {
        margin-bottom: 0;
      }
    }

    button {
      width: 100%;
    }
  }

  .collapsible {
    margin-top: $space-unit * 2;
    border-top: 1px solid get($grey, border);
    padding-top: $space-unit * 3;
  }

  .services-boxes {
    .card {
      height: 186px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: $space-unit * 2;

      .caption {
        margin-top: $space-unit * 2;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .seats-wrapper {
    width: 100%;
    margin-top: $space-unit;
    padding: $space-unit * 2;

    h6 {
      line-height: 32px;
      font-family: get($font, paragraph);
      font-weight: 400;
      margin-bottom: $space-unit * 2;
    }

    p {
      font-size: 12px;
      line-height: 16px;
    }

    .caption {
      display: block;
      width: 100%;
      padding-bottom: $space-unit;
      border-bottom: 1px solid get($grey, border);
    }

    .seats {
      margin-top: $space-unit * 2;
      margin-bottom: $space-unit;

      .seat {
        display: flex;
        justify-content: space-between;

        p {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
    .change-time-wrapper {
      width: 100%;
      background: $white;
      padding: 8px;
      border-radius: 10px;
      margin-top: 24px;
      .change-time {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          &:first-of-type {
            font-weight: 600;
            margin-right: 4px;
          }
          &:last-of-type {
            margin-right: 8px;
          }
        }
      }
    }
  }

  @include breakpoint(md, min) {
    padding-top: $header-height-desktop + $space-unit * 5;
    padding-bottom: 120px;
  }

  @include breakpoint(sm) {
    .sidebar {
      text-align: center;
    }
  }

  @include breakpoint(md) {
    padding-top: $space-unit * 3;
    padding-bottom: $space-unit * 10;

    .content form button[role='submit'] {
      margin-top: $space-unit * 3;
    }

    .big-paragraph {
      font-size: 14px;
    }

    .sidebar {
      margin-bottom: $space-unit * 2;
      h4,
      .h4 {
        margin-top: $space-unit * 3;
      }
    }

    .alert--success {
      padding-right: 0;
      margin-top: $space-unit * 4;
    }

    .refound-table {
      margin-bottom: $space-unit * 2;
    }

    .wrapper-tickets {
      button {
        &.remove {
          transform: translate(15px, -15px);
        }
      }
    }
  }
  .delete-ticket {
    form {
      button {
        width: 100%;
      }
    }
    button {
      width: 100%;
    }
  }
  .editorial-search-container {
    margin-top: 40px;
    .editorial-search-wrapper {
      height: 496px;
      display: flex;
      align-items: center;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      padding: 0;
      .booking-search-editorial {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 60%;
        height: 100%;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        display: flex;
        align-items: flex-end;
        .editorial-text-content {
          margin: 30px;
          h2 {
            font-size: 24px;
            line-height: 40px;
            color: $white;
            margin-bottom: 12px;
          }
          .editorial-text-inner-wrapper {
            color: $white;
            font-size: 16px;
            line-height: 28px;
            p {
              display: flex;
              align-items: center;
              margin-top: 8px;
            }
            .icon-check-red {
              display: inline-block;
              margin-right: 16px;
              width: 24px;
              height: 24px;
            }
          }
        }
      }
      .booking-search-form {
        width: 40%;
        padding: 32px 40px;
        line-height: 40px;
        .button--primary {
          height: 56px;
        }
        .alert--error {
          margin-top: 8px;
          padding: 0 0 0 56px;
        }
      }
    }
  }
  .search-booking-icon {
    margin-left: 8px;
  }
  @include breakpoint(md) {
    .editorial-sidebar {
      margin-bottom: 0;
      padding-left: 0;
      .breadcrumb {
        justify-content: flex-start;
      }
    }
    .editorial-search-container {
      margin-top: 0;
      .editorial-search-wrapper {
        box-shadow: unset;
        height: unset;
        .booking-search-editorial {
          display: none;
        }
        .booking-search-form {
          width: 100%;
          padding: 0 0 15px 0;
          h1 {
            text-align: center;
          }
          .button--primary {
            margin-top: 36px;
          }
        }
      }
    }
  }
  .booking-recap-card {
    .ic-booking-arrow-right {
      background-size: 100%;
      width: 22px;
      height: 14px;
    }
    background: get($grey, background);
    border-radius: 30px;
    padding: $space-unit * 2;
    padding-bottom: $space-unit * 3;
    border: 1px solid get($grey, border);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    .direction-title {
      text-align: center;
    }
    .departures-arrivals-cities {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $space-unit * 3;
      .departure-city,
      .arrival-city {
        font-size: 24px;
        line-height: 32px;
        width: 50%;
        text-align: right;
      }
      .arrival-city {
        text-align: left;
      }
      i {
        margin: 0 $space-unit * 2;
      }
      .ic-arrow-switch {
        background-size: 100%;
        width: 22px;
        height: 28px;
      }
    }
    .booking-detail-accordion {
      .accordion-wrapper {
        margin: $space-unit * 3 0 0 0;
        padding: 4px;
      }
      .accordion-item {
        &.open {
          .show-less {
            display: block !important;
          }
          .show-more {
            display: none;
          }
        }
      }
      .accordion-item__head {
        height: 24px;
        border-bottom: unset;
        justify-content: space-between;
        position: relative;
        .accordion-head-text {
          display: flex;
          align-items: center;
          .show-more,
          .show-less {
            font-weight: 600;
            margin-right: $space-unit;
            color: get($red);
            text-wrap: nowrap;
            font-family: get($font, secondary);;
          }
          .show-less {
            display: none;
          }
        }
        .line {
          border-top: 1px solid get($grey, border);
          width: 38%;
        }
      }
      .travel-service-details {
        width: 100%;
        padding: 0 $space-unit * 2;
      }
      .services-recap {
        margin-top: $space-unit * 3;
        .services-wrapper {
          .collapsible {
            &:not(:first-of-type) {
              margin-top: 0;
            }
            border: unset;
            padding: 0;
          }
          .services-boxes {
            .service-box {
              display: flex;
              align-items: center;
              min-width: 30%;
              background: $white;
              border: 1px solid get($grey, border);
              border-radius: 8px;
              padding: $space-unit;
              margin: 4px 4px 0 0;
              .caption {
                font-size: 12px;
                line-height: 16px;
                padding: 4px;
              }
              i {
                margin-right: 4px;
              }
            }
          }
          .seats-wrapper {
            padding: 0;
            margin-top: $space-unit * 3;
            .caption {
              font-size: 14px;
              line-height: 24px;
              display: flex;
              align-items: center;
              .ic-booking-arrow-right {
                width: 12px;
                height: 6px;
                margin: 0 8px;
              }
            }
            .seats {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .seat {
                width: 47%;
                p:last-of-type {
                  font-size: 12px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
    .tabs-component {
      padding-bottom: 0;
    }
    .tabs-header {
      margin-top: $space-unit * 3;
      ul {
        align-items: center;
        padding: unset;
        @include breakpoint(md) {
          display: flex;
        }
        li {
          width: 50%;
          margin: 0;
          text-align: center;
          .tab-item-head {
            color: get($red);
            background: $white;
            width: 100%;
            text-transform: uppercase;
            padding: 12px;
            &.outbound {
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              border: 1px solid get($grey, border);
              border-right: unset;
            }
            &.inbound {
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              border: 1px solid get($grey, border);
              border-left: unset;
            }
            &.selected {
              color: $white;
              background: get($red);
            }
          }
        }
      }
    }
    @include breakpoint(md) {
      border-radius: unset;
      .departures-arrivals-cities {
        flex-direction: column;
        margin-top: 12px;
        .departure-city,
        .arrival-city {
          width: unset;
        }
        .ic-arrow-switch {
          transform: rotate(90deg);
          width: 11px;
          height: 14px;
          margin: 4px 0;
        }
      }
      .booking-detail-accordion {
        .travel-service-details {
          padding: 0 $space-unit;
        }
        .accordion-item__head {
          justify-content: center;
        }
        .services-recap {
          margin-top: $space-unit * 6;
          .services-wrapper {
            h6 {
              font-size: 14px;
              border-bottom: 1px solid get($grey, border);
            }
            .services-boxes .service-box {
              width: 100%;
              margin: 0 0 $space-unit 0;
            }
            .seats-wrapper {
              .caption {
                flex-wrap: wrap;
              }
              .seats {
                flex-direction: column;
                .seat {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
  .booking-modify-buttons-wrapper {
    h4 {
      margin-top: 40px;
      font-size: 1.5rem;
      line-height: 32px;
      margin-bottom: 3 * $space-unit;
    }
    .postsale-button {
      width: 100%;
      padding: 4px 12px 4px 4px;
      height: 72px;
      border-radius: 100px;
      justify-content: space-between;
      .label-wrapper {
        display: flex;
        align-items: center;
        &--text {
          padding: 0 1.5 * $space-unit;
          text-align: left;
        }
      }
    }
    .booking-detail-luggage-seat-buttons {
      display: flex;
      .postsale-button-form {
        width: 50%;
        margin-bottom: $space-unit;
        &:first-child {
          margin-right: 2 * $space-unit;
        }
        .postsale-button {
          border-color: get($red);
          .ic-plus {
            background-size: 32px 32px;
            width: 32px;
            height: 32px;
          }
          .label-wrapper {
            &--text {
              font-size: 1rem;
            }
          }
        }
      }
    }
    .booking-detail-modify-buttons {
      display: flex;
      flex-direction: column;
      .postsale-button {
        margin-bottom: $space-unit;
        padding: 12px 24px;
        .label-wrapper {
          i {
            background-size: 24px 24px;
            width: 24px;
            height: 24px;
          }
          &--text {
            color: get($grey, dark);
          }
        }
      }
    }
    @include breakpoint(md) {
      padding: 0 3 * $space-unit;
      h4 {
        margin-top: 30px;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 2 * $space-unit;
      }
      .booking-detail-luggage-seat-buttons {
        flex-direction: column;
        .postsale-button-form {
          width: 100%;
          &:first-child {
            margin-right: 0;
          }
          &:last-child {
            margin-bottom: 2px;
          }
        }
      }
      .booking-detail-modify-buttons {
        .postsale-button {
          height: 56px;
          .label-wrapper {
            &--text {
              padding: 0 $space-unit;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.booking-recap-inner-card {
  background-color: $white;
  border: 1px solid get($grey, border);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: $space-unit * 2 $space-unit * 3;
  &.inbound {
    margin-top: $space-unit;
  }
  .travel-changes {
    display: flex;
    align-items: center;
    .info-circle {
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
    &.mobile {
      display: none;
    }
  }
  .date-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .departure-date {
      font-size: 18px;
      line-height: 24px;
    }
  }
  .stations-section {
    display: flex;
    margin-top: 12px;
    align-items: center;
    .stations-dots {
      display: flex;
      flex-direction: column;
      width: 12px;
      :first-child,
      :last-child {
        border: 1px solid get($grey);
        border-radius: 50%;
        height: 12px;
      }
      :first-child {
        margin-top: 3px;
      }
      :last-child {
        margin-bottom: 3px;
      }
      :nth-child(2) {
        height: 14px;
        border-left: 1px solid get($grey);
        margin: 4px 0;
        position: relative;
        left: 6px;
      }
    }
    .stations-wrapper {
      width: 100%;
      margin-left: $space-unit;
      .station {
        display: flex;
        justify-content: space-between;
        .station-name,
        .station-time {
          font-size: 14px;
          font-family: 'Montserrat';
          line-height: 24px;
        }
        .station-time {
          font-weight: 600;
        }
      }
    }
  }
  @include breakpoint(md) {
    .travel-changes {
      display: none;
      &.mobile {
        display: flex;
        margin-top: 12px;
      }
    }
  }
}
