// ADD VENDOR PREFIXES TO ANY CSS PROPERTY
@mixin css3-prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}

//KEYFRAME ANIMATIONS
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

//ARROW WITH FOUR OPTIONAL DIRECTIONS
@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}

//MEDIA QUERIES
@mixin breakpoint($point, $type: max) {
  $width: get($screen, $point);
  @if $point == xl {
    $type: min;
  }
  @if $type == max {
    $width: $width - 1px;
  }
  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}

//TRIM TEXT
@mixin text-shorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Define vertical, horizontal, or both position
@mixin center($position) {
  position: absolute;

  @if $position == 'vertical' {
    top: 50%;
    @include css3-prefix(transform, translateY(-50%));
  } @else if $position == 'horizontal' {
    left: 50%;
    @include css3-prefix(transform, translateX(-50%));
  } @else if $position == 'both' {
    top: 50%;
    left: 50%;
    @include css3-prefix(transform, translate(-50%, -50%));
  }
}

@mixin spritex($name) {
  $sprite: map-get($sprites, $name);
  background: url($sprite) center no-repeat;
  $size: map-get($sizes, $name);
  background-size: map-get($size, width) map-get($size, height);
  width: map-get($size, width);
  height: map-get($size, height);
  display: inline-block;
}

@mixin animate($time: 0.3) {
  transition: all #{$time}s ease-in-out;
}

@mixin labelIcon($name, $type: left) {
  content: '';
  position: absolute;
  top: 0; // non in percentuale per gestire il caso in cui c'è il messaggio di errore sotto
  transform: translateY(50%);
  @if $type == 'right' {
    right: $space-unit * 2;
  } @else {
    left: $space-unit * 2;
  }
  display: block;
  height: $space-unit * 3;
  width: $space-unit * 3;
  @include sprite($name);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
