[data-calendar-event='true'] {
  .add-to-calendar-widget {
    display: none;
    position: absolute;
    padding-top: $space-unit;
    top: 100%;
    left: 100%;
    z-index: 1;
    @include animate();
    .add-to-calendar-dropdown {
      border-radius: 10px;
      border: 1px solid get($grey, border);
      background-color: $white;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: $space-unit * 2;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      border-top-left-radius: 0;
      overflow: visible;
      &::before,
      &::after {
        content: '';
        // z-index: 100;
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 1px;
        transform: translateY(-100%);
        border-style: solid;
        border-width: 10px 0 0 13px;
        border-color: transparent transparent transparent $white;
      }
      &::after {
        z-index: -1;
        left: -1px;
        border-width: 12px 0 0 $space-unit * 2;
        border-color: transparent transparent transparent get($grey, border);
      }
      a {
        display: flex;
        align-items: center;
        opacity: 0.8;
        white-space: pre;
        text-decoration-line: none;
        margin-bottom: $space-unit;
        transition: all 0.2s ease-out;
        &:focus {
          outline: none;
          text-decoration-line: underline;
        }
        &::before {
          margin-right: $space-unit;
        }
        &:hover {
          opacity: 1;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  &.show {
    .add-to-calendar-widget {
      display: block;
    }
  }
}

[data-whatintent='mouse'] {
  [data-calendar-event='true'] {
    .add-to-calendar-widget {
      display: block;
      visibility: hidden;
      opacity: 0;
    }
    &:hover {
      .add-to-calendar-widget {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
