.experience-component_promo_card_wrapper {
  margin-top: $space-unit * 10;
  margin-bottom: $space-unit * 8;
  @include breakpoint(sm) {
    margin-top: $space-unit * 5;
    margin-bottom: $space-unit * 2;
  }
}
.promo-card-wrapper {
  h2 {
    margin-bottom: $space-unit * 5;
  }
  .promo-card {
    position: relative;
    margin-bottom: $space-unit * 2;
    padding-bottom: 100%;
    width: 100%;
    img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: -1;
    }
    .marker {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      height: 112px;
      width: 112px;
      background-color: get($red);
      border-bottom-right-radius: 80%;
      i {
        margin: $space-unit * 2;
      }
    }
    .wrapper-text {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: $space-unit * 5;
      color: $white;
    }
    .go-to {
      &,
      .button {
        color: $white;
      }
    }
  }
  @include breakpoint(sm) {
    h2 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: $space-unit * 3;
    }
    .promo-card {
      margin-bottom: $space-unit * 3;
      .marker {
        width: 66px;
        height: 66px;
        i {
          height: $space-unit * 4;
          width: $space-unit * 4;
          margin: 12px;
        }
      }
      .wrapper-text {
        padding: $space-unit * 3;
        h3 {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}
