.home-header {
  min-height: 600px;
  padding-top: $header-height-desktop;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: get($font, secondary);
  @include breakpoint(sm) {
    min-height: 270px;
  }
  .wrapper-text {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10%;
    color: $white;
    .has-shadow {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .black {
      color: $black;
    }
    h2,
    .h2 {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      font-size: $space-unit * 8;
      line-height: $space-unit * 9;
    }
    p.subtitle {
      font-size: $space-unit * 3;
      line-height: $space-unit * 4;
      margin: $space-unit 0;
    }
  }
  &.has-slideshow {
    //for editmode
    &[data-edit='true'] {
      padding-bottom: $space-unit * 4;
      .wrapper-text {
        position: relative;
        bottom: initial;
        //color: $black;
      }
      .grid-layout {
        .swiper-slide {
          height: 400px;
        }
      }
    }
    //carosello
    .slideshow {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      @include swiper();
      .swiper-button-next,
      .swiper-button-prev {
        bottom: $space-unit * 4;
        @include sprite('chevron-right');
        background-size: 80%;
        background-position: 60% center;
        background-color: $white;
        width: 34px;
        height: 34px;
        border: 1px solid get($grey, border);
        border-radius: 50%;
        box-sizing: border-box;
        right: initial;
        &:hover {
          @include sprite('chevron-right-white');
          background-size: 80%;
          background-position: 60% center;
          background-color: get($red);
          border-color: get($red);
        }
      }
      .swiper-button-next {
        left: 42px;
        transform: initial;
      }
      .swiper-button-prev {
        left: 0;
        transform: rotate(-180deg);
        top: initial;
      }
    }
    .header-slide {
      display: block;
      height: 100%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .wrapper-text {
        bottom: $space-unit * 16;
        z-index: 2;
      }
    }
    .nav-box {
      position: relative;
    }
    picture.overlay {
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 1;
      }
    }
    @include breakpoint(lg) {
      .wrapper-text .h2 {
        width: initial;
      }
    }
    @include breakpoint(md) {
      padding-top: 0;
      background-size: 120%;
      background-position: top center;

      .slideshow {
        position: relative;
      }

      .header-slide {
        img {
          position: absolute;
          height: calc(100% + 30px);
        }
        .wrapper-text {
          bottom: initial;
        }
      }
      picture.overlay {
        &::before {
          height: calc(100% + 30px);
        }
      }

      .wrapper-text {
        position: relative;
        bottom: initial;
        padding: 14vw $space-unit * 3;
        text-align: center;
        .h2 {
          font-size: 40px;
          line-height: 48px;
          width: initial;
        }
        p.subtitle {
          font-size: $space-unit * 2;
          line-height: $space-unit * 3;
          margin: 6px 0;
        }
      }

      > .mbsc-grid {
        padding: 0;
      }

      .wrapper-text .h2 {
        width: 100%;
      }
    }
    @include breakpoint(sm) {
      .wrapper-text {
        padding: 6vw $space-unit * 3;
        min-height: 240px;
        .mbsc-grid {
          padding-left: 12px;
          padding-right: 12px;
        }
        .h2 {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .swiper-pagination-bullets {
        display: none;
      }
    }

    @include breakpoint(md) {
    }
  }
}
