.membership-badge {
  background: var(--searchbox-url) no-repeat top center;
  border-radius: 50px;
  margin-right: 8px;
  padding: 5px 8px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  line-height: normal;
  white-space: nowrap;
  visibility: hidden;
  &.passenger_1,
  &.passenger_2 {
    visibility: visible;
  }
}