.discover-itabus {
  &__title {
    font-size: 3.5rem;
    line-height: 32px;
    margin: $space-unit 0 $space-unit * 7 0;
  }
  &__wrapper {
    width: 75%;
    margin: $space-unit auto;
  }
  .discover-itabus-item {
    .di-item {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin: 8px 8px 8px;
      border: 1px solid get($red);
      border-radius: 54px;
      padding-left: 8px;
      min-height: 72px;
      text-decoration: none;
      i {
        margin-right: 8px;
      }
    }
    .di-item-inner {
      display: flex;
      position: relative;
      align-items: center;
      right: 5px;
      .di-image {
        width: 64px;
        height: 64px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
      }
      .di-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 4px;
        max-width: 80%;
        h5 {
          color: get($red);
          text-transform: uppercase;
          font-size: 1rem;
          line-height: 24px;
        }
        p {
          color: get($grey);
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  @include breakpoint(md) {
    &__title {
      font-size: 1.5rem;
      line-height: 32px;
      margin: $space-unit *2 auto;
    }
    &__wrapper {
      width: 100%;
    }
    .discover-itabus-item {
      .di-item-inner .di-text{
        max-width: 75%;
      }
      .di-item {
        margin: 8px 0;
    }
  }
}
}