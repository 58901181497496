.promo-box__wrapper {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 24px;

  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;

    .swiper-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      transition-property: transform;
      box-sizing: content-box;

      .swiper-slide {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
        transition-property: transform;
      }
    }
  }

  .experience-component_box_item,
  .experience-component_box_item_extended {
    a {
      display: block;
      color: initial;
      text-decoration: none;
    }
  }

  .mbsc-row {
    display: flex;
    flex-wrap: wrap;
    @include breakpoint(sm, min) {
      justify-content: center;
      margin: 0;
    }

    @include breakpoint(sm, max) {
      &.swiper-wrapper {
        flex-wrap: nowrap;
      }
    }

    .experience-component_box_item {
      width: 100%;
      margin: 0 0 16px;

      @include breakpoint(sm, min) {
        width: 45%;
        margin: 0 8px 24px;
      }
    }
    .experience-component_box_item_extended {
      &,
      &.swiper-slide {
        width: 80%;
        margin: 0 auto 50px;

        @include breakpoint(sm, min) {
          width: 30%;
          margin: 0 8px 100px;
        }
      }
    }
  }
  .promo-box {
    height: 100%;
    display: flex;
    width: 100%;
    &__item {
      display: flex;
      width: inherit;
      background: #ffffff;
      border-radius: 16px;
      border: 2px solid #dfdfdf;
      padding: 16px;
      text-align: left;
    }
    &__img {
      img {
        width: 80px;
        border-radius: 7px;
        display: block;
      }
    }
    &__content {
      text-align: left;
      padding-left: 16px;

      span {
        font-weight: 700;
        font-size: 16px;
        color: #e1261d;
        text-transform: uppercase;
      }
      p {
        margin-top: 10px;
      }
    }
  }
  .promo-box {
    &__item--extended {
      background: #ffffff;
      text-align: left;
      overflow: hidden;
    }
    &__img--extended {
      img {
        width: 100%;
        border-radius: 16px 16px 0 0;
        display: block;
      }
    }
    &__content--extended {
      padding: 20px;
      border-radius: 0 0 16px 16px;
      border-left: 2px solid #dfdfdf;
      border-right: 2px solid #dfdfdf;
      border-bottom: 2px solid #dfdfdf;
      @include breakpoint(md, min) {
        min-height: 230px;
      }
      @include breakpoint(sm, max) {
        min-height: 290px;
      }
      span {
        font-size: 16px;
        font-weight: 700;
      }
      > span {
        text-transform: uppercase;
        margin-bottom: 4px;
        text-align: left;
      }
    }
    &__line--extended {
      padding: 20px 0;
      border-bottom: 1px #dfdfdf solid;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .info {
        font-style: italic;
      }

      div {
        &.col-left {
          width: 65%;
          text-align: left;

          img {
            @include breakpoint(md, min) {
              height: 40px;
            }
            @include breakpoint(sm, max) {
              height: 30px;
            }
          }
        }
        &.col-right {
          width: 35%;
          text-align: right;
        }
      }

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}
