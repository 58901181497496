.hero-title-slideshow {
  position: relative;
  padding-top: $header-height-desktop + $space-unit * 25;
  padding-bottom: $space-unit * 25;
  &[data-edit='true'] {
    padding-top: $header-height-desktop;
    padding-bottom: $space-unit * 4;
    .caption,
    h2,
    h4 {
      color: $black;
    }
  }
  h4 {
    display: none;
  }
  .caption,
  h2,
  h4 {
    color: $white;
  }
  //for editmode
  .grid-layout {
    .swiper-slide {
      height: 400px;
      img {
        position: absolute;
        width: 100%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  //carosello
  .slideshow {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    @include swiper();
    .swiper-slide {
      overflow: hidden;
      .background-cover {
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      bottom: $space-unit * 4;
      @include sprite('chevron-right');
      background-size: 80%;
      background-position: 60% center;
      background-color: $white;
      width: 34px;
      height: 34px;
      border: 1px solid get($grey, border);
      border-radius: 50%;
      box-sizing: border-box;
      &:hover {
        @include sprite('chevron-right-white');
        background-size: 80%;
        background-position: 60% center;
        background-color: get($red);
        border-color: get($red);
      }
    }
    .swiper-button-next {
      right: $space-unit * 4;
      transform: initial;
    }
    .swiper-button-prev {
      right: 74px;
      transform: rotate(-180deg);
      top: initial;
    }
  }
  .wrapper-text {
    position: relative;
    z-index: 1;
  }
  @include breakpoint(sm) {
    padding-top: $space-unit * 12;
    padding-bottom: $space-unit * 6;
    .wrapper-text {
      text-align: center;
    }
    .slideshow {
      .swiper-button-next,
      .swiper-button-prev {
        display: block;
        bottom: $space-unit;
        width: 26px;
        height: 26px;
      }
      .swiper-button-next {
        right: $space-unit;
      }
      .swiper-button-prev {
        right: 42px;
      }
    }
    .caption {
      margin-bottom: $space-unit;
    }
    h2 {
      display: none;
    }
    h4 {
      display: block;
    }
  }
}
