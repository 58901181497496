@mixin sidebarBody {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  > h5 {
    margin-bottom: $space-unit;
  }

  > h6 {
    margin: $space-unit 0;
  }

  .no-route-selected {
    border: 1px dashed get($grey, border);
    border-radius: 10px;
    text-align: center;
    color: get($grey, text);
    padding: $space-unit * 2 0;
    width: 100%;
  }

  .cartitem {
    border: 1px solid get($grey, border);
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;

    .price-container {
      background: get($grey, fog);
    }

    .directions {
      padding: 0 $space-unit;
      display: flex;
      justify-content: space-between;
      .left,
      .right {
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50%;
      }
      .left {
        text-align: left;
        padding-right: $space-unit * 2;
      }
      .right {
        text-align: right;
        padding-left: $space-unit * 2;
      }
      .ico-bus {
        @include spritex('bus-mini');
        position: absolute;
        left: 50%;
        top: $space-unit;
        transform: translateX(-50%);
      }

      &.stations {
        padding-top: 12px;
        position: relative;
      }

      &.times {
        @include spritex('line-horizontal');
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-repeat: repeat-x;
        width: auto;
        height: auto;
        align-items: center;
        padding: 0;
        margin: $space-unit*0.5 $space-unit;
        > * {
          display: inline-block;
          background-color: #fff;

          &:first-child {
            padding-right: 14px;
          }

          &:last-child {
            padding-left: 14px;
          }
        }

        .left,
        .right {
          font-size: 20px;
          line-height: 22px;
        }

        .day-name {
          font-family: get($font, secondary);
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          padding: 0 14px;
          text-transform: capitalize;
        }
      }

      &.prices {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $space-unit;
        .right {
          font-family: get($font, secondary);
          font-weight: 600;
          line-height: 24px;
          font-size: 14px;
        }

        .toggle-price-details {
          background-size: 100%;
          height: 16px;
          width: 16px;
          margin-left: 8px;
          vertical-align: text-top;
        }
      }
    }

    .price-details-box {
      padding: $space-unit;
      padding-bottom: 12px;
      // margin-top: $space-unit;
      font-size: 12px;
      line-height: 16px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: calc(100% - 16px);
        height: 1px;
        left: $space-unit;
        top: 0;
        background-color: get($grey, border);
        z-index: 1;
      }

      .arrow-down-no-bg,
      .arrow-up-no-bg {
        margin-left: 6px;
        width: $space-unit;
        height: $space-unit;
        background-size: 100%;
      }

      .price-passenger {
        display: flex;
        justify-content: space-between;
        padding-right: ($space-unit * 3);
        .caption {
          font-size: 14px;
          line-height: 16px;
        }
        .value {
          text-align: right;
        }
        &.price-service {
          margin-top: 8px;
          padding-top: 8px;
          border-top: 1px solid get($grey, light);
        }
      }

      .price-passenger-details-wrapper {
        margin-top: $space-unit;
        padding-right: ($space-unit * 3);
      }

      .price-passenger-details {
        display: grid;
        grid-template-columns: 70% 30%;
      }

      .price-passenger-details-value {
        text-align: right;
      }
    }
  }

  .privacy-card {
    background: #f5f6f6;
    border-radius: 10px;
    width: 100%;
    margin-top: $space-unit * 2;
    padding: $space-unit * 1.5;

    display: flex;
    flex-direction: row;
    align-items: center;

    &__print {
      margin-right: $space-unit;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .membership-card {
    background: get($flex);
    border-radius: 10px;
    padding: $space-unit * 2;
    width: 100%;
    margin-top: $space-unit * 2;

    &__header {
      display: flex;
    }

    &__title {
      margin-left: $space-unit;
      font-family: get($font, secondary);
      font-weight: 600;
      font-size: 16px;
      line-height: $space-unit * 3;
      letter-spacing: -0.02em;
    }

    &__price {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__price-label {
      font-family: get($font, secondary);
      font-weight: 600;
      font-size: 12px;
      line-height: $space-unit * 2;
    }

    &__price-amount {
      text-align: right;
      font-weight: 600;
      font-size: 14px;
      line-height: $space-unit * 3;
    }

    &__discount {
      font-weight: normal;
      font-size: 12px;
      line-height: $space-unit * 2;
    }

    .separator {
      display: block;
      margin-top: $space-unit * 2;
      margin-bottom: $space-unit;
      border-style: initial;
      border-top: 1px solid get($flex, border);
    }
  }

  .flex-box {
    background: whitesmoke;
    box-sizing: border-box;
    border: 1px solid silver;
    border-radius: 10px;
    padding: $space-unit * 2;
    padding-bottom: 10px;
    width: 100%;
    margin-top: $space-unit * 2;

    .titleicon {
      position: relative;
      padding-right: ($space-unit * 7);
      h6 {
        color: get($grey, 'caption');
        margin-bottom: $space-unit * 2;
      }
      .ico-flex {
        @include spritex('flex');
        background-size: unset;
        position: absolute;
        right: -16px;
        top: -16px;
        width: 80px;
        height: 80px;
      }
    }
    p {
      color: get($grey, dark);
    }

    .separator {
      display: block;
      margin-top: $space-unit;
      margin-bottom: $space-unit;
      border-style: initial;
      border-top: 1px solid silver;
    }

    .microcopy {
      font-size: 16px;
      line-height: 22px;
    }

    .add-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .flex-status {
        font-size: 14px;
        line-height: 22px;
        font-weight: bold;
        margin-right: $space-unit * 2;
      }
    }
  }

  .cart-totals {
    width: 100%;
    margin-top: $space-unit * 3;
    align-items: center;
    // padding: 0 8px;

    .total-price-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .total-price-label,
      .total-price-value {
        color: get($grey, caption);
      }

      .total-price-label {
        font-family: get($font, secondary);
        font-weight: 600;
      }

      &:not(:last-child) {
        margin-bottom: $space-unit * 2;
      }
    }

    .voucher-row {
      position: relative;
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      padding: $space-unit * 2;

      .total-price-value {
        color: #ee203c;
      }

      .voucher-remove {
        position: absolute;
        top: -8px;
        right: -8px;
        cursor: pointer;
      }
    }
  }
}

.sidebar {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border: 1px solid get($grey, border);
  border-radius: 20px;
  padding: $space-unit * 2;
  background-color: $white;

  .cart-body {
    @include sidebarBody;
    &__membership-wrapper {
      width: 100%;
      text-align: center;
      h6 {
        font-size: 16px;
        margin:0 0 24px;
      }
    }
    &__membership {
      background: var(--sidebar-url) no-repeat top center;    
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 72px;
      border-radius: 16px;
      padding: 0 24px;
      span {
        @include spritex('logo-memberhip');
        width: 120px;
        height: 100%;
        background-size: 95%;
      }       
    }
  }

  .action-container {
    margin-top: $space-unit * 3;
    width: 100%;
    button {
      width: inherit;
    }
  }
  .countdown {
    margin-top: $space-unit * 2;
    &.alert--error {
      // cursor: pointer;
      font-weight: bold;
    }
  }
}
