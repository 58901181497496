.my-account__membership {
  text-align: left;
  .description {
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    margin-top: 24px;
  }
  .member-user {
    margin-top: 52px;
    .intro{
      border-bottom: 1px solid #DFDFDF; 
      margin-bottom: 10px;
      margin-right: 16px;
      position: relative;
    }
    h2 {
      font-size: 16px;
      line-height: 50px;
      @include breakpoint(sm, min) {
        font-size: 24px;
      }
    }
    .logo-member {
      background: var(--searchbox-url) no-repeat 0 0;    
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 38px;
      position: absolute;
      right: -16px;
      bottom: -1px;
      border-radius: 0 0 12px 0;
      padding: 0 16px 0 22px;
      clip-path: polygon(17% 0%, 100% 0%, 100% 100%, 0% 100%);
      span {
        @include spritex('logo-memberhip');
        width: 100%;
        height: 100%;
        background-size: 95%;
      }      
    }
  }
  .mmb-cta {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
  .editorial-template {
    .experience-component {
      margin: 32px 0;
    }
    h4 {
      font-size: 32px;
      font-family: "Montserrat Alternates";
    }
    ul {
      li {
        display: block;
        font-size: 14px;
        margin-bottom: 16px;

        @include breakpoint(md, min) {
          font-size: 18px;
        }
      }
    }     
    .mbsc-grid {
      padding: 0;
    }
  }
}