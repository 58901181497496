#search-widget-component {
  .booking-box.floating {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;
    @include breakpoint(md, min) {
      align-items: flex-start;
    }
    @include animate;
    &.show {
      opacity: 1;
      visibility: visible;
      .booking-box__inner-wrapper {
        transform: scale(1);
      }
      .booking-box__inner-wrapper {
        @include breakpoint(md, min) {
          margin-top: 200px;
        }
      }
    }
    &__inner-wrapper {
      transform-origin: bottom right;
      transform: scale(0);
      @include animate;
      position: relative;
      bottom: 100px;
    }
  }
  .close--button {
    display: none;
  }

  .scroll-to-search {
    &:not(.show-box) {
      &::before {
        background-size: 180%;
        background-position: 60% 53.5%;
      }
    }
    &::before {
      height: 48px;
      width: 48px;
    }
    > span {
      color: $white;
      padding: 12px;
      padding-right: 36px;
      background-color: get($green);
      position: absolute;
      z-index: -1;
      right: 50%;
      top: 0;
      width: auto;
      display: block;
      white-space: nowrap;
      border-radius: 48px 0 0 48px;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }
    &.show-box {
      > span {
        background-color: get($red);
        transform: translateX(32px);
        opacity: 0;
      }
      @include breakpoint(md, min) {
        padding: 7.5px;
        background-color: white;
        border-radius: 25px;
        &::before {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  @include breakpoint(md) {
    .booking-box {
      padding: 16px 0;
      &.floating {
        padding: 0;
        display: flex;
        align-items: flex-end;
        .booking-box__body {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        > .mbsc-grid {
          padding: 0;
        }
        &.show {
          .booking-box__inner-wrapper {
            transform: initial;
          }
        }
        &__inner-wrapper {
          bottom: 0;
          transform: translateY(100%);
        }
      }
      .booking-box__outer-wrapper {
        padding: 0 16px;
      }
    }
    .close--button {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin: 12px 24px;
      cursor: pointer;
      &::before {
        content: '';
        height: 24px;
        width: 24px;
        display: block;
        @include sprite('close-red');
      }
    }
  }
}

.hero-region {
  position: relative;
  @media (min-width: 991px) {
    .booking-box:not(.floating) {
      position: absolute;
      top: 80px;
    }
  }
}
