.card--postsale {
  &-services {
    position: relative;
    background-color: #f5f5f5;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: $space-unit * 2;
    border-radius: 0 0 20px 20px;

    @include breakpoint(md, min) {
      padding: $space-unit * 2 $space-unit * 3 $space-unit * 3;
    }

    &-top {
      position: absolute;
      background-color: #f5f5f5;
      border-left: 1px solid #dfdfdf;
      border-right: 1px solid #dfdfdf;      
      height: 30px;
      width: calc(100% + 2px);
      top: -20px;
      z-index: -1;
      left: -1px;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      form {
        display: contents;
      }
    }

    &-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid;
      border-color: #5FA5DB;
      background-color: #F7FBFD;
      padding: 2px 16px;
      width: 100%;
      border-radius: 12px;
      text-decoration: none;
      color: #1F1F1F;
      cursor: pointer;
      text-transform: none;
      margin-bottom: $space-unit;

      .ic-arrow-right {      
        width: 12px;
        height: 12px;
        background-size: 100%;
      }

      @include breakpoint(md, min) {
        width: 48%;
        margin-bottom: 0;
        padding: 20px;
        border-radius: 20px;
        justify-content: center;

        .ic-arrow-right {
          display: none;
        }
      }

      .icon-access-new,
      .icon-luggages-new {
        width: 37px;
        height: 37px;
        display: block;
      }

      span {
        font-family: 'Montserrat';
        font-weight: 600;
        margin-left: $space-unit;
      }

      &-unavailable {
        filter: grayscale(1);
        cursor: auto;
        pointer-events: none;
      }
    }

    h6 {
      margin-bottom: $space-unit * 2;
      text-align: center;
    }
  }
}

.luggagepassenger.ps-disable-accordion {
  .luggagehead {
    pointer-events: none;
  }
}

#app-post-sale {
  .booking-navigation {
    .bookingbar {
      .steps {
        top: 25px;
        left: 45%;
        transform: translateX(-50%);
        .booking-operator-wrapper {
          i {
            display: none;
          }
        }
      }
    }
  }
}