// Utility debug

.development {
  background-color: bisque;
  padding: 10px;
  border: 2px dotted #e83e8c;
}

.debug_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;

  .button {
    position: fixed;
    right: 5px;
    bottom: 5px;
  }
}

.debug_row {
  height: 100%;
}

#debugpanel {
  background-color: #fee;
  padding: 10px;
  border-top: 1px solid #000;
  overflow: auto; // This will prevent main overflow annoying front-end developers.
  font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;

  summary {
    font-size: 12px;
    padding-bottom: 10px;
    cursor: pointer;
  }

  .table .table {
    background-color: transparent;

    tr {
      > th,
      > td {
        border-top: 0;
        border-left: 0;
      }

      > td {
        border-right: 0;
      }
    }
  }

  table {
    margin-top: 20px;

    th,
    td {
      border: 1px solid #ccc;
      padding: 4px;
      font-size: 12px;
      vertical-align: top;
      word-wrap: break-word;
      text-align: left;

      &.nopadding {
        padding: 0;

        table {
          border: 0;
        }
      }

      &.separator {
        background-color: #e8e0df;
      }

      &.type {
        white-space: nowrap;
      }
    }

    code,
    a,
    span {
      color: #e83e8c;
      padding: 0;
      border: none;
      background-color: transparent;

      &.disclosure {
        color: #000;
        font-size: 12px;
      }
    }
  }
}
.devtool {
  position: absolute;
  left: 10px;
  bottom: 10px;
  border: 1px solid #999;
  padding: 5px;
  margin-top: 5px;
  width: 140px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  background-color: rgb(66, 66, 66);
  a {
    text-decoration-line: none;
    color: #fff;
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
    border: 1px solid #ccc;
    margin-left: 5px;
    border-radius: 2px;
    &.devtool-enabled {
      background-color: rgb(20, 61, 20);
    }
    &.devtool-disabled {
      background-color: rgb(114, 36, 36);
    }
  }
}
